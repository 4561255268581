import React from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import {RouteWithLayout} from './components';
import theme from './views/skills/theme';
import {
  Main,
  Minimal
} from './layouts';
import {AuthCheck, WithCustomTheme} from './hoc';
import {
  Dashboard,
  BotIntelligence,
  Skills,
  ExpectedOutcomes,
  AppStore,
  Login,
  Users,
  Register,
  RegistrationComplete,
  CustomCommands,
  ErrorLog,
  ResetRequest,
  ResetRequested,
  ResetPassword,
  ResetComplete,
  CustomCommandEditor
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={AuthCheck(Dashboard)}
        exact
        layout={Main}
        path='/dashboard'
      />
      <RouteWithLayout
        component={AuthCheck(BotIntelligence)}
        exact
        layout={Main}
        path='/bot-intelligence'
      />
      <Route
        // component={Skills}
        component={WithCustomTheme(Skills, theme)}
        exact
        path='/skills'
      />
      <RouteWithLayout
        component={AuthCheck(ExpectedOutcomes)}
        exact
        layout={Main}
        path='/expected-outcomes'
      />
      <RouteWithLayout
        component={AuthCheck(AppStore)}
        exact
        layout={Main}
        path='/appstore'
      />
      <RouteWithLayout
        component={AuthCheck(Users)}
        exact
        layout={Main}
        path='/users'
      />
      <RouteWithLayout
        component={AuthCheck(CustomCommands)}
        exact
        layout={Main}
        path='/custom-commands'
      />
      <RouteWithLayout
        component={AuthCheck(CustomCommandEditor)}
        exact
        layout={Main}
        path='/custom-command-editor/:id'
      />
      <RouteWithLayout
        component={AuthCheck(CustomCommandEditor)}
        exact
        layout={Main}
        path='/custom-command-editor'
      />
      <RouteWithLayout
        component={AuthCheck(ErrorLog)}
        exact
        layout={Main}
        path='/error-log'
      />
      <Route
        component={Login}
        exact
        path='/login'
      />
      <Route
        component={Register}
        exact
        path='/register/:id'
      />
      <Route
        component={RegistrationComplete}
        exact
        path='/registration-complete'
      />
      <Route
        component={ResetRequest}
        exact
        path='/reset-request'
      />
      <Route
        component={ResetRequested}
        exact
        path='/reset-requested'
      />
      <Route
        component={ResetPassword}
        exact
        path='/reset-password/:id'
      />
      <Route
        component={ResetComplete}
        exact
        path='/reset-complete'
      />
      <Redirect
        exact
        to="/dashboard"
      />
    </Switch>
  )
}

export default Routes;