import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import axios from 'axios';
import {Tooltip, Alerts} from '../../../../components'
import icon from '../../../../assets/branding/Onebot white.svg';
import {withRouter} from 'react-router-dom';
import { CloudDownload, Map, Forum, Settings, SupervisorAccount, Public, Lock, BarChart, Error } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  sidebar: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1030,
    padding: 0,
    width: "64px",
    height: "100vh",
    background: "#824FFF !important",
    display: "flex",
    justifyContent: "center",
    borderRight: "1px solid #dee2e6!important"
  }, 
  list: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
    height: "100vh",
    width: "50px"
  },
  listItem: {
    minWidth: "32px",
    minHeight: "32px",
    // padding: ".2em",
    borderRadius: ".25em",
    fill: "#fff",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "12px",
    marginBottom: "12px",
    '&:last-of-type': {
      paddingBottom: "1em",
      marginBottom: "0",
    }
  },
  brand: {
    padding: 0,
    marginTop: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: 'center',
    minWidth: "40px",
    minHeight: "40px",
    marginBottom: "12px",
  },
  brandImage: {
    height:"40px",
    width:"40px"
  },
  active: {
    backgroundColor: theme.palette.common.white
  },
  link: {
    width: '32px',
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: ".2em",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    }
  },
  active: {
    backgroundColor: theme.palette.common.white,
    '& div': {
      color: theme.palette.primary.main
    }
  },
  iconContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  spacer: {
    flexGrow: 1
  },
  spacerLarge: {
    flexGrow: 2
  },
  sidenavIcon: {
    marginLeft: "auto",
    marginRight: "auto",
  }
})

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: ""
    }
  }

  logout() {
    axios.delete('/auth/logout')
    .then(() => {
      localStorage.removeItem('user');
      this.props.history.push('/login');
    })
    .catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  render() {
    const {classes, languageDialogOpenHandler, licenseDialogEnter, licenseDialogLeave} = this.props;
    return (
      <div className={classes.sidebar}>
        <ul className={classes.list}>
          <li className={classes.brand}>
            <Link 
              to='/' 
              aria-owns='license-popover'
              aria-haspopup="true"
              >
              <img 
              onMouseEnter={licenseDialogEnter} 
              onMouseLeave={licenseDialogLeave}
              className={classes.brandImage} src={icon} alt=""/>
            </Link>
          </li>
          <li className={classes.spacer}></li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips.dashboard}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to="/dashboard">
                <div className={classes.iconContainer}>
                  <BarChart fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips['bot-intelligence']}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to='/bot-intelligence'>
                <div className={classes.iconContainer}>
                  <Forum fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips['expected-outcomes']}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to='/expected-outcomes'>
                <div className={classes.iconContainer}>
                  <Map fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips.integrations}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to='/appstore'>
                <div className={classes.iconContainer}>
                  <CloudDownload fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips.users}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to='/users'>
                <div className={classes.iconContainer}>
                  <SupervisorAccount fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips["custom-commands"]}
              placement="right"
            >
              <NavLink exact activeClassName={classes.active} className={classes.link} to='/custom-commands'>
                <div className={classes.iconContainer}>
                  <Settings fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips.languages}
              placement="right"
            >
              <NavLink className={classes.link} to='#' onClick={languageDialogOpenHandler}>
                <div className={classes.iconContainer}>
                  <Public fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
          <li className={classes.spacerLarge}></li>
          <li className={classes.listItem}>
            <Tooltip
              text={this.props.language.tooltips.logout}
              placement="right"
            >
              <NavLink className={classes.link} to='#' onClick={this.logout.bind(this)}>
                <div className={classes.iconContainer}>
                  <Lock fontSize="small" />
                </div>
              </NavLink>
            </Tooltip>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Sidebar));