import React, { Component } from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {TextInput, Button, Typography} from '../../../../components';
import OnebotLogo from '../../../../assets/branding/OneBot.png';

const styles = theme => ({
  heading: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '48px'
  },
  text: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageText: {
    lineHeight: '24px'
  },
  subText: {
    color: theme.palette.text.secondary
  },
  logo: {
    width: '48px',
    height: '48px',
    marginRight: theme.spacing(1)
  },
})

class Dialog extends Component {
  render() {
    const {classes, type, languagePack, open, dialogHandler, submit, fieldHandler, values, submitting} = this.props;
    
    if(!type || !languagePack) {
      return null;
    }
    
    const modalLanguagePack = languagePack[type]
    
    return (
      <MuiDialog open={open} onClose={dialogHandler}>
        <DialogTitle disableTypography={true}>
          <div className={classes.heading}>
            <div className="image">
              <img className={classes.logo} src={OnebotLogo}/>
            </div>
            <div className={classes.text}>
              <div>
                <Typography variant="h5" className={classes.imageText}>
                  {modalLanguagePack.heading}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={[classes.imageText, classes.subText].join(" ")}>
                  {modalLanguagePack.helpText}
                </Typography>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12}>
              <TextInput value={values.subject} label={modalLanguagePack.fields.subject.label} onChange={fieldHandler} name={`${type}-subject`} disabled={submitting}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput multiline rows={4} value={values.description} label={modalLanguagePack.fields.description.label} onChange={fieldHandler} name={`${type}-description`} disabled={submitting}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={submit} onClick={dialogHandler}>
            {modalLanguagePack.buttons.cancel}
          </Button>
          <Button fullWidth disableElevation color='info' variant='contained' onClick={submit} disabled={!values.subject || !values.description || submitting}>
            {modalLanguagePack.buttons.submit}
          </Button>
        </DialogActions>
      </MuiDialog>
    );
  }
}

export default withStyles(styles)(Dialog);