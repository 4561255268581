import React, { Component } from 'react';
import {withTheme} from '@material-ui/styles';
import PropTypes from 'prop-types';

class Typography extends Component {
  render() {
    const {variant, theme, children, display, bold, style, className} = this.props;
    const typographyClasses = theme.typography;
    let typography = null;

    if(!children) {
      return null;
    }

    let customStyles = {
      marginTop: 0,
      marginBottom: 0,
      ...typographyClasses[variant ? variant : 'body1'],
      ...style,
    }

    if (display)
      customStyles.display = display;

    if(bold) {
      customStyles = {
        ...customStyles,
        ...typographyClasses.bold,
      }
    }

    switch (variant?.toLowerCase()) {
      case 'body1':
      case 'body2':
        typography = <p className={className} style={customStyles}>{children}</p>
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        const Tag = `${variant}`
        typography = <Tag className={className} style={customStyles}>{children}</Tag>
        break;
      case 'button':
      case 'buttonsmall':
      case 'buttonlarge':
      case 'caption':
      case 'cell':
      case 'tableheader':
        typography = <span className={className} style={customStyles}>{children}</span>
        break;
      default:
        typography = <p className={className} style={customStyles}>{children}</p>
        break;
    }
    return typography ? typography : null;
  }
}

Typography.propTypes = {
  variant: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default withTheme(Typography);