import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid} from '@material-ui/core';
import {Typography, Button, TextInput} from '../../../../components';
import { Autocomplete } from '@material-ui/lab';


class HelpDialog extends Component {
  render() {
    const {open, close, question, languagePack, ...rest} = this.props;

    return (
      <Dialog open={open} fullWidth maxWidth={"xs"} onClose={close} {...rest}>
        <DialogTitle disableTypography>
          <Typography variant="h5">
            {question?.skill}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {question?.howToUse}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            {languagePack.buttons.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HelpDialog;