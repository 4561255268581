export default (command) => {
  const values = [
    "busObId",
    "search"
  ]

  return values.every(x => command[x]) 
    && ("customerFieldId" in command 
      ? command.customerFieldId !== "" 
      : true)
    && ("customerFieldMapping" in command
      ? command.customerFieldMapping.every(x => x.customerFieldId !== "" && x.businessObjectFieldId !== "")
      : true)
}