import React, { Component } from 'react';
import {Grid, Card, CardHeader, CardContent} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Typography} from '../../../../components';
import Chart from 'react-apexcharts';
import moment from 'moment';

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    paddingBottom: '0'
  }
})

class RecentStats extends Component {
  render() {
    const {classes, languagePack, stats} = this.props;

    const today = new Date();
    const lastMonth = new Date(moment().subtract(30, 'd'));

    const requests = stats.pastMonth.map(function(x) {
      return [x.day, x.total]
    });
    const commands = stats.pastMonth.map(function(x) {
      return [x.day, x.command]
    });
    const failed = stats.pastMonth.map(function(x) {
      return [x.day, x.failed]
    });

    const options = {
      chart: {
        type: 'area',
        fontFamily: 'Poppins, sans-serif',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        min: lastMonth.getTime(),
        max: today.getTime(),
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      colors: [
        "rgb(3, 169, 252)",
        "rgb(58, 247, 0)",
        "rgb(255, 99, 132)"
      ]
    }

    return (
      <Grid 
        item
        xs={12}
        md={8}
        lg={9}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>
          <CardHeader
            title={(<Typography variant="body1" bold>{languagePack.heading}</Typography>)}
            subheader={(<Typography variant="body2">{languagePack.helpText}</Typography>)}
            disableTypography
          />
          <CardContent>
            <Chart
              options={options}
              series={[
                {
                  name: 'Requests',
                  data: requests
                },
                {
                  name: 'Commands',
                  data: commands
                },
                {
                  name: 'Failed Commands',
                  data: failed
                }
              ]}
              type="area"
              height={295}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(RecentStats);