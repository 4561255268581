import React, { Component } from 'react';
import {Toolbar as MuiToolbar, Grid, Button, FormControlLabel, Popover, ListItem, ListSubheader} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Delete, NotInterested, Add, Check, FilterList} from '@material-ui/icons';
import {TextInput, Tooltip, Typography, Checkbox, Radio} from '../../components';
import {Search} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const styles = theme => ({
  actionButton: {
    maxWidth: "36px",
    maxHeight: "36px",
    minWidth: "36px",
    backgroundColor: "rgba(244,246,249,1)", //TODO Update Greys in theme
    marginRight: theme.spacing(1),
    "&:last-of-type": {
      marginRight: 0
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[300]   
    }
  },
  spacer: {
    flexGrow: 1
  },
  grid: {
    display: "flex"
  },
  gridContainer: {
    padding: theme.spacing(2)
  },
  add: {
    color: theme.palette.info.main
  },
  enable: {
    color: theme.palette.success.main
  },
  delete: {
    color: theme.palette.error.main
  },
  disable: {
    color: theme.palette.error.main
  },
  filtersContainer: {
    padding: 16
  },
  filterListSubheader: {
    color: theme.palette.text.secondary,
    padding: 0,
    lineHeight: "20px",
    padding: "8px 0",
    "&:first-of-type": {
      paddingTop: 0
    }
  },
  filterListItem: {
    boxSizing: "content-box",
    maxHeight: 20,
    padding: "8px 0",
    "&:last-of-type": {
      paddingBottom: 0
    },
    "& .MuiFormControlLabel-label": {
      paddingLeft: 3
    }
  },
  filterButton: {
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "6px",
    boxSizing: "border-box",
    maxHeight: '36px',
    fontSize: '14px',
    padding:"8px 12px",
    paddingLeft:"11px",
    marginRight: theme.spacing(1),
  },
  filterButtonFiltered: {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`,
  },
  filterButtonIcon: {
    paddingRight:"8px",
    fontSize: '16px'
  },
  recordText: {
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  recordSelectedText: {
    alignItems: "center",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}) 

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownAnchor: null,
      dropdownOpen: false
    }
  }

  filterDropDownHandler = (event) => {
    const ct = event.currentTarget;
    this.setState(state =>({
      ...state,
      dropdownAnchor: state.anchorEl || ct,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  formatText(string) {
    const res = string.replace( /([A-Z])/g, " $1" );
    const finalRes = res.charAt(0).toUpperCase() + res.slice(1);

    return finalRes;
  }

  actionButton(props) {
    const buttonClasses = [this.props.classes.actionButton];

    const { icon, tooltip, className, style, color, disabled, ...rest } = props;

    const customStyle = style || {};
    if (color && !disabled)
      customStyle.color = color;

    return <Tooltip
      text={tooltip}
      placement="bottom"
    >
      <Button className={[...buttonClasses, className].join(' ')} style={customStyle} disabled={disabled || false} {...rest}>
        {icon}
      </Button> 
    </Tooltip>
  }

  render() {
    const {classes, actions, filterFieldHandler, filterValue, additionalFilters, additionalFiltersHandler, recordNumber, selectedNumber, showingNumber, filterTranslation} = this.props;
    
    const actionButtons = actions ? actions.map((x, i) => {
      const { type, ...rest } = x;
      const icon = {
        add: <Add fontSize="small"/>,
        enable: <Check fontSize="small"/>,
        delete: <Delete fontSize="small"/>,
        disable: <NotInterested fontSize="small"/>
      }

      if (!icon[type])
        return this.actionButton(x);

      return this.actionButton({ icon: icon[type], className: classes[type], ...rest});
    }) : null;

    let filterOptions = null;

    if(additionalFilters) {
      const {search, ...rest} = additionalFilters;
      filterOptions = rest
    }

    const filterMenuItems = [];
    let isFiltered = false;

    if(filterOptions) {
      isFiltered = Object.values(filterOptions).some(x => Object.values(x).includes(true));
      for(const key in filterOptions) {
        filterMenuItems.push(
          <ListSubheader className={classes.filterListSubheader} key={key}>
            <Typography variant="caption" bold>{filterTranslation[key].category}</Typography>
          </ListSubheader>
        )
        const {type, ...rest} = filterOptions[key]
        if(type === 'radio') {
          rest.options.map(val => {
            filterMenuItems.push(
              <ListItem className={classes.filterListItem} key={`${key}-${val}`}>
                <FormControlLabel
                  key={key}
                  label={<Typography variant="body2" bold>{filterTranslation[key][val]}</Typography>}
                  control={
                    <Radio
                      checked={val === rest.selected}
                      value={val}
                      size="small"
                      onChange={(event) => additionalFiltersHandler(event, 'radio')}
                      name={`${key}-radio`}
                    />
                  }
                />
              </ListItem>
            )
          })
        } else {
          for(const val in rest) {
            filterMenuItems.push(
              <ListItem className={classes.filterListItem} key={`${key}-${val}`}>
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      name={`${key}-${val}`}
                      size="small"
                      checked={filterOptions[key][val]}
                      onChange={(event) => additionalFiltersHandler(event)}
                    />
                  }
                  label={<Typography variant="body2" bold>{filterTranslation[key][val]}</Typography>}
                />
              </ListItem>
            )
          }
        }
      }
    }
    return (
      <MuiToolbar variant="dense" disableGutters>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs={3} className={classes.grid}>
            {
              filterFieldHandler ? <TextInput 
                name='search'
                value={filterValue}
                onChange={filterFieldHandler}
                endAdornment={<Search/>}
                label={this.props.languagePack.searchField}
                size="small"
              /> : null   
            }
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            {
              filterOptions && 
              <React.Fragment>
                <Button className={[classes.filterButton, isFiltered ? classes.filterButtonFiltered : ""].join(" ")} onClick={this.filterDropDownHandler}>
                  <FilterList className={classes.filterButtonIcon} fontSize="inherit" />
                  {this.props.languagePack.filterButton}
                </Button>
                <Popover 
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{ className: classes.filtersContainer}} 
                  open={this.state.dropdownOpen} 
                  anchorEl={this.state.dropdownAnchor} 
                  onClose={this.filterDropDownHandler}
                >
                  {filterMenuItems}
                </Popover>
                
              </React.Fragment>
            }
            {
              typeof recordNumber === "number" &&
              <>
                <Typography display="flex" variant="body2" className={classes.recordText} bold>
                  {this.props.languagePack.recordText}: {recordNumber}
                </Typography>
                {
                (showingNumber !== recordNumber) > 0 && 
                <Typography display="flex" variant="body2" className={classes.recordSelectedText}>
                  ({showingNumber} {this.props.languagePack.showingText})
                </Typography>
                }
                {
                (selectedNumber || 0) > 0 && 
                <Typography display="flex" variant="body2" className={classes.recordSelectedText}>
                  ({selectedNumber} {this.props.languagePack.selectedText})
                </Typography>
                }
              </>
            }
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            <div className={classes.spacer}></div>
            {actionButtons}
          </Grid>
        </Grid>
      </MuiToolbar>
    );
  }
}

Toolbar.propTypes = {
  saveAction: PropTypes.func, 
  discardAction: PropTypes.func, 
  addAction: PropTypes.func, 
  filterFieldHandler: PropTypes.func, 
  filterValue: PropTypes.string, 
  custom: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element.isRequired,
    action: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
  })), 
  additionalFilters: PropTypes.object, 
  additionalFiltersHandler: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    languagePack: state.languagePack.toolbar,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Toolbar));
