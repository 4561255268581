import React, { Component } from 'react';
import {Card, Grid} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {MoreVert} from '@material-ui/icons';
import {Toolbar, Table, LoadingSpinner, Alerts} from '../../components';
import {connect} from 'react-redux';
import axios from 'axios';
import defaults from './defaults.json';
import helper from '../../helpers/helper';
import {withRouter} from 'react-router-dom';

import { CommandWarningDialog, CommandDialog } from "./components";

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

export const filterCommandValues = (commandValues) => {
  const values = { ...commandValues };

  for (const valName in values) {
    const val = values[valName];
    if (Array.isArray(val))
      values[valName] = val.filter(x => x !== null);
  }

  return values;
}

class CustomCommands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commands: null,
      configs: null,
      commandStatistics: null,
      cardTemplates: null,
      tableData: null,
      filters: {
        search: "",
        status: defaults.filters.status,
      },
      newCommandDialog: defaults.commandDialog,
      editCommandDialog: defaults.commandDialog,
      newCommand: defaults.command,
      selectedCommand: null,
      selectedCommands: [],
      commandDeleteDialog: defaults.commandDeleteDialog,
      commandDisableDialog: defaults.commandDisableDialog
    }
  }

  componentDidMount() {
    Promise.all([
      this.getConfigs(),
      this.getCommands(),
      this.getStatistics(),
      this.getCardTemplates()
    ]).then(() => {
      this.createTableData();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiLanguage !== this.props.uiLanguage)
      this.createTableData();
  }

  getCommands = async () => {
    try {
      const { data } = await axios.get('/api/commands?custom=true');

      this.setState(state => ({
        ...state,
        commands: data
      }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }

  getConfigs = async () => {
    try {
      const { data } = await axios.get('/api/configs');

      this.setState(state => ({
        ...state,
        configs: data
      }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }

  getStatistics = async () => {
    try {
    const { data: { commands } } = await axios.get('/api/statistics?custom=true');

    this.setState(state => ({
      ...state,
      commandStatistics: commands
    }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }
  
  getCardTemplates = async () => {
    try {
      const { data } = await axios.get('/api/commands/templates')
      this.setState(state => ({
        ...state,
        cardTemplates: data
      }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  editCommandHandler = (step) => () => {
    const { editCommandDialog } = this.state;
    this.setState(state => ({
      ...state,
      editCommandDialog: {
        ...editCommandDialog,
        open: true,
        step
      }
    }))
  }

  createTableData = async () => {
    let filteredCommands = this.state.commands;
    const {search, ...rest} = this.state.filters;
    
    if(search || Object.values(rest).some(x => Object.values(x).includes(true))) {
      filteredCommands = filteredCommands.filter(x => {
        if(
          (
            x.name?.toLowerCase().match(search.toLowerCase()) ||
            this.props.integrations[x.integrationId]?.product?.toLowerCase().match(search.toLowerCase()) ||
            x.method?.toLowerCase().match(search.toLowerCase()) ||
            (x.draft ? "Draft" : x.enabled ? "Active" : "Disabled")?.toLowerCase().match(search.toLowerCase())
          ) &&
          (Object.values(rest.status).includes(true) ?
            (
              (rest.status.active && x.enabled) || 
              (rest.status.disabled && !x.enabled && !x.draft) || 
              (rest.status.draft && x.draft)
            ) : true
          )
        ) {
          return true
        }
      })
    }

    const tableRows = await Promise.all(filteredCommands.map(async x => {
      const status = x.draft ? this.props.languagePack.filters.status.draft : x.enabled ? this.props.languagePack.filters.status.active : this.props.languagePack.filters.status.disabled;
      const color = x.draft ? "grey" : x.enabled ? "success" : "error";
      // const roles = [{label: status, bgColor:{color: color, colorVariant: 'light'}, color: {color: color, colorVariant: 'main'}}];

      const actions = [
        {"display": this.props.languagePack.tableActions.editCommand, action: this.editCommandHandler("setup")},
        {"display": this.props.languagePack.tableActions.editCard, action: this.editCommandHandler("card")},
        {disabled: x.enabled, "display": this.props.languagePack.tableActions.enable, action: this.toggleCommand(true)},
        {disabled: !x.enabled, "display":this.props.languagePack.tableActions.disable, action: this.toggleCommand(false)},
        {color: "error", "display": this.props.languagePack.tableActions.delete, action: this.deleteCommand()},
      ]

      const usage = this.state.commandStatistics?.find(s => s.command === x.id)?.total || 0;

      return { 
        id: x.id,
        onClick: async () => {
          this.props.history.push('/custom-command-editor/'+x.id+'#setup');
        },
        cells: [
          {
            sortBy: x.name,
            type: 'imagetext',
            options: {
              imageUrl: await helper.integrationImage(x.integrationId),
              alt: x.name,
              text: {
                primary: x.name,
                secondary: this.props.integrations[x.integrationId]?.product
              }
            }
          }, 
          x.method, 
          usage,
          {
            sortBy: status,
            type: 'status',
            options: {
              text: status,
              color: color
            }
          }, 
          // status
          {
            type: 'actions', 
            options: {
              heading: this.props.languagePack.tableActions.heading,
              button: <MoreVert/>, 
              click: () => this.setCommand(x.id),
              actions: actions
            }
          }
        ]
      }
    }));

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true
      },
      {
        label: this.props.languagePack.table[2],
        sortable: true
      },
      {
        label: this.props.languagePack.table[3],
        sortable: true
      },
      {
        label: this.props.languagePack.table[4],
        sortable: false
      }
    ]

    const tableData = {
      rows: tableRows,
      labels
    }

    this.setState(state => ({
      ...state,
      tableData: tableData
    }))
  } 
  
  DialogStateHandler = (dialog, reset = true) => () => {
    if (this.state[dialog].open) {
      if (reset)
        this.ResetDialogState(dialog)();
      this.setState(state =>({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: false
        }
      }))
    } else {
      this.setState(state => ({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: true
        }
      }))
    }
  }

  ResetDialogState = (dialog) => () => {
    this.setState(state =>({
      ...state,
      [dialog]: defaults[dialog]
    }))
  }

  DialogFieldHandler = event => {
    const dialog = event.target.name.split('-')[0];
    const field = event.target.name.split('-')[1];
    this.setState(state => ({
      ...state,
      [dialog]: {
        ...state[dialog],
        [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      }
    }))
  }

  // newCommand = async () => {
  //   const command = this.state.selectedCommand;

  //   const {open, ...rest} = this.state.editCommand;

  //   const {id, busObId, search} = command;
    
  //   let shouldAdd = true;

  //   const payload = {};

  //   await Object.keys(rest).map(x => {
  //     payload[x] = rest[x].value;
  //   })

  //   if(shouldAdd === true) {
  //     axios.post('/api/botuser', payload).then(user => {
  //       this.DialogStateHandler('newCommandDialog')
  //       this.setState(state => ({
  //         ...state,
  //         users: [...state.users, user.data.user]
  //       }))
  //       Alerts({text: 'User Successfully Added', type: "success"})
  //     }).catch(error => {
  //       Alerts({text: error, type: "error"})
  //     })
  //   }
  // }

  deleteCommand = (initDialog = true) => async () => {
    if (initDialog) {
      return this.setState(state => ({
        ...state,
        commandDeleteDialog: {
          ...this.state.commandDeleteDialog,
          numberOfRecords: 1,
          multiple: false,
          open: true
        }
      }))
    }
    const command = this.state.selectedCommand;
    try {
      await axios.delete(`/api/command/${command.id}`)

      const commands = [...this.state.commands];
      const index = await commands.findIndex(x => {
        return x.id === command.id;
      })

      if(index !== -1) {
        commands.splice(index, 1);
      }

      this.setState(state => ({
        ...state,
        commands,
        commandDeleteDialog: {
          ...this.state.commandDeleteDialog,
          open: false
        }
      }), () => {
        this.createTableData();
      })
      
      Alerts({text: `Command '${command.name}' successfully deleted`, type: 'success'})
    } catch (err) {
      Alerts({text: err?.response?.data || err.message, type: "error"});
    }
  }

  toggleCommand = (enable, initDialog = true) => async () => {
    if (initDialog && !enable) {
      return this.setState(state => ({
        ...state,
        commandDisableDialog: {
          ...this.state.commandDisableDialog,
          numberOfRecords: 1,
          multiple: false,
          open: true
        }
      }))
    }

    const command = this.state.selectedCommand;
    const commands = [...this.state.commands];
    const toggledCommand = await commands.find(x => {
      return x.id === command.id;
    })
    
    try {
      const { data } = await axios.patch(`/api/command/${command.id}`,
       JSON.stringify([
        { "op": "replace", "path": "/enabled", "value": enable }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
      });

      toggledCommand.enabled = data.enabled;

      this.setState(state => ({
        ...state,
        commands,
        commandDisableDialog: {
          ...this.state.commandDisableDialog,
          open: false
        }
      }), () => {
        this.createTableData();
      })
      Alerts({text: `Command '${command.name}' successfully ${enable ? "enabled" : "disabled"}`, type: 'success'});
    } catch (err) {
      Alerts({text: err?.response?.data || err.message, type: "error"});
    }
  }

  commandSelectionToggle = (enableCommand = true, initDialog = true) => async () => {
    const selectedCommands = [...this.state.selectedCommands];
    if (selectedCommands.length === 0)
      return;

    if (!enableCommand && initDialog) {
      return this.setState(state => ({
        ...state,
        commandDisableDialog: {
          ...this.state.commandDisableDialog,
          numberOfRecords: selectedCommands.length,
          multiple: true,
          open: true
        }
      }))
    }
    
    const commands = [...this.state.commands];



    const results = await Promise.allSettled(selectedCommands.map(async id => {
      const toggledCommand = await commands.find(x => {
        return x.id === id;
      })

      const { data } = await axios.patch(`/api/command/${id}`,
        JSON.stringify([
        { "op": "replace", "path": "/enabled", "value": enableCommand }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
      });
      toggledCommand.enabled = data.enabled;
      selectedCommands.splice(selectedCommands.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully ${enableCommand ? "enabled" : "disabled"} ${successfulResults.length} command${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to ${enableCommand ? "enable" : "disable"} ${failedResults.length} command${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully ${enableCommand ? "enabled" : "disabled"} ${successfulResults.length} command${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to ${enableCommand ? "enable" : "disable"} ${failedResults.length} command${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

    this.setState(state => ({
      ...state,
      commands,
      commandDisableDialog: {
        ...this.state.commandDisableDialog,
        open: false
      },
      selectedCommands
    }), () => {
      this.createTableData();
    })
  }

  commandSelectionDelete = (initDialog = true) => async () => {
    const selectedCommands = [...this.state.selectedCommands];
    if (selectedCommands.length === 0)
      return;

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        commandDeleteDialog: {
          ...this.state.commandDeleteDialog,
          numberOfRecords: selectedCommands.length,
          multiple: true,
          open: true
        }
      }))
    }

    const commands = [...this.state.commands];
    const results = await Promise.allSettled(selectedCommands.map(async id => {
      const toggledCommand = await commands.find(x => {
        return x.id === id;
      })

      await axios.delete(`/api/command/${id}`);
      commands.splice(commands.indexOf(toggledCommand), 1);
      selectedCommands.splice(selectedCommands.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    
    
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully deleted ${successfulResults.length} command${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} command${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully deleted ${successfulResults.length} command${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to delete ${failedResults.length} command${failedResults.length > 1 ? "s" : ""}.`, type: "error" });


    this.setState(state => ({
      ...state,
      commands,
      commandDeleteDialog: {
        ...this.state.commandDeleteDialog,
        open: false
      },
      selectedCommands
    }), () => {
      this.createTableData();
    })
  }

  setCommand = async (c) => {
    const command = await this.state.commands.find(x => {
      return x.id === c;
    })
    if(command) {
      this.setState(state => ({
        ...state,
        selectedCommand: command
      }))
    }
  }

  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }), () => {
      this.createTableData();
    })
  }

  commandUpdateHandler = (type) => (command) => {
    const updatedCommand = { ...defaults.command, ...command };
    this.setState(state => ({
      ...state,
      [type]: updatedCommand
    }))
  }

  saveCommand = (type, draft = false) => async () => {
    const commandToSave = { ...this.state[type], draft, ...(!draft && { enabled: true }) };
    commandToSave.values = filterCommandValues(commandToSave.values);

    const id = commandToSave?.id || "";
    
    const { data } = await axios[id ? "put" : "post"](`/api/command/${id}`, commandToSave);

    this.setState({
      ...this.state,
      commands: id 
        ? this.state.commands.map(x => x.id === id ? data : x) 
        : this.state.commands.concat([data])
    }, () => {
      this.createTableData();
    });

    this.commandUpdateHandler(type)({});
    Alerts({text: `Command '${data.name}' successfully ${id ? "updated" : "added"}`, type: 'success'});
  }

  selectCommandHandler = (command) => {
    let selectedCommands = [...this.state.selectedCommands];
    if (command) {
      const commandIndex = selectedCommands.indexOf(command);
      commandIndex > -1 
      ? selectedCommands.splice(commandIndex, 1)
      : selectedCommands.push(command);
    } else {
        selectedCommands = selectedCommands.length !== this.state.tableData.rows.length 
        ? this.state.tableData.rows.map(x => x.id) 
        : [];
    }

    this.setState(state => ({
      selectedCommands
    }), () => {
      this.createTableData();
    });
  }

  render() {
    const {classes, languagePack, theme} = this.props;
    if(!this.state.commands && !this.state.commandStatistics && !this.state.cardTemplates && !this.state.tableData) {
      return <LoadingSpinner/>
    }

    const noneSelected = this.state.selectedCommands.length === 0;

    return (
      <React.Fragment>
        {/* <CommandDialog 
          open={this.state.newCommandDialog.open}
          languagePack={languagePack.commandDialog} 
          command={this.state.newCommand}
          configs={this.state.configs}
          integrations={this.props.integrations}
          templates={this.state.cardTemplates}
          onCancel={this.DialogStateHandler("newCommandDialog", false)}
          onClose={this.DialogStateHandler("newCommandDialog", false)}
          onUpdate={this.commandUpdateHandler("newCommand")}
          onSave={this.saveCommand("newCommand", "newCommandDialog")}
          onSaveDraft={this.saveCommand("newCommand", "newCommandDialog", true)}
        /> */}
        {/* {this.state.selectedCommand && <CommandDialog 
          open={this.state.editCommandDialog.open}
          dialogStep={this.state.editCommandDialog.step}
          languagePack={languagePack.commandDialog} 
          command={this.state.selectedCommand}
          configs={this.state.configs}
          integrations={this.props.integrations}
          templates={this.state.cardTemplates}
          onCancel={this.DialogStateHandler("editCommandDialog", false)}
          onClose={this.DialogStateHandler("editCommandDialog", false)}
          onUpdate={this.commandUpdateHandler("selectedCommand")}
          onSave={this.saveCommand("selectedCommand", "editCommandDialog")}
          onSaveDraft={this.saveCommand("selectedCommand", "editCommandDialog", true)}
        />} */}
        <CommandWarningDialog
          languagePack={languagePack.commandDeleteDialog}
          open={this.state.commandDeleteDialog.open}
          numberOfRecords={this.state.commandDeleteDialog.numberOfRecords}
          onCancel={this.DialogStateHandler("commandDeleteDialog", false)}
          onClose={this.DialogStateHandler("commandDeleteDialog", false)}
          onConfirm={this.state.commandDeleteDialog.multiple ? this.commandSelectionDelete(false) : this.deleteCommand(false)}
        />
        <CommandWarningDialog
          languagePack={languagePack.commandDisableDialog}
          open={this.state.commandDisableDialog.open}
          numberOfRecords={this.state.commandDisableDialog.numberOfRecords}
          onCancel={this.DialogStateHandler("commandDisableDialog", false)}
          onClose={this.DialogStateHandler("commandDisableDialog", false)}
          onConfirm={this.state.commandDisableDialog.multiple ? this.commandSelectionToggle(false, false) : this.toggleCommand(false, false)}
        />
        <Card elevation={0} className={['cardWrapper', 'stickyToolbar'].join(' ')}>
          <Grid container>
            <Grid item xs={12} className="toolbarWrapper">
              <Toolbar
                filterValue={this.state.filters.search}
                filterFieldHandler={this.filterFieldHandler}
                filterTranslation={languagePack.filters}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                actions={[
                  {type: "add", tooltip: languagePack.actions.add, onClick: () => this.props.history.push('/custom-command-editor#setup')},
                  {type: "enable", disabled: noneSelected, tooltip: languagePack.actions.enable, onClick: this.commandSelectionToggle(true)},
                  {type: "disable", disabled: noneSelected, tooltip: languagePack.actions.disable, onClick: this.commandSelectionToggle(false)},
                  {type: "delete", disabled: noneSelected, tooltip: languagePack.actions.delete, onClick: this.commandSelectionDelete()},
                ]}
                recordNumber={this.state.commands?.length || 0}
                selectedNumber={this.state.selectedCommands?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
              />
            </Grid>
            <Grid item xs={12} className="h100">
              {this.state.tableData && <Table
                sortable
                stickyHeader
                tableData={this.state.tableData}
                defaultSort={3}
                recordsPerPage={15}
                dense
                selectionHandler={this.selectCommandHandler}
                selectedArr={this.state.selectedCommands}
              />}
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ languagePack, integrations, uiLanguage }) => {
  const page = window.location.pathname.split('/')[1]
  return {
    uiLanguage,
    languagePack: languagePack[page],
    integrations
  }
}


export default connect(mapStateToProps)(withRouter(withTheme(withStyles(styles)(CustomCommands))));