import React, { Component } from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import { Button, Typography } from '../../../../components';

export class DeleteWarningDialog extends Component {
  render() {
    const {languagePack, open, close, selectedNo, deleteHandler, selected} = this.props;
    return (
      <Dialog open={open} onClose={close} fullWidth maxWidth='xs'>
        <DialogTitle>
          <Typography variant='h5'>
            {languagePack.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {
            selected
            ? languagePack.text
            : <>
                {languagePack.multipleText[0]} 
                <Typography display='inline' bold variant='body1'>
                  {selectedNo}  
                </Typography> 
                {languagePack.multipleText[1]} 
                <br/><br/>
                {languagePack.text}
              </>
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth disableElevation onClick={close}>
            {languagePack.buttons.cancel}
          </Button>
          <Button fullWidth disableElevation color="error" variant="contained" onClick={(event) => deleteHandler(event, selected ? selected.id : null, 'deleteWarningDialog')}>
            {languagePack.buttons.delete}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DeleteWarningDialog;
