import {Alerts} from './components';

function createWebSocket(url, maxRetries = 10, retryInterval = 5000) {
  let client;
  let serverVersion;
  let serverWarning;
  let retries = 0;

  const showServerWarning = () => {
    if (serverWarning)
      return;
    serverWarning = Alerts({ 
      text: "There was a problem connecting to the server. Reconnecting ...", 
      type: "warning", 
      timeout: false
    })
  };

  const closeServerWarning = () => {
    serverWarning?.close();
    serverWarning = null;
  };

  function start() {
    client = new WebSocket(url);

    client.onopen = () => {
      if (retries !== 0) {
        closeServerWarning();
        Alerts({ text: "Re-established connection to server", type: "success"});
      }
      retries = 0;
    }

    client.onmessage = (message) => {
      const newServerVersion = JSON.parse(message.data)?.serverVersion;
      if (newServerVersion) {
        if (!serverVersion) {
          console.log(`Version number: ${newServerVersion}`);
          serverVersion = newServerVersion;
        } else if (serverVersion !== newServerVersion) {
          window.location.reload();
        }
      }
    }

    client.onerror = (e) => console.error(e);

    client.onclose = () => {
      if (retries === maxRetries) {
        closeServerWarning();
        return Alerts({ text: "Error connecting to server, please refresh or try again later.", type: "error"});
      } else if (retries === 0) {
        showServerWarning();
      }
        
      retries += 1;
      setTimeout(start, retryInterval);
    }
  }

  start();
}

export default createWebSocket;