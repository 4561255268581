import React, { Component } from 'react';
import {ThemeProvider, createMuiTheme} from '@material-ui/core';
import Routes from './routes';
import {Router} from 'react-router-dom';
import './App.css';
import theme from './theme';
import {connect} from 'react-redux';
import supported from './translation/supported.json';
import integrations from './views/appstore/integrations.json';
import axios from 'axios';
import {Alerts} from './components';
import createWebSocket from "./websocket";

createWebSocket(`${(window.location.protocol === "https:" ? "wss" : "ws")}://${window.location.host}/ws`);

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      languageSet: false
    }
  }
  
  setLanguagePacks = async () => {
    const {setlanguage} = this.props;


    const userLanguage = supported.find(x =>{
      return x.language === (localStorage.getItem("language") || window.navigator.language);
    })

    const languageCode = userLanguage?.language || 'en-GB';
    import(`./translation/static/${languageCode}.json`).then(async x => {
      import (`./translation/languages/${languageCode}`).then(async k => {

        const languagePacks = {};
        await Promise.all(Object.keys(integrations).map(async x => {
          await import (`./translation/integrations/${x}/${languageCode}.json`).then(integration => {
            languagePacks[x] = integration.default[x];
          })
        }))

        localStorage.setItem("language", languageCode);
        await setlanguage({uiLanguage: languageCode, languagePack: x.default, integrationLanguagePacks: languagePacks, languages: k.default})
        
        this.setState({languageSet: true});
      })
    }).catch(err => {
      console.error(err)
    })
  }

  getEnabledLanguages = (languages) => {
    axios.get('/api/languages/enabled').then(async (data) => {
      let sorted = data.data.map(x => {
        return {...x, label: languages[x.language]}
      }).sort(function (a, b) { 
        if (languages[a.language] < languages[b.language]) {
          return -1;
        }
        if (languages[a.language] > languages[b.language]) {
          return 1;
        }
        return 0
      });

      await this.props.setEnabledLanguages({enabledLanguages: sorted})
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  componentDidMount() {
    this.setLanguagePacks();
  }

  render() {

    const muiTheme = createMuiTheme(theme);

    if(!this.state.languageSet) {
      return null;
    }

    return (
      <div className="App">
        <ThemeProvider theme={muiTheme}>
          <Router history={this.props.history}>
            <Routes/>
          </Router>
        </ThemeProvider>
      </div>
    );
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setlanguage: x => dispatch({type: 'SETLANGUAGE', payload: x}),
    setEnabledLanguages: x => dispatch({type: 'SETENABLEDLANGUAGES', payload: x})
  }
}

export default connect(null, mapDispatchToProps)(App);
