import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

export default function(AuthComponent) {
  class AuthCheck extends Component {

    constructor(props) {
      super(props);
    }

    componentWillUnmount() {
      const { interceptorId, setInterceptorId } = this.props;
      axios.interceptors.response.eject(interceptorId);
      if (!interceptorId)
        setInterceptorId({ interceptorId: null });
    }
    
    render() {
      if(!localStorage.getItem('user')){
        window.location.href = "/login"
      }

      const { interceptorId, setInterceptorId } = this.props;
      if (!interceptorId) {
        const newInterceptorId = axios.interceptors.response.use(data => {
          return data;
        }, (err) => {
          if(err?.response?.status === 401){
            localStorage.removeItem('user');
            window.location.href = "/login"
          }
          return Promise.reject(err);
        });
        setInterceptorId({ interceptorId: newInterceptorId });
      }

      return <AuthComponent />
    }
  }

  const mapDispatchToProps= (dispatch) => {
    return {
      setInterceptorId: x => dispatch({type: 'SETINTERCEPTORID', payload: x}),
    }
  }

  const mapStateToProps = (state) => {
    return {
      interceptorId: state.interceptorId
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(AuthCheck);
}