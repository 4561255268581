import integrations from "../views/appstore/integrations.json";
import {useDispatch} from 'react-redux';
import axios from 'axios';
import store from '../store/reducer';


const helpers = {
  limitStringLen: (string, length) => {
    if(!length || string.length < length) {
      return string;
    }
    return `${string.substr(0, length)}...`;
  },
  integrationImage: async (id) => {
    const imageName = integrations[id].image
    return (await import(`../assets/integrations/${imageName}`)).default;
  },
  setErrorCount: async () => {
    // const dispatch = useDispatch();

    const count = await axios.get('/api/errorlogs/statistics').then(data => {
      return data.data;
    });

    store.dispatch({type: 'SETERRORCOUNT', payload: count.unread});
  }
}
export default helpers;

