import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import { withStyles } from "@material-ui/styles";
import {TextInput, Typography, Button, Alerts, AdaptiveCardPreview} from '../../../../components';
import {Clear} from '@material-ui/icons';
import axios from 'axios';

const TEAMS_ID = "c77e5f86-ce39-4e0e-8d9b-b9eb138d63f5";

const styles = theme => ({
  subHeading: {
    color: theme.palette.text.secondary
  }
})

const HelpCardDialog = ({ classes, open, languagePack, onClose, questions = [], ...rest }) => {
  const [config, setConfig] = useState(null);
  const [cardTemplate, setCardTemplate] = useState(null);
  const [helpCard, setHelpCard] = useState(null);

  const close = () => {
    setHelpCard({...config.helpCard});
    onClose();
  }

  const getConfig = async () => {
    try{
      const { data } = await axios({
        url: `/api/configs`,
      });
      setConfig(data?.find(x => x.id === TEAMS_ID));
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  const getTemplate = async () => {
    try{
      const { data } = await axios({
        url: `/api/integration/teams/cardtemplate`,
      });
      setCardTemplate(data);
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }
  
  const getBase64 = (file) => {
    if (!file)
      return null;
      
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }
  
  const saveHelpCard = async () => {
    try {
      const { data } = await axios({
        url: `/api/config/${config.id}`,
        method: "PUT",
        data: {
          helpCard
        }
      });
      setConfig(data);
      Alerts({type: 'success', text: "Help card configuration successfully changed."});
      close();
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  useEffect(() => {
    getConfig();
    getTemplate();
  }, [])
  useEffect(() => {
    setHelpCard({...config?.helpCard})
  }, [config])
  
  const helpCardTextInput = (name, multiline=false) => <Grid item xs={12}>
    <TextInput
      multiline={multiline}
      label={languagePack.helpCard[name].label}
      value={helpCard?.[name] || ""}
      name={`helpCard-${name}`}
      type={'text'}
      onChange={e => setHelpCard({...helpCard, [name]: e.target.value })}
      autocomplete="off"
    />
  </Grid>

  const helpCardImageInput = (name) => <Grid item xs={12}>
      <TextInput
        type="file"
        InputLabelProps={{ shrink: true }}
        inputProps={{ accept: "image/png,image/jpeg"} }
        label={languagePack.helpCard[name].label} 
        onChange={async (e) => {
          const file = e.target.files[0];
          if (!file)
            return;
          if (!["image/png", "image/jpeg"].includes(file.type))
            return Alerts({ type: "error", text: "Image must be JPEG or PNG" })
          if (file.size > 500000)
            return Alerts({ type: "error", text: "Image cannot be larger than 500KB" })
          setHelpCard({...helpCard, [name]: await getBase64(file)});
        }}
        onClick={async (e) => {
          if (!helpCard)
            e.target.value = null;
        }}
        endAdornment={
          helpCard?.[name] && <IconButton size="small" onClick={(e) => {
            e.stopPropagation();
            const modifiedHelpCard = {...helpCard}; 
            delete modifiedHelpCard[name];
            setHelpCard(modifiedHelpCard) }
          }>
            <Clear fontSize="small"/>
          </IconButton>
        }
      />
    </Grid>

  const actions = questions.map(x => ({
    type: "Action.Submit",
    title: x.name,
  }));
  
  const ammendedCardTemplate = {...cardTemplate}
  ammendedCardTemplate.actions = actions;

  return (
    <Dialog maxWidth="md" open={open} onClose={close} {...rest}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h5">
          {languagePack.heading}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {languagePack.text}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subHeading} bold>
              {languagePack.subHeadings.cardSetup}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subHeading} bold>
              {languagePack.subHeadings.preview}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {helpCardTextInput("preText", true)}
              {helpCardImageInput("avatar")}
              {helpCardTextInput("title")}
              {helpCardTextInput("subtitle")}
              {helpCardImageInput("mainImage")}
              {helpCardTextInput("details", true)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                {ammendedCardTemplate && <AdaptiveCardPreview template={ammendedCardTemplate} data={helpCard} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => close()}>
          {languagePack.buttons.cancel}
        </Button>
        <Button color="info" variant="contained" disableElevation fullWidth onClick={saveHelpCard}>
          {languagePack.buttons.save}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

HelpCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  languagePack: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(HelpCardDialog);