import React, { Component } from 'react';
import {Card, Grid, Avatar, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {MoreVert, VerifiedUser, LockOpen} from '@material-ui/icons';
import {Toolbar, Table, Alerts, TextInput, Typography, Checkbox, Button, LoadingSpinner} from '../../components';
import {connect} from 'react-redux';
import axios from 'axios';
import defaults from './defaults.json';
import { UserWarningDialog, SupportAccessDialog, UserAuthDialog } from "./components";
import moment from "moment";

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      tableData: null,
      selectedUsers: [],
      selectedUser: null,
      filters: {
        search: "",
        status: defaults.filters.status,
        administrators: defaults.filters.administrators
      },
      newUser: defaults.newUser,
      supportAccess: defaults.supportAccess,
      adminConfirmDialog: defaults.adminConfirmDialog,
      userDeleteDialog: defaults.userDeleteDialog,
      toggleUserDialog: defaults.toggleUserDialog,
      userAuthDialog: defaults.userAuthDialog
    }
  }

  componentDidMount() { 
    this.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiLanguage !== this.props.uiLanguage)
      this.createTableData();
  }

  // TODO - Investigate map error thrown on auto reload
  getUsers = async () => {
    axios.get('/api/botusers').then(users => {
      axios.get('/api/botadministrators').then(async admins => {
        axios.get('/api/statistics').then(async stats => {
          users.data = await Promise.all(users.data.map(async user => {
            const admin = await admins.data.administrators.find(x => { 
              return x.username.toLowerCase() === user.email.toLowerCase();
            })

            const stat = await stats.data.users.find(x => {
              return x.userId === user.id;
            })

            if(admin) {
              user.isAdmin = true;
              user.adminDisabled = admin.disabled
            }

            if(stat) {
              user.queries = stat.total
            }

            return user;
          }));

          this.setState(state => ({
            ...state,
            users: users.data
          }), () => {
            this.createTableData(users.data);
          });
        }).catch(err => {
          Alerts({type: 'error', text: err.response?.data || err.message})
        })
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message})
      })
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    })
  }

  // !Done
  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  // !Done
  createTableData = async () => {
    let filteredUsers = [...this.state.users];
    const {search, ...rest} = this.state.filters;
    if(search || Object.values(rest).some(x => Object.values(x).includes(true))) {
      filteredUsers = await filteredUsers.filter(x => {
        if(
          (
            x.fullName?.toLowerCase().match(search.toLowerCase()) ||
            x.email?.toLowerCase().match(search.toLowerCase()) ||
            x.department?.toLowerCase().match(search.toLowerCase()) || 
            x.title?.toLowerCase().match(search.toLowerCase()) 
          ) && 
          (
            Object.values(rest.administrators).includes(true) ? 
            rest.administrators.showAdministrators && x.isAdmin: true
          ) &&
          (
            Object.values(rest.status).includes(true) ? 
            rest.status.licensed && (x.teamsId && !x.disabled && x.licensed) ||
            rest.status.notLicensed && (!x.licensed && x.active && !x.disabled) ||
            rest.status.notRegistered && (!x.teamsId && !x.disabled) ||
            rest.status.disabled && x.disabled : true 
          )
        ) {
          return true;
        }
      }
      )
    }

  

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true
      },
      {
        label: this.props.languagePack.table[2],
        sortable: true
      },
      {
        label: this.props.languagePack.table[3],
        sortable: true
      },
      {
        label: null,
        sortable: false
      }
    ]

    const tableRows = await filteredUsers.map(x => {
      let status = x.teamsId ? this.props.languagePack.filters.status.licensed : this.props.languagePack.filters.status.notRegistered;
      let statusColor = x.teamsId ? "success" : "grey";
      let roles = [{label: 'User', bgColor:{color: 'success', colorVariant: 'light'}, color: {color: 'success', colorVariant: 'main'}}];

      const actions = [
        { "display": this.props.languagePack.tableActions[x.disabled ? "enable" : "disable"], action: this.toggleUser(x.disabled)},
        { "display": this.props.languagePack.tableActions.delete, action: () => this.DialogStateHandler('userDeleteDialog'), color: 'error'},
      ]

      if(x.teamsId && !x.licensed) {
        status = this.props.languagePack.filters.status.notLicensed;
        statusColor = "warning";
      }

      if(x.disabled) {
        status = this.props.languagePack.filters.status.disabled;
        statusColor = "error"
      }

      if(x.isAdmin) {
        if(!x.adminDisabled) {
          roles.unshift({label: 'Admin', bgColor:{color: 'info', colorVariant: 'light'}, color: {color: 'info', colorVariant: 'main'}})
        }
        actions.unshift({"display": this.props.languagePack.tableActions[x.adminDisabled ? 'enableAdmin' : "disableAdmin"], action: () => this.toggleAdmin()})
      } else {
        actions.unshift({"display": this.props.languagePack.tableActions.makeAdmin, action: () => this.DialogStateHandler('adminConfirmDialog')})
      }

      return {
        id: x.id,
        cells: [
          {
            sortBy: x.fullName.toLowerCase(),
            type: 'imagetext',
            options: {
              imageUrl: `/api/botuser/${x.id}/avatar`,
              alt: x.fullName,
              text: {
                primary: x.fullName,
                secondary: x.department
              }
            }
          }, 
          x.queries ? x.queries : 0, 
          {
            sortBy: status,
            type: 'status',
            options: {
              text: status,
              color: statusColor
            }
          }, 
          {
            sortBy: roles.length ? roles[0].label : "",
            type: 'chips',
            options: {
              chips: roles
            }
          }, 
          {
            sortBy: false,
            type: 'actions', 
            options: {
              heading: this.props.languagePack.tableActions.heading,
              button: <MoreVert/>, 
              click: () => this.setUser(x.email),
              actions: actions
            }
          }
        ]
      }
    })

    const tableData = {
      rows: tableRows,
      labels: labels
    } 

    this.setState(state => ({
      ...state,
      tableData: tableData
    }))
  } 
  
  // !Done
  DialogStateHandler = (dialog, reset = true) => {
    if(this.state[dialog].open) {
      if (reset)
        this.setState(state =>({
          ...state,
          [dialog]: defaults[dialog]
        }))
      else
        this.setState(state =>({
          ...state,
          [dialog]: {
            ...state[dialog],
            open: false
          }
        }))
    } else {
      this.setState(state => ({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: !state[dialog].open
        }
      }))
    }
  }

  // !Done
  DialogFieldHandler = (event, name, val) => {
    const dialog = event ? event.target.name.split('-')[0] : name.split('-')[0];
    const field = event ? event.target.name.split('-')[1] : name.split('-')[1];

    this.setState(state => ({
      ...state,
      [dialog]: {
        ...state[dialog],
        [field]: {
          value: val || event.target.checked || event.target.value
        }
      }
    }))
  }

  // TODO Test/Amend Error Alert
  addNewUser = async () => {
    const {open, ...rest} = this.state.newUser;
    
    let shouldAdd = true;

    const payload = {};

    await Object.keys(rest).map(x => {
      payload[x] = rest[x].value;
    })

    if(shouldAdd === true) {
      axios.post('/api/botuser', payload).then(user => {
        this.DialogStateHandler('newUser')
        this.setState(state => ({
          ...state,
          users: [...state.users, user.data]
        }), () => {
          Alerts({text: 'User Successfully Added', type: "success"})
        })
      }).catch(error => {
        Alerts({text: error.response?.data || error.message, type: "error"})
      })
    }
  }

  // TODO Test/Amend Error Alert
  importUsers = async () => {
    axios.put('/api/botusers/import').then(() => {
      this.getUsers();
      Alerts({text: 'Users Successfully Imported', type: "success"})
    }).catch(error => {
      Alerts({text: error.response?.data || error.message, type: "error"})
    })
  }

  // TODO Test/Amend Error Alert
  grantTempAccess = async () => {

    if(!this.state.supportAccess.email.value) {
      return Alerts({text: "Please supply an email address", type: "warning"});
    }

    let expiry;

    switch (this.state.supportAccess.accessTime.value) {
      case '24hrs':
        expiry = moment.utc().add(1, "d");
        break;
      case '48hrs':
        expiry = moment.utc().add(2, "d");
        break;
      case '1w':
        expiry = moment.utc().add(1, "w");
        break;
      case '4w':
        expiry = moment.utc().add(4, "w");
        break;
      case 'custom':
        expiry = this.state.supportAccess.custom.value
        break;
      default:
        expiry = moment.utc().add(1, "d");
        break;
    }

    axios.put(`/api/botAdministrators/temporary/${this.state.supportAccess.email.value}`, {expiry: expiry.format()})
    .then(data => {      
      Alerts({text: `Email sent to user: ${this.state.supportAccess.email.value}`, type: "success"})
      this.DialogStateHandler('supportAccess');
    }).catch(error => {
      Alerts({text: error.response?.data || error.message, type: "error"})
    })
  }

  // TODO Test/Amend Error Alert
  makeAdmin = async () => {
    const user = this.state.selectedUser;

    axios.post('/auth/registration', {username: user.email}).then(data => {
      this.DialogStateHandler('adminConfirmDialog');
      Alerts({text: `Registration email sent to ${user.fullName}`, type: 'success'})
    }).catch(error => {
      Alerts({text: error.response?.data || error.message, type: "error"});
    })
  }

  // TODO Test/Amend Error Alert
  deleteUser = (initDialog = true) => async () => {
    const user = this.state.selectedUser;

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        userDeleteDialog: {
          ...this.state.userDeleteDialog,
          numberOfRecords: 1,
          multiple: false,
          open: true
        }
      }))
    }

    axios.delete(`/api/botuser/${user.id}`).then(async (data) => {
      const users = [...this.state.users];
      const index = await users.findIndex(x => {
        return x.id === user.id;
      })

      if(index !== -1) {
        users.splice(index, 1);
      }

      this.setState(state => ({
        ...state,
        users: users
      }), () => {
        this.getUsers();
        this.DialogStateHandler('userDeleteDialog');
      })

      Alerts({text: `User ${user.fullName} successfully deleted`, type: 'success'})
    }).catch(error => {
      Alerts({text: error.response?.data || error.message, type: "error"});
    })
  }

  toggleUser = (enableUser, initDialog = true) => async () => {
    const user = this.state.selectedUser;
    const users = [...this.state.users];

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        toggleUserDialog: {
          ...this.state.toggleUserDialog,
          numberOfRecords: 1,
          type: enableUser ? "enable" : "disable",
          multiple: false,
          open: true
        }
      }))
    }
    
    axios.patch(`/api/botuser/${user.id}`,
      JSON.stringify([
        { "op": "replace", "path": "/disabled", "value": !enableUser }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
    }).then(async (data) => {
      this.setState(state => ({
        ...state,
        users: users
      }), () => {
        this.DialogStateHandler("toggleUserDialog");
        this.getUsers();
      })
    }).catch(error => {
      Alerts({type: 'error', text: error.response?.data || error.message})
    })
  }

  userSelectionToggle = (enableUser = true, initDialog = true) => async () => {
    const selectedUsers = [...this.state.selectedUsers];
    if (selectedUsers.length === 0)
      return;

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        toggleUserDialog: {
          ...this.state.toggleUserDialog,
          numberOfRecords: selectedUsers.length,
          type: enableUser ? "enable" : "disable",
          multiple: true,
          open: true
        }
      }))
    }

    const results = await Promise.allSettled(selectedUsers.map(async id => {

      const { data } = await axios.patch(`/api/botUser/${id}`,
        JSON.stringify([
        { "op": "replace", "path": "/disabled", "value": !enableUser }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
      });
      selectedUsers.splice(selectedUsers.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully ${enableUser ? "enabled" : "disabled"} ${successfulResults.length} user${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to ${enableUser ? "enable" : "disable"} ${failedResults.length} user${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully ${enableUser ? "enabled" : "disabled"} ${successfulResults.length} user${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to ${enableUser ? "enable" : "disable"} ${failedResults.length} user${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

    this.setState(state => ({
      ...state,
      toggleUserDialog: {
        ...this.state.toggleUserDialog,
        open: false
      },
      selectedUsers
    }), () => {
      this.getUsers();
    })
  }

  userSelectionDelete = (initDialog = true) => async () => {
    const selectedUsers = [...this.state.selectedUsers];
    if (selectedUsers.length === 0)
      return;

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        userDeleteDialog: {
          ...this.state.userDeleteDialog,
          numberOfRecords: selectedUsers.length,
          multiple: true,
          open: true
        }
      }))
    }

    const results = await Promise.allSettled(selectedUsers.map(async id => {

      await axios.delete(`/api/botUser/${id}`);
      selectedUsers.splice(selectedUsers.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    
    
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully deleted ${successfulResults.length} user${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} user${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully deleted ${successfulResults.length} user${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to delete ${failedResults.length} user${failedResults.length > 1 ? "s" : ""}.`, type: "error" });


    this.setState(state => ({
      ...state,
      userDeleteDialog: {
        ...this.state.userDeleteDialog,
        open: false
      },
      selectedUsers
    }), () => {
      this.createTableData();
      this.getUsers();
    })
  }

  toggleAdmin = async () => {
    const user = this.state.selectedUser;
    if(user) {
      axios.put(`/api/botAdministrator/${user.adminId}/disabled/toggle`).then(data => {
        const users = [...this.state.users];
        const selectedUser = users.find(x => {
          return x.adminId === user.adminId;
        })

        selectedUser.adminDisabled = data.data;

        this.setState(state => ({
          ...state,
          users: users
        }), () => {
          this.createTableData();
        })
      }).catch(error => {
        Alerts({type: 'error', text: error.response?.data || error.message})
      })
    }
  }

  // !Done
  setUser = async (u) => {
    const user = await this.state.users.find(x => {
      return x.email.toLowerCase() === u.toLowerCase();
    })
    if(user) {
      this.setState(state => ({
        ...state,
        selectedUser: user
      }))
    }
  }

  userSelectionHandler = async (user) => {
    let selectedUsers = [...this.state.selectedUsers];

    if(user) {   
      if(selectedUsers.includes(user)) {
        const index = selectedUsers.indexOf(user);
        selectedUsers.splice(index, 1);
      } else {
        selectedUsers = [...selectedUsers, user];
      }
    } else {
      if(selectedUsers.length === this.state.tableData.rows.length) {
        selectedUsers = [];
      } else {
        this.state.tableData.rows.map(x => {
          if(!selectedUsers.includes(x.id)){
            selectedUsers.push(x.id)
          }
        }) 
      }
    }
    
    this.setState(state => ({
      ...state,
      selectedUsers: selectedUsers
    }));
  }

  // !Done
  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }), () => {
      this.createTableData();
    })

  }

  render() {
    const {classes, languagePack, theme} = this.props;
    if(!this.state.users || !this.state.tableData) {
      return <LoadingSpinner/>
    }

    const tfOptions = [
      '24hrs',
      '48hrs',
      '1w',
      '4w',
      'custom'
    ];

    const newUserDialog = (
      <Dialog open={this.state.newUser.open} onClose={() => this.DialogStateHandler('newUser')} maxWidth='xs'>
        <DialogTitle disableTypography={true}>
          <Typography variant="h5">
            {languagePack.newUserModal.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {languagePack.newUserModal.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                key="newUser-fullName"
                name="newUser-fullName"
                label={languagePack.newUserModal.fields.fullname.label}
                value={this.state.newUser.fullName.value}
                onChange={(event) => this.DialogFieldHandler(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                key="newUser-email"
                name="newUser-email"
                label={languagePack.newUserModal.fields.email.label}
                value={this.state.newUser.email.value}
                onChange={(event) => this.DialogFieldHandler(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                key="newUser-department"
                name="newUser-department"
                label={languagePack.newUserModal.fields.department.label}
                value={this.state.newUser.department.value}
                onChange={(event) => this.DialogFieldHandler(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="newUser-isAdmin"
                    value={this.state.newUser.isAdmin.value}
                    onChange={(event) => this.DialogFieldHandler(event)}
                  />
                }
                label={languagePack.newUserModal.fields.admin.label}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => this.DialogStateHandler('newUser')}>
            {languagePack.newUserModal.buttons.cancel}
          </Button>
          <Button fullWidth color="info" variant="contained" onClick={this.addNewUser}>
            {languagePack.newUserModal.buttons.submit}
          </Button>
        </DialogActions>
      </Dialog>
    )
    
    const adminConfirmDialog = (
      <Dialog open={this.state.adminConfirmDialog.open} onClose={() => this.DialogStateHandler('adminConfirmDialog')} maxWidth='xs'>
        <DialogTitle disableTypography={true}>
          <Typography variant="h5">
            {languagePack.adminConfirmModal.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {`${languagePack.adminConfirmModal.text[0]} ${this.state.selectedUser?.fullName} ${languagePack.adminConfirmModal.text[1]}\n\n`}
              </Typography>
              <Typography variant="body1">
                {languagePack.adminConfirmModal.additionalText}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth  onClick={() => this.DialogStateHandler('adminConfirmDialog')}>
            {languagePack.adminConfirmModal.buttons.cancel}
          </Button>
          <Button fullWidth color="info" variant="contained" onClick={this.makeAdmin}>
            {languagePack.adminConfirmModal.buttons.submit}
          </Button>
        </DialogActions>
      </Dialog>
    )
    
    const noneSelected = this.state.selectedUsers.length === 0;
    return (
      <React.Fragment>
        {newUserDialog}
        <SupportAccessDialog
          open={this.state.supportAccess.open}
          close={() => this.DialogStateHandler('supportAccess')}
          languagePack={languagePack.supportAccessModal}
          dialogFields={this.state.supportAccess}
          fieldHandler={this.DialogFieldHandler}
          grantAccess={this.grantTempAccess}
          timeFrameOptions={tfOptions}
        />
        {adminConfirmDialog}
        <UserWarningDialog
          languagePack={languagePack.toggleUserDialog[this.state.toggleUserDialog.type]}
          numberOfRecords={this.state.toggleUserDialog.numberOfRecords}
          onClose={() => this.DialogStateHandler("toggleUserDialog", false)}
          onCancel={() => this.DialogStateHandler("toggleUserDialog", false)}
          onConfirm={this.state.toggleUserDialog.multiple ? this.userSelectionToggle(this.state.toggleUserDialog.type === "enable", false) : this.toggleUser(this.state.toggleUserDialog.type === "enable", false)}
          confirmColor={this.state.toggleUserDialog.type === "enable" ? "success" : "error"}
          open={this.state.toggleUserDialog.open}
        />
        <UserWarningDialog
          languagePack={languagePack.userDeleteDialog}
          numberOfRecords={this.state.userDeleteDialog.numberOfRecords}
          onClose={() => this.DialogStateHandler("userDeleteDialog", false)}
          onCancel={() => this.DialogStateHandler("userDeleteDialog", false)}
          onConfirm={this.state.userDeleteDialog.multiple ? this.userSelectionDelete(false) : this.deleteUser(false)}
          confirmColor="error"
          open={this.state.userDeleteDialog.open}
        />
        <UserAuthDialog
          open={this.state.userAuthDialog.open}
          languagePack={languagePack.userAuthDialog}
          onClose={() => this.DialogStateHandler("userAuthDialog", false)}
        />
        <Card elevation={0} className={['cardWrapper', 'stickyToolbar'].join(' ')}>
          <Grid container>
            <Grid item xs={12} className="toolbarWrapper">
              <Toolbar
                filterValue={this.state.filters.search}
                filterFieldHandler={this.filterFieldHandler}
                filterTranslation={languagePack.filters}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                actions={[
                  {icon: <LockOpen fontSize="small"/>, tooltip: languagePack.actions.userAuth, color: theme.palette.warning.main,  onClick: () => this.DialogStateHandler("userAuthDialog", true)},
                  {type: "enable", disabled: noneSelected, tooltip: languagePack.actions.enable, onClick: this.userSelectionToggle()},
                  {type: "disable", disabled: noneSelected, tooltip: languagePack.actions.disable, onClick: this.userSelectionToggle(false)},
                  {icon: <VerifiedUser fontSize="small"/>, tooltip: languagePack.actions.temporaryAdmin, color: theme.palette.warning.main,  onClick: () => this.DialogStateHandler('supportAccess')},
                  {type: "add", tooltip: languagePack.actions.add, onClick: () => this.DialogStateHandler('newUser')},
                  {type: "delete", disabled: noneSelected, tooltip: languagePack.actions.delete, onClick: this.userSelectionDelete()},
                ]}
                recordNumber={this.state.users?.length || 0}
                selectedNumber={this.state.selectedUsers?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
              />
            </Grid>
            <Grid item xs={12} className='h100'>
              <Table
                stickyHeader
                tableData={this.state.tableData}
                recordsPerPage={15}
                defaultSort={1}
                defaultSortOrder='desc'
                dense
                selectionHandler={this.userSelectionHandler}
                selectedArr={this.state.selectedUsers}
              />
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const page = window.location.pathname.split('/')[1]
  return {
    uiLanguage: state.uiLanguage,
    languagePack: state.languagePack[page]
  }
}


export default connect(mapStateToProps)(withStyles(styles)(withTheme(Users)));