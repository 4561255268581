import React, {useState} from 'react';
import { AppBar, IconButton, Menu, MenuItem, ListItemIcon, Badge } from '@material-ui/core';
import {useTheme, withStyles} from '@material-ui/styles';
import { TagFaces, ErrorOutline, Error } from '@material-ui/icons';
import {Tooltip, Typography} from '../../../../components';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import helpers from '../../../../helpers/helper';

const styles = theme => ({
  topbar: {
    height: "55px",
    width: "100% !important",
    backgroundColor: "#fff!important",
    borderBottom: "1px solid #dee2e6!important",
    zIndex: "1020!important",
    top: "0!important",
    color: "#000!important",
    paddingRight: theme.spacing(2)
  },
  content: {
    height: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginLeft: '72px'
  },
  heading: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  typography: {
    marginBottom: "0!important"
  },
  buttons: {
    display: "flex",
    marginTop: "auto!important",
    marginBottom: "auto!important"
  },
  languageButton: {
    width: "25px",
    height: "25px",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: "12px",
    cursor: "pointer"
  },
  feedback: {
    color: theme.palette.grey["400"]
  },
  support: {
    color: theme.palette.grey["400"]
  },
  menuItem: {
    fontWeight: 400,
    color: "#212529",
    textAlign: "inherit",
    whiteSpace: "nowrap",
    fontSize: "16px"
  },
  menuItemImg: {
    width: "20px",
    height: "20px"
  },
  badge: {
    backgroundColor: theme.palette.error.dark
  }
})

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      flags: null
    };
    this.setMenuClose = this.setMenuClose.bind(this)
  }

  componentDidMount() {
    this.setFlags();
    helpers.setErrorCount()
  }

  setFlags = async () => {
    const flags = {};
    await Promise.all(this.props.supported.map(async x => {
      await import(`../../../../assets/flags/${x.image}.svg`).then(image => {
        flags[x.language] = image.default;
      })
    }))
    
    this.setState(state => ({
      ...state,
      flags: flags
    }))
  }

  setLanguage = async event => {
    let language = event.currentTarget.id;
    import(`../../../../translation/static/${language}.json`).then(async x => {
      import(`../../../../translation/languages/${language}.json`).then(async k => {
        
        const languagePacks = {};
        await Promise.all(Object.keys(this.props.integrations).map(async x => {
          await import (`../../../../translation/integrations/${x.toLowerCase()}/${language}.json`).then(integration => {
            languagePacks[x.toLowerCase()] = integration.default[x.toLowerCase()];
          })
        }))

        localStorage.setItem("language", language);
        await this.props.setlanguage({uiLanguage: language, languagePack: x.default, integrationLanguagePacks: languagePacks, languages: k.default});
      })
      
    })

    this.setMenuClose();
  }
  
  setMenuOpen = (event) => {
    this.setState({open: true, anchorEl: event.target})
  }
 
  setMenuClose = () => {
    this.setState({open: false})
  }
  render() {
    const {title, language, supported, uiLanguage, errorCount, classes, dialogHandler} = this.props;
    
    return ( 
      <AppBar className={classes.topbar}>
        <div className={classes.content}>
          <div className={classes.heading}>
            <Typography variant="body1" className={classes.typography} bold>
              {title}
            </Typography>
          </div>
          <div className={classes.buttons}>
          <Tooltip text={language.tooltips.error} placement="bottom">
              <IconButton id='support-button' className="topnav-btn" className={classes.support} onClick={() => this.props.history.push('/error-log')}>
                <Badge badgeContent={errorCount} color="error" max={99} classes={{badge: classes.badge}}>
                  <Error fontSize="small"/>
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip text={language.tooltips.feedback} placement="bottom">
              <IconButton id='feedback-button' className="topnav-btn" className={classes.feedback} onClick={dialogHandler}>
                <TagFaces fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip text={language.tooltips.support} placement="bottom">
              <IconButton id='support-button' className="topnav-btn" className={classes.support} onClick={dialogHandler}>
                <ErrorOutline fontSize="small" />
              </IconButton>
            </Tooltip>
            {this.state.flags ? 
            <React.Fragment>
              <Tooltip text={language.tooltips.language} placement="bottom">
              <div className={classes.languageButton} onClick={this.setMenuOpen}>
                <img src={this.state.flags[uiLanguage]}/>
              </div>
              </Tooltip>
              <Menu
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={this.setMenuClose}
                keepMounted
              >
                {
                  supported.map(x => (
                    <MenuItem id={x.language} key={x.language} className={classes.menuItem} onClick={this.setLanguage}>
                      <ListItemIcon>
                        <img className={classes.menuItemImg} src={this.state.flags[x.language]}/>
                      </ListItemIcon>
                      {x.display}
                    </MenuItem>
                  ))
                }
              </Menu>
            </React.Fragment> : null}
          </div>
        </div>
      </AppBar>
    )
  }
}

const mapStateToProps = ({uiLanguage, supported, integrations, page, errorCount}) => {
  return {
    uiLanguage: uiLanguage, 
    supported: supported,
    integrations: integrations,
    page: page,
    errorCount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setlanguage: x => dispatch({type: 'SETLANGUAGE', payload: x}),
    setErrorCount: x => dispatch({type: 'SETERRORCOUNT', payload: x})
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar)));