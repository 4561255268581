import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, Status, Autocomplete, TextInput, Alerts, Typography } from '../../../../components';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/styles';
import axios from 'axios';
import { LanguageWarningDialog } from './components';

const styles = theme => ({
  list: {
    backgroundColor: "#fff",
    paddingLeft: theme.spacing(2) 
  },
  removeButton: {
    color: theme.palette.error.main,
    textDecoration: "none",
    borderBottom: `2px solid ${theme.palette.error.main}`,
    ...theme.typography.bold
  }
})

class LanguageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableLanguages: null,
      selectedLanguage: null,
      translateFrom: null,
      enabledLanguages: this.props.enabledLanguages || [],
      languageDeleteDialog: {
        open: false
      },
      languageToDelete: null,
    }
  }  

  DialogStateHandler = (dialog, open) => () => {
    if (this.state[dialog].open) {
      this.setState(state =>({
        ...state,
        [dialog]: {
          open: false
        }
      }))
    } else {
      this.setState(state => ({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: true
        }
      }))
    }
  }

  enableLanguage = (skipTranslation) => () => {
    axios.post("/api/languages/enable" + (!skipTranslation ? '?translateFrom=' + this.state.translateFrom.language : ''), {language: this.state.selectedLanguage.languageCode}).then(data => {
      this.props.updateEnabledLangauges();
      Alerts({type: 'success', text: !skipTranslation ? 'Translation Successful' : 'Language Successfully Enabled'});
      this.props.close();
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  setLanguage = (e, val) => {
    this.setState(state => ({
      ...state,
      selectedLanguage: val
    }))
  }

  setTranslation = (e, val) => {
    this.setState(state => ({
      ...state,
      translateFrom: val
    }))
  }

  reset = () => {
    this.setState(state => ({
      ...state,
      selectedLanguage: null,
      translateFrom: null
    }))
    this.props.exit();
  }

  deleteSelectedLanguage = () => {
    const { id, language } = this.state.languageToDelete;
    const { allLanguages } = this.props;
    
    axios.delete(`/api/language/${id}`).then(data => {
      this.props.updateEnabledLangauges();
      Alerts({type: 'success', text: `'${allLanguages[language]}' Successfully Deleted`});
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }).finally(() => {
      this.DialogStateHandler("languageDeleteDialog")();
    })
  }

  removeButtonHandler = (language) => (e) => {
    e.preventDefault();

    this.setState(state => ({
      ...state,
      languageToDelete: language
    }))
    
    this.DialogStateHandler("languageDeleteDialog")();
  }

  render() {
    const {classes, step, open, close, stepHandler, languagePack, enabledLanguages, allLanguages, exit} = this.props;
    const steps = [
      {
        header: (
          <Typography variant="h5">
            {languagePack.languages.heading}
          </Typography>
        ),
        content: (
            <Grid container spacing={2} justify='space-between'>
              <Grid item xs={12}>
                <Typography>
                  {languagePack.languages.helpText}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.list}>
                {
                  enabledLanguages ? enabledLanguages.map(x => (
                    <React.Fragment key={x.language}>
                      <Grid item xs={6} >
                        <Status text={allLanguages[x.language]} color='common' colorVariant='black'/>
                      </Grid>
                      <Grid item xs={6} key={x.language}>
                        <a href="#" className={classes.removeButton} onClick={this.removeButtonHandler(x)}>Remove</a>
                      </Grid>
                    </React.Fragment>
                  )) : null
                }
                </Grid>
              </Grid>
            </Grid>

        ),
        footer: (
          <React.Fragment>
            <Button fullWidth onClick={close}>
              {languagePack.languages.buttons.close}
            </Button>
            <Button fullWidth color='info' variant='contained' onClick={() => stepHandler('next')}>
              {languagePack.languages.buttons.enable}
            </Button>
          </React.Fragment>
        )
      },
      {
        header: (
          <Typography variant="h5">
            {languagePack.newLanguage.heading}
          </Typography>
        ),
        content: (
          <Grid container spacing={2} justify='space-between'>
            <Grid item xs={12}>
              <Typography>
                {languagePack.newLanguage.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={this.state.selectedLanguage}
                key="enable"
                options={this.props.availableLanguages}
                getOptionLabel={option => option.language}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    fullWidth
                    size="medium"
                    variant="filled"
                    label={languagePack.newLanguage.fields.selectLanguage.label}
                  />
                )}
                onChange={this.setLanguage}
              />
            </Grid>
          </Grid>
        ),
        footer: (
          <React.Fragment>
            <Button fullWidth onClick={() => stepHandler('prev')}>
              {languagePack.newLanguage.buttons.cancel}
            </Button>
            <Button disableElevation fullWidth color='info' variant='contained' onClick={() => stepHandler('next')} disabled={!this.state.selectedLanguage}>
              {languagePack.newLanguage.buttons.next}
            </Button>
          </React.Fragment>
        )
      },
      {
        header: (
          <Typography variant="h5">
            {languagePack.translate.heading}
          </Typography>
        ),
        content: (
          <Grid container spacing={2} justify='space-between'>
            <Grid item xs={12}>
              <Typography>
                {languagePack.translate.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={this.state.translateFrom}
                key='translate'
                options={enabledLanguages}
                getOptionLabel={option => option?.label}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    size='medium'
                    fullWidth
                    variant="filled"
                    label={languagePack.translate.fields.selectLanguage.label}
                  />
                )}
                onChange={this.setTranslation}
              />
            </Grid>
          </Grid>
        ),
        footer: (
          <React.Fragment>
            <Button fullWidth onClick={() => stepHandler('prev')}>
              {languagePack.translate.buttons.cancel}
            </Button>
            <Button disableElevation fullWidth color='info' variant='contained' onClick={this.enableLanguage(true)}>
              {languagePack.translate.buttons.skip}
            </Button>
            <Button disableElevation fullWidth color='success' variant='contained' disabled={!this.state.translateFrom} onClick={this.enableLanguage()}>
              {languagePack.translate.buttons.translate}
            </Button>
          </React.Fragment>
        )
      }
    ]

    return (
      <>
      <LanguageWarningDialog
        languagePack={languagePack.languageDeleteDialog}
        open={this.state.languageDeleteDialog.open}
        numberOfRecords={1}
        onCancel={this.DialogStateHandler("languageDeleteDialog")}
        onClose={this.DialogStateHandler("languageDeleteDialog")}
        onConfirm={this.deleteSelectedLanguage}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={close} onExited={(e) => { this.reset(e); exit(e);}}>
        <DialogTitle disableTypography={true}>
          {steps[step].header}
        </DialogTitle>
        <DialogContent>
          {steps[step].content}
        </DialogContent>
        <DialogActions>
          {steps[step].footer}
        </DialogActions>
      </Dialog>
      </>
    );
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setEnabledLanguages: x => dispatch({type: 'SETENABLEDLANGUAGES', payload: x}),
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(LanguageDialog));