import React, {useState} from 'react';
import {Sidebar, Topbar} from './components';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/styles';
import defaults from './defaults.json';
import axios from "axios";
import {Alerts} from '../../components';

const styles = theme => ({
  content: {
    height: '100%',
    padding: theme.spacing(1)
  },
  wrapper: {
    padding: '55px 0 0 0'
  }
});

class Minimal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      availableLanguages: null,
      systemInfo: null
    }
  }
  
  componentDidMount() {
    this.getEnabledLanguages();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.page !== this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle) {
      this.setState(state => ({
        ...state,
        page: this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle
      }))
    }
    if (prevProps.uiLanguage !== this.props.uiLanguage && this.props.enabledLanguages)
      this.getEnabledLanguages();
  }

  getEnabledLanguages = () => {
    const languages = this.props.allLanguages;
    axios.get('/api/languages/enabled').then(async (data) => {
      let sorted = data.data.map(x => {
        return {...x, label: languages[x.language]}
      }).sort(function (a, b) { 
        if (languages[a.language] < languages[b.language]) {
          return -1;
        }
        if (languages[a.language] > languages[b.language]) {
          return 1;
        }
        return 0
      });

      const { uiLanguage, currentLanguage, setCurrentLanguage } = this.props;
      if(!currentLanguage || !sorted.some(x => x.language === currentLanguage)) {
        const cL = sorted.find(x => x.language === uiLanguage?.split("-")?.[0]) || sorted?.[0];
        setCurrentLanguage({ currentLanguage: cL?.language || null });
      }

      await this.props.setEnabledLanguages({enabledLanguages: sorted})
      await this.getAvailableLanguages(sorted)

    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }
  
  getAvailableLanguages = (languages) => {
    if(!this.props.enabledLanguages && !languages) {
      return;
    }

    const enabledLanguages = languages || this.props.enabledLanguages;

    const available = Object.keys(this.props.allLanguages).map(x => {      
      if(!enabledLanguages.find(k => k.language === x)){
        return {languageCode: x, language: this.props.allLanguages[x]}
      }
      return;
    }).filter(k => k !== undefined)

    this.setState(state => ({
      ...state,
      availableLanguages: available
    }))

    return available;
  }

 
  render() {
    const {classes, children, navigation, modals} = this.props;
    if(!navigation.navigation && !navigation.topNavigation) {
      return null
    }

    return(
      <React.Fragment>
        <div className={classes.wrapper}>
          <Topbar language={navigation.topNavigation} title={this.state.page}/>
          <main className={classes.content}>
            {children}
          </main>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({languagePack, languages, enabled, currentLanguage, uiLanguage}) => {
  const page = window.location.pathname.split('/')[1];
  return {
    languagePack: languagePack,
    navigation: {navigation: languagePack.navigation, topNavigation: languagePack.topNavigation, title: languagePack[page].pageTitle},
    modals: {support: languagePack.supportModal, feedback: languagePack.feedbackModal, language: languagePack.languageModal, systemInfo: languagePack.systemInfoModal},
    allLanguages: languages,
    enabledLanguages: enabled,
    currentLanguage,
    uiLanguage
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setEnabledLanguages: x => dispatch({type: 'SETENABLEDLANGUAGES', payload: x}),
    setCurrentLanguage: x => dispatch({type: 'SETCURRENTLANGUAGE', payload: x}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Minimal));