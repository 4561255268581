import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {TextInput, Typography, Button, Autocomplete, Alerts, AdaptiveCardPreview} from '../../../../components';
import axios from 'axios';

import commandSetup from "./commandsetup";
import { Template } from "adaptivecards-templating";

import { filterCommandValues } from "../../customcommands";

import defaults from "./defaults.json";

const styles = theme => ({
  subHeading: {
    color: theme.palette.text.secondary
  },
  acPreviewButton: {
    display: "flex",
    justifyContent: "center"
  },
  sticky: {
    position: "sticky",
    top: 0
  }
})

// yep
export const addInitialAdditionalField = (template, commandValues) => {
  const values = { ...commandValues };
  for (const field of template.additionalFields)
  if (!values[field.name] || values[field.name].length === 0){
    values[field.name] = [null];
  }
  return values;
}

const CommandDialog = ({ classes, open, languagePack, command, configs, integrations, templates, onCancel, onSave, onSaveDraft, onUpdate, dialogStep, ...rest }) => {
  const [step, setStep] = useState(step || defaults.step);
  const [dependencies, setDependencies] = useState(defaults.dependencies);

  // Useless
  const commandAppHandler = (event, value) => {
    const updatedCommand = {
      ...(command?.id && { id: command.id}),
      ...(command?.custom && { custom: command.custom}),
      ...(command?.draft && { draft: command.draft}),
      ...(command?.enabled && { enabled: command.enabled}),
      ...(command?.method && { method: command.method}),
      ...(command?.name && { name: command.name}),
      ...(value?.id && { integrationId: value.id})
    };
    onUpdate(updatedCommand);
  }


  // yep
  const templateHandler = (event, value) => {
    const updatedCommand = {
      ...command,
      "templateId": value?.id,
      "values": {}
    };

    updatedCommand.values = addInitialAdditionalField(value, updatedCommand.values);
    onUpdate(updatedCommand);
  }

  // yep
  const removeTemplatePreview = () => {
    setDependencies({...dependencies, templatePreview: null});
  }

  // yep
  const defaultTemplateData = (template) => {
    if (!template)
      return;

    const defaultData = {};

    for (const { name, defaultValue, image } of template.fields)
      if (image)
        defaultData[name] = `${process.env.PUBLIC_URL}/default.png`;
      else
        defaultData[name] = defaultValue;

    for (const { name, defaultValue } of template.additionalFields)
      defaultData[name] = defaultValue;

    const t = new Template(template.adaptiveCard);
    return t.expand({ $root: defaultData });
  }

  // yep
  const templatePreviewHandler = async () => {
    try {
      const commandToPreview = { ...command };
      commandToPreview.values = filterCommandValues(commandToPreview.values);

      const { data } = await axios.post(`/api/commands/custompreview`, commandToPreview);
      setDependencies({ ...dependencies, templatePreview: data });
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  // yep
  const dialogCacheHandler = (dependency, value) => {
    setDependencies({...dependencies, [dependency]: value})
  }

  
  const dialogFieldHandler = event => {
    const field = event.target.name.split('-')[1];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const updatedCommand = {...command, [field]: value};
    onUpdate(updatedCommand);
  }

  useEffect(() => {
    if (step === "card" && templates && !command.templateId) {
      onUpdate({
        ...command,
        templateId: templates[0]?.id
      })
    }
  }, [step])

  useEffect(() => {
    if (open === true && dialogStep) {
      setStep(dialogStep)
    } else if (open === false && command?.id) {
      removeTemplatePreview();
    }
  }, [open]);

  useEffect(() => {
    removeTemplatePreview();
  }, [command.templateId]);

  const integrationsWithId = Object.keys(integrations).map(x => ({ ...integrations[x], id: x }))
  const validIntegrations = integrationsWithId.filter(x => commandSetup[x.id] !== undefined);
  const selectedIntegration = validIntegrations.find(x => x.id === command.integrationId) || null;
  
  const selectedIntegrationConfig = configs?.find(x => x.id === selectedIntegration?.id) || null;
  
  let adaptiveCard;
  let selectedTemplate

  if (step === "card") {
    selectedTemplate = templates?.find(x => x.id === command.templateId) || null;
    const defaultTemplate = defaultTemplateData(selectedTemplate);
    adaptiveCard = dependencies?.templatePreview?.content || defaultTemplate || {};
  }


  const IntegrationSetup =  commandSetup[command.integrationId]?.setup;
  const IntegrationCardSetup =  commandSetup[command.integrationId]?.card;


  const commandValidation = {
    setup: () => {
      const values = [
        "name",
        "integrationId",
        "method"
      ];
      return values.every(x => command[x]) && commandSetup[command.integrationId]?.validation(command) === true;
    },
    // card: () => {
    //   if (!selectedTemplate)
    //     return false;

    //   if (selectedTemplate.fields.length > 0)
    //     for (const field of selectedTemplate.fields)
    //       if (!command.values[field.name])
    //         return false;
      
    //   // if (selectedTemplate.additionalFields.length > 0)
    //   //   for (const field in selectedTemplate.additionalFields)
    //   //     if (command.values[field.name].length === 0)
    //   //       return false;
      
    //   return true;
    // }
  }

  const commandDialogSteps = {
    setup: {
      maxWidth: "md",
      dialogContent: (
        <>
          <DialogTitle disableTypography={true}>
            <Typography variant="h5">
              {languagePack.setup.heading}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="body1">
                  {languagePack.setup.text}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.subHeading} bold>
                      {languagePack.setup.subHeadings.commandSetup}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      key="command-name"
                      name="command-name"
                      label={languagePack.setup.fields.name.label}
                      value={command.name}
                      onChange={(event) => dialogFieldHandler(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      key="command-description"
                      name="command-description"
                      label={languagePack.setup.fields.description.label}
                      value={command.description}
                      onChange={(event) => dialogFieldHandler(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={selectedIntegration}
                      disabled={command?.id !== undefined}
                      loading={validIntegrations === null}
                      key={`command-integrationId`}
                      options={validIntegrations}
                      getOptionLabel={(option) => option.product}
                      label={languagePack.setup.fields.app.label}
                      onChange={commandAppHandler}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      key="command-method"
                      name="command-method"
                      label={languagePack.setup.fields.method.label}
                      value={command.method}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.subHeading} bold>
                    {languagePack.setup.subHeadings.searchSetup}
                  </Typography>
                </Grid>
                  {
                    IntegrationSetup && <IntegrationSetup 
                      command={command} 
                      config={selectedIntegrationConfig} 
                      languagePack={languagePack["integrationFields"][selectedIntegration.id]} 
                      integration={selectedIntegration} 
                      onUpdate={onUpdate} 
                      dependencies={dependencies} 
                      onDependancyCache={dialogCacheHandler} 
                    /> 
                  }
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={onCancel}>
              {languagePack.setup.buttons.cancel}
            </Button>
            <Button disabled={!commandValidation.setup()} color="info" variant="contained" disableElevation fullWidth onClick={() => { setStep("card") }}>
              {languagePack.setup.buttons.next}
            </Button>
          </DialogActions>
        </>
      )
    },
    card:{
      maxWidth: "md",
      dialogContent: (
        <>
          <DialogTitle disableTypography={true}>
            <Typography variant="h5">
              {languagePack.card.heading}
            </Typography>

          </DialogTitle>
          <DialogContent>
  
          <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography variant="body1">
                  {languagePack.card.text}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subHeading} bold>
                  {languagePack.card.subHeadings.cardSetup}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subHeading} bold>
                  {languagePack.card.subHeadings.preview}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      value={selectedTemplate}
                      key={`command-templateId`}
                      options={templates}
                      getOptionLabel={(option) =>  option.name}
                      renderInput={(params) => <TextInput {...params} label={languagePack.card.fields.cardTemplate.label} />}
                      onChange={templateHandler}
                    />
                  </Grid>
                  {
                    (IntegrationCardSetup && command.templateId) && <IntegrationCardSetup command={command} template={selectedTemplate} languagePack={languagePack["integrationFields"][selectedIntegration.id]} integration={selectedIntegration} onUpdate={onUpdate} dependencies={dependencies} onDependancyCache={dialogCacheHandler}/> 
                  }
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid spacing={2} className={classes.sticky} container>
                  <Grid item xs={12}>
                    <AdaptiveCardPreview template={adaptiveCard} />
                  </Grid>
                  <Grid item xs={12} className={classes.acPreviewButton} style={{padding: "10px"}}>
                    <Button size="small" onClick={templatePreviewHandler}>
                      {languagePack.card.buttons.preview}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={() => { setStep("setup") }}>
              {languagePack.card.buttons.back}
            </Button>
            <Button color="info" variant="contained" fullWidth disableElevation onClick={onSaveDraft}>
              {languagePack.card.buttons.saveAsDraft}
            </Button>
            <Button color="success" variant="contained" fullWidth disableElevation onClick={onSave}>
              {languagePack.card.buttons.saveAndEnable}
            </Button>
          </DialogActions>
        </>
      )
    }
  }

  const currentStep = commandDialogSteps[step];

  return (
    <Dialog open={open} maxWidth={currentStep.maxWidth} {...rest}>
      {currentStep.dialogContent}
    </Dialog>
  )
}

CommandDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  languagePack: PropTypes.object.isRequired,
  command: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  templates: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onUpdate: PropTypes.func
}

export default withStyles(styles)(CommandDialog);