import React, { Component } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {TextInput, Button, Alerts, Typography} from '../../../../components';
import axios from 'axios';
import {connect} from 'react-redux';

class TranslateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: null
    }
  }

  reset = () => {
    this.setState(state => ({
      ...state,
      selectedLanguage: null
    }))
  }
  
  setLanguage = (e, val) => {
    this.setState(state => ({
      ...state,
      selectedLanguage: val
    }))
  }

  translate = () => {
    axios.post('/api/translate/question/'+this.props.currentLanguage, {
      answer: this.props.question.answers.filter(x => x.language === this.state.selectedLanguage.language).map(x => x.answer),
      questions: this.props.question.questions.filter(x => x.language === this.state.selectedLanguage.language).map(x => x.question)
    }).then(data => {
      this.props.updateTranslatedQuestion(data.data.answer.map(x => {return {answer: x,language: this.props.currentLanguage}}), data.data.questions.map(x => {return {question: x,language: this.props.currentLanguage, userSuggestion: false}}));
      this.props.close();
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    })
  }
  
  render() {
    const {languagePack, open, close, enabledLanguages} = this.props;
    return (
      <Dialog fullWidth maxWidth='sm' open={open} onClose={close} onExited={this.reset}>
        <DialogTitle disableTypography={true}>
          <Typography variant='h5'>
            {languagePack.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justify='space-between'>
            <Grid item xs={12}>
              <Typography>
                {languagePack.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete 
                fullWidth
                options={enabledLanguages}  
                disableClearable
                renderInput={(params) => <TextInput {...params} label={languagePack.fields.language.label}/>}
                getOptionLabel={x => x.label}
                onChange={this.setLanguage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            {languagePack.buttons.cancel}
          </Button>
          <Button fullWidth color='success' variant='contained' onClick={this.translate} disabled={!this.state.selectedLanguage}>
            {languagePack.buttons.translate}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLanguage: state.currentLanguage
  }
}

export default connect(mapStateToProps)(TranslateModal);