import React, { Component } from 'react';
import {Grid, Card} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Chip, Typography} from '../../../../components';
import helpers from '../../../../helpers/helper';


const styles = theme => ({
  logo: {
    width: '64px',
    height: '64px',
  },
  appCard: {
    display: "flex",
    flexDirection: 'column',
    cursor: 'pointer',
    '& > div': {
      marginBottom: 24,
    },
    '& > div:last-of-type': {
      marginBottom: 0,
    },
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.elevations.fluffy[3]
    },
    padding: "24px"
  },
  appCardHeading: {
    display: "flex",
    justifyContent: 'space-between'
  },
  checkIcon: {
    color: theme.palette.success.main
  },
  cardText: {
    flexGrow: 1
  },
  comingSoon: {
    backgroundColor: "rgba(244,246,249,1)"
  }
})

const AppstoreCard = (props) => {
  const {classes, config, reference, languagePack, integrationLanguagePack, configSelectHandler} = props;
  let status = null 
  const isComingSoon = reference.status === 'comingSoon';
  if(isComingSoon) {
    status = (
      <Typography variant="body2" bold>
        {languagePack.comingSoon || "Coming Soon"}
      </Typography>
    )
  }

  const chipColorReference = {
    "chatbot": {
      bg: ['info', 'main'],
      text: ['info', 'contrastText']
    },
    "directoryServices": {
      bg: ['error', 'main'],
      text: ['error', 'contrastText']
    },
    "botIntelligence": {
      bg: ['success', 'main'],
      text: ['success', 'contrastText']
    },
    "serviceManagement": {
      bg: ['warning', 'main'],
      text: ['common', 'white']
    },
    "customerSatisfaction": {
      bg: ['primary', 'main'],
      text: ['primary', 'contrastText']
    },
    "misc": {
      bg: ['grey', 500],
      text: ['common', 'white']
    }
  }

  const chipColor = chipColorReference[reference.category];

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={config.id}
    >
      <Card id={config.id} elevation={0} variant="outlined" className={[classes.appCard, isComingSoon ? classes.comingSoon : null].join(' ')} onClick={(event) => configSelectHandler(event, isComingSoon ? 'comingSoonModal' : 'appConfigurationModal')}>
        <div className={classes.appCardHeading}>
          <img className={classes.logo} src={require('../../../../assets/integrations/'+reference.image).default} alt={reference.product + " logo"}/>
          {status}
        </div>
        <div>
          <Typography variant='h5'>
            {reference.product}
          </Typography>
        </div>
        <div className={classes.cardText}>
          <Typography variant="body2">
            {helpers.limitStringLen(integrationLanguagePack[config.id].pageHelpText, 150)}
          </Typography>
        </div>
        <div>
          <Chip 
            size="small"
            label={<Typography variant="body2" bold>{languagePack.status[reference.category]}</Typography>} 
            bgColor={{color: chipColor.bg[0], colorVariant: chipColor.bg[1]}} 
            color={{color: chipColor.text[0], colorVariant: chipColor.text[1]}}
            />
        </div>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(AppstoreCard);