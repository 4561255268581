import { default as origAxios } from 'axios';
const axios = origAxios.create();

export const getSearches = async function (busObId) {
  const { data } = await axios({
    url: `/api/integration/cherwell/getsearchitems/association/${busObId}`,
  });

  const { root } = data;

  function getAllSearches(root) {
    let searches = root.childItems;
    for (const folder of root.childFolders)
      searches = searches.concat(getAllSearches(folder));
    return searches;
  }
  
  return getAllSearches(root);
}