import React, { Component } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/styles';
import { Typography } from '..';
import PropTypes from 'prop-types';

function buttonClass(theme, color, variant) {
  const isGrey = color === "grey";
  const palette = isGrey ? theme.palette[color]["500"] : theme.palette[color].main;
  const paletteLight = isGrey ? theme.palette[color]["300"] : theme.palette[color].light;
  const paletteDark = isGrey ? theme.palette[color]["700"] : theme.palette[color].dark;

  const style = {
    color: palette,
    // padding: "14px 18px",
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: paletteLight,
    }
  }

  if (variant === "outlined") {
    // style.padding = "12px 16px";
    style.border = `2px solid ${palette}`;
  }

  if (variant === "contained") {
    style.color = theme.palette.getContrastText(palette);
    style.backgroundColor = palette;
    style['&:hover'] =  {
      color: theme.palette.getContrastText(paletteDark),
      backgroundColor: paletteDark,
    }
  }

  return style;
}

const styles = (theme) => {
  const validColors = [
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "error",
    "grey",
  ]
  const validVariants = [
    "outlined",
    "text",
    "contained"
  ]
  const classes = {};
  for (const color of validColors)
    for (const variant of validVariants)
      classes[`${color}-${variant}`] = buttonClass(theme, color, variant);

  return classes;
};

class Button extends Component {
  render() {
    const { color = "grey", variant = "outlined", size = "medium", children, theme, classes, className, ...rest } = this.props;
    const cn = `${color}-${variant}`;
    let buttonFont = "button";
    if (size !== "medium")
      buttonFont = `button${size === "large" ? "Large" : "Small"}`;
    return (
      <MuiButton className={[classes[cn], className].join(' ')} variant={variant} size={size} {...rest}>
        <Typography variant={buttonFont}>
          {children}
        </Typography>
      </MuiButton>
    );
  }
}

Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ])
}

export default withTheme(withStyles(styles)(Button));