import React, { Component } from 'react';
import integrations from './integrations.json';
import {connect} from 'react-redux';
import axios from 'axios';
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Card, IconButton} from '@material-ui/core';
import {withStyles} from '@material-ui/core';
import {Search, Clear} from '@material-ui/icons';
import {Toolbar, TextInput, Alerts, Typography, Button, LoadingSpinner} from '../../components';
import defaults from './defaults.json';
import {AppstoreCard} from './components';
import { Autocomplete } from '../../components';

const styles = theme => ({
  logo: {
    width: '48px',
    height: '48px',
    marginRight: theme.spacing(1)
  },
  card: {
    padding: 0
  },
  appGridContainer: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  appCard: {
    display: "flex",
    flexDirection: 'column',
    cursor: 'pointer',
    '& div': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  },
  appCardHeading: {
    display: "flex",
    justifyContent: 'space-between'
  },
  checkIcon: {
    color: theme.palette.success.main
  },
  spacer: {
    marginTop: `${theme.spacing(2)}px !important`
  },
  cardText: {
    flexGrow: 1
  },
  comingSoon: {
    backgroundColor: "rgba(244,246,249,1)"
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '48px'
  },
  text: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageText: {
    lineHeight: '24px'
  },
  subText: {
    color: theme.palette.text.secondary
  },
  configDialog: {
    maxHeight: '450px'
  }
})

class AppStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configs: null,
      questions: null,
      filters: {
        search: "",
        fieldMapping: "",
        category: defaults.filters.category,
        status: defaults.filters.status
      },
      selectedConfig: null,
      comingSoonModal: defaults.comingSoonModal,
      installAppModal: defaults.installAppModal,
      appConfigurationModal: defaults.appConfigurationModal
    }
  }

  componentDidMount() {
    this.getConfigs()
    this.getQuestions()
  }
  
  getConfigs = () => {
    axios.get('api/configs').then(data => {
      this.setState(state =>({
        ...state,
        configs: data.data
      }))
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  getQuestions = () => {
    axios.get('api/questions').then(data => {
      this.setState(state =>({
        ...state,
        questions: data.data
      }))
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  modalStateHandler = (modal) => {
    if(this.state[modal].open) {
      this.setState(state =>({
        ...state,
        [modal]: defaults[modal]
      }))
    } else {
      this.setState(state => ({
        ...state,
        [modal]: {
          ...state[modal],
          open: !state[modal].open
        }
      }))
    }
  }
  
  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }))
  }

  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }))
  }

  modalFieldHander = (event) => {
    this.setState(state => ({
      ...state,
      selectedConfig: {
        ...state.selectedConfig,
        [event.target.name]: event.target.value
      }
    }))
  }

  fieldHandler = (name, value) => {
    this.setState(state => ({
      ...state,
      selectedConfig: {
        ...state.selectedConfig,
        [name]: value
      }
    }))
  }
  
  getFields = (event) => {
    const {id, commands, enabled, businessObjects, ...rest} = this.state.selectedConfig;
    let fields = rest;
    let step = this.state.appConfigurationModal.step;

    if(Object.keys(rest).length === 0) {
      step = 1
    }

    if(step === 0) {
      return Object.keys(fields).map(x => {
        let input = null;
  
        switch (x) {
          case 'defaultLanguage':
            const languages = Object.keys(this.props.languages).map(x => ({ code: x, language: this.props.languages[x] }));
            const defaultLanguage = languages?.find(l => l.code === rest[x]) || null;
            input = 
            <Autocomplete
              disableClearable
              key={`appstore-defaultLanguage`}
              label={this.props.integrationLanguagePack[id].fields[x].label}
              options={languages || []}
              getOptionLabel={(option) => option.language}
              onChange={(e, val) => this.fieldHandler(x, val.code)}
              value={defaultLanguage}
            />
            break;
          case 'defaultResponse':
            const { questions } = this.state;
            const defaultQuestion = questions?.find(q => q.id === rest[x]) || null;
            input = 
            <Autocomplete
              disableClearable
              key={`appstore-defaultResponse`}
              label={this.props.integrationLanguagePack[id].fields[x].label}
              loading={questions === null}
              options={questions || []}
              getOptionLabel={(option) => option.name}
              onChange={(e, val) => this.fieldHandler(x, val.id)}
              value={defaultQuestion}
            />
            break;
          case 'customerSearch':
          case 'helpCard':
          case 'welcomeCard':
            input = null;
            break;
          default:
            input = <TextInput label={this.props.integrationLanguagePack[id].fields[x].label} value={rest[x]} name={x} type={integrations[id].passwordFields?.includes(x) ? 'password' : 'text' } onChange={event => this.modalFieldHander(event)}  autocomplete="off"/>
            break;
        }
  
        return (
          <Grid
            key={`${id}-${x}`}
            item
            xs={12}
          >
            {input}
          </Grid>
        )
      })
    } else {
      if(businessObjects) {
        return <React.Fragment>
          {
            Object.keys(businessObjects).map(b => {
              return (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" bold>
                      {b}
                    </Typography>
                  </Grid>
                { 
                  businessObjects[b].fields ? Object.keys(businessObjects[b].fields).map(f => {
                    if(this.state.filters.fieldMapping) {
                      if(
                        !f.toLowerCase().match(this.state.filters.fieldMapping) &&
                        !businessObjects[b].fields[f].toLowerCase().match(this.state.filters.fieldMapping)
                      ) {
                        return false;
                      }
                    }
                    return <Grid item xs={12}> 
                      <TextInput name={`${b}-fields-${f}`} label={f} value={businessObjects[b].fields[f]} onChange={this.fieldMappingFieldHandler}  autocomplete="off"/>
                    </Grid>
                  }) : null
                }
                </React.Fragment>
              )
            })
          }
        </React.Fragment>
      }
    }
  }

  configSelectHandler = async (event, modal) => {
    const id = event.currentTarget.id;
    let config = await this.state.configs.find(x => {
      return x.id === id
    })

    if(!config) {
      config = {id: id}
    }

    this.setState(state => ({
      ...state,
      selectedConfig: config
    }))
    
    this.modalStateHandler(modal);
  }

  updateConfig = async () => {
    const selected = this.state.selectedConfig;
    axios.put(`/api/config/${selected.id}`, selected).then(async (data) => {
      const configs = [...this.state.configs];
      let index = await configs.findIndex(x => {
        return x.id === selected.id;
      })

      const { __v, __t, _id, ...rest } = data.data;
  
      if(index !== -1) {
        configs.splice(index, 1, rest);
      }

      this.setState(state => ({
        ...state,
        configs: configs
      }))

      this.modalStateHandler('appConfigurationModal');
      Alerts({type: 'success', text: 'Config Successfully Updated'})
    }).catch(error => {
      Alerts({type: 'error', text: error.response?.data || error.message});
    })
  }

  dialogStepHandler = (e) => {
    e.preventDefault();
    this.setState(state => ({
      ...state,
      appConfigurationModal: {
        ...state.appConfigurationModal,
        step: state.appConfigurationModal.step === 0 ? 1 : 0
      }
    }))
  }

  fieldMappingFieldHandler = (event) => {
    const identifier = event.target.name.split('-');
    this.setState(state => ({
      ...state,
      selectedConfig: {
        ...state.selectedConfig,
        businessObjects: {
          ...state.selectedConfig.businessObjects,
          [identifier[0]]: {
            ...state.selectedConfig.businessObjects[identifier[0]],
            [identifier[1]]: identifier[1] === 'busObId' ? event.target.value : {
              ...state.selectedConfig.businessObjects[identifier[0]][identifier[1]],
              [identifier[2]]: event.target.value
            }
          }
        }
      }
    }))
  }

  render() {
    const {classes, languagePack, integrationLanguagePack} = this.props;

    const comingSoonModal = ( 
      this.state.selectedConfig ? 
      <Dialog fullWidth maxWidth="xs" open={this.state.comingSoonModal.open} onClose={() => this.modalStateHandler('comingSoonModal')}>
        <DialogTitle disableTypography={true}> 
          <div className={classes.heading}>
            <div className="image">
              <img className={classes.logo} src={require('../../assets/integrations/'+integrations[this.state.selectedConfig.id].image).default}/>
            </div>
            <div className={classes.text}>
              <div>
                <Typography variant="h5" className={classes.imageText}>
                  {integrations[this.state.selectedConfig.id].product}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={[classes.imageText, classes.subText].join(" ")}>
                  {integrations[this.state.selectedConfig.id].by}
                </Typography>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant='body1'>
                {integrationLanguagePack[this.state.selectedConfig.id].comingSoonModal?.primaryText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {integrationLanguagePack[this.state.selectedConfig.id].comingSoonModal?.secondaryText}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => this.modalStateHandler('comingSoonModal')}>
            {languagePack.comingSoonModal.buttons.close}
          </Button>
        </DialogActions>
      </Dialog> : null
    )

    const appConfigurationModal = (
      this.state.selectedConfig ? 
      <Dialog fullWidth maxWidth="sm" open={this.state.appConfigurationModal.open} onClose={() => this.modalStateHandler('appConfigurationModal')}>
        <DialogTitle disableTypography={true}>
          <div className={classes.heading}>
            <div className="image">
              <img className={classes.logo} src={require('../../assets/integrations/'+integrations[this.state.selectedConfig.id].image).default}/>
            </div>
            <div className={classes.text}>
              <div>
                <Typography variant="h5" className={classes.imageText}>
                  {integrations[this.state.selectedConfig.id].product}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={[classes.imageText, classes.subText].join(" ")}>
                  {integrations[this.state.selectedConfig.id].by}
                </Typography>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.configDialog}>
          <Grid
            container
            spacing={2}
          > 
            <Grid item xs={12} style={{position: 'sticky', top: -8 , zIndex: 1000, backgroundColor: "#fff"}}>
              <React.Fragment>
                {this.state.appConfigurationModal.step === 0 ? <Typography variant="body1">
                  {integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[0]}
                  <a target="_blank" href={integrations[this.state.selectedConfig.id]?.supportUrl}>{integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[1]}</a>
                  {integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[2]}
                  {integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[3]}
                  <a href="#" onClick={this.dialogStepHandler}>{integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[4]}</a>
                  {integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.helpText[5]}
                  </Typography> :
                  <React.Fragment>
                    <Typography variant="body1">
                      {integrationLanguagePack[this.state.selectedConfig.id].configurationModal?.fieldMapping.helpText}
                    </Typography>
                    <TextInput 
                      className={classes.spacer}
                      name='fieldMapping'
                      value={this.state.filters.fieldMapping}
                      onChange={this.filterFieldHandler}
                      endAdornment={<Search/>}
                      label={languagePack.appConfigurationModal.search}
                    />
                  </React.Fragment>
                }
              </React.Fragment>
            </Grid>
            {
              this.getFields()
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            this.state.appConfigurationModal.step === 0 ? 
            <Button fullWidth onClick={() => this.modalStateHandler('appConfigurationModal')}>
              {languagePack.appConfigurationModal.buttons.cancel}
            </Button> : 
            <Button fullWidth onClick={this.dialogStepHandler}>
              {languagePack.appConfigurationModal.buttons.back}
            </Button>
          }
          <Button fullWidth variant="contained" color='success' onClick={() => this.updateConfig()}>
            {languagePack.appConfigurationModal.buttons.submit}
          </Button> 
        </DialogActions>
      </Dialog> : null
    )

    if(!this.state.configs || !this.state.questions) {
      return <LoadingSpinner/>;
    }

    const {search, fieldMapping, ...rest} = this.state.filters;

    const appStoreCards = Object.keys(integrations).map(x => {
      if(search) {
        if( 
          !integrations[x].product.toLowerCase().match(search.toLowerCase()) &&
          !integrations[x].by.toLowerCase().match(search.toLowerCase()) 
        ) {
          return null
        }
      }
      if(rest) {
        if(rest.category) {
          if(Object.values(rest.category).includes(true) && rest.category[integrations[x].category] === false) {
            return null;
          }
        }
        if(rest.status) {
          if(Object.values(rest.status).includes(true) && rest.status[integrations[x].status] === false) {
            return null;
          }
        }
      }

      const config = this.state.configs.find(k => {
        return x === k.id;
      })

      return (
        <AppstoreCard 
          key={x} 
          config={config ? config : {id: x}} 
          reference={integrations[x]} 
          languagePack={languagePack}
          integrationLanguagePack={integrationLanguagePack}
          configSelectHandler={this.configSelectHandler}
        />
      )
    }).filter(x => x !== null);

    return (
      <React.Fragment>
        {comingSoonModal}
        {appConfigurationModal}
        <Card elevation={0} className={"cardWrapper"}>
          <Grid container>
            <Grid item xs={12}>
              <Toolbar
                filterValue={this.state.filters.search}
                filterFieldHandler={this.filterFieldHandler}
                filterTranslation={languagePack.filters}
                additionalFilters={{...rest, search}}
                additionalFiltersHandler={this.additionalFiltersHandler}
                recordNumber={Object.keys(integrations)?.length || 0}
                showingNumber={appStoreCards?.length || 0}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid className={classes.appGridContainer} container spacing={2}>
                {appStoreCards}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({languagePack, integrationLanguagePacks, languages}) => {
  const page = window.location.pathname.split('/')[1]
  return {
    languagePack: languagePack[page],
    integrationLanguagePack: integrationLanguagePacks,
    languages: languages
  }
}

export default connect(mapStateToProps)(withStyles(styles)(AppStore));