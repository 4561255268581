export default {
  filters: {
    status: {
      new: false,
      reviewed: false,
      resolved: false
    }
  },
  dialogs: {
    expectedOutcomeDialog: {
      open: false
    }
  }
}