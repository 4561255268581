import React, { Component } from 'react';
import {Grid, Card} from '@material-ui/core';
import {withTheme, withStyles} from '@material-ui/styles';
import {MoreVert, HelpOutline, EmojiPeople} from '@material-ui/icons';
import {Toolbar, Table, LoadingSpinner, Alerts} from '../../components';
import {WelcomeCardDialog, HelpCardDialog, QuestionModal, NewQuestionModal, TranslateModal} from './components';
import {connect} from 'react-redux';
import axios from 'axios';
import defaults from './defaults';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import helper from '../../helpers/helper';
import { QuestionWarningDialog } from './components'

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

class BotIntelligence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: null,
      commands: null,
      tableData: null,
      selectedQuestions: [],
      selectedQuestion: null,
      userSuggestions: null,
      questionModal: defaults.questionModal,
      newQuestionModal: defaults.newQuestionModal,
      translateModal: defaults.translateModal,
      questionDeleteDialog: defaults.questionDeleteDialog,
      questionDisableDialog: defaults.questionDisableDialog,
      helpCardDialog: defaults.helpCardDialog,
      welcomeCardDialog: defaults.welcomeCardDialog,
      filters: {
        search: "",
        type: defaults.filters.type,
        status: defaults.filters.status
      }
    }
  }

  componentDidMount() {
    this.getQuestions();
  }

  componentDidUpdate(prevProps) {
    if (this.hasDependencies()) {
      if (
        (prevProps.uiLanguage !== this.props.uiLanguage) || 
        (prevProps.currentLanguage !== this.props.currentLanguage)
      )
        this.createTableData();

      if (prevProps.uiLanguage !== this.props.uiLanguage)
        this.getCommands();

      if (prevProps.enabledLanguages !== this.props.enabledLanguages)
        this.getQuestions();
    }
  }

  getQuestions = () => {
    axios.get('/api/questions').then(questions => {
      this.setState(state => ({
        ...state,
        questions: questions.data
      }), async () => {
        await this.getCommands(true);
        await this.getUserSuggestions();
      }) 
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  getUserSuggestions = () => {
    axios.get('/api/usersuggestions?status=submitted').then(data => {
      this.setState(state => ({
        ...state,
        userSuggestions: data.data
      }))
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    })
  }

  getCommands = async (force = false) => {
    try {
      let commands = this.state.commands;
      if (!commands || force === true)
        commands = (await axios.get('/api/commands')).data;

      const integrations = Object.keys(this.props.integrationLanguagePacks).map(i => ({...this.props.integrationLanguagePacks[i], id: i}))      

      commands = commands.map(c => {
        let label
        if(c.custom) {
          label = c.name;
        } else {
          label = integrations.find(i => i.id === c.integrationId).commands[c.id].name;
        }
        c.label = label;
        return c
      })

      commands.sort((a, b) => {
        if(a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        } else {
          return -1
        }
      })

      this.setState(state =>({
        ...state,
        commands: commands,
      }), () => {
        this.createTableData()
      })
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  fieldHandler = (event, type, val) => {
    const { currentLanguage } = this.props;
    switch (type) {
      case 'name':
        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            [type]: event.target.value
          }
        }))
        break;
      case 'variant':
        const questions = this.state.selectedQuestion?.questions ? [...this.state.selectedQuestion.questions]: [];
        const filteredQuestions = questions.filter(x => x.language !== currentLanguage);
        const questionsLang = questions.filter(x => x.language === currentLanguage);
        if(questionsLang.length < val.length) {
          const newVal = val.pop();
          const existing = questions.find(x => x.question.toLowerCase() === newVal.toLowerCase());
          if(!existing) {
            val.push({question: newVal, language: currentLanguage, userSuggestion: false})
          }
        } 

        const newArr = filteredQuestions.concat(val)
        
        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            questions: newArr
          }
        }))


        
        
        
        break;
      case 'answer':
        const answers = this.state.selectedQuestion?.answers ? [...this.state.selectedQuestion.answers] : [];
        const answer = answers.find(x => x.language === currentLanguage);

        if(answer) {
          answer.answer = event.target.value;
        } else {
          answers.push({language: currentLanguage, answer: event.target.value})
        }

        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            answers: answers
          }
        }))
        break;
      case 'action':
        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            [type]: val?.id || null
          }
        }))
        break;
      case 'publicSkill':
        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            [type]: event.target.checked
          }
        }))
        break;
      default:
        this.setState(state => ({
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            [type]: event.target.value
          }
        }))
        break;
    }
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  addQuestionDialog = () => {
    this.setState(state => ({
      ...state,
      selectedQuestion: null,
    }), () => {
      this.DialogStateHandler('newQuestionModal')();
    })
  }

  addQuestion = () => {
    const payload = this.state.selectedQuestion;

    if(!payload?.name) {
      Alerts({type: 'warning', text: 'Description field is required'})
      return;
    }

    if(!payload?.questions?.length) {
      Alerts({type: 'warning', text: 'Atlease one variant is required'})
      return;
    }

    if(payload?.publicSkill === true && !payload.skill) {
      return Alerts({type: 'warning', text: 'Skill is required if "Show in Public Skills Page" is selected'});
    }
  
    if(payload?.publicSkill === true && !payload.howToUse) {
      return Alerts({type: 'warning', text: 'How to Use is required if "Show in Public Skills Page" is selected'});
    }

    axios.post('/api/question', this.state.selectedQuestion).then( data => {

      const questions = [...this.state.questions];
      questions.push(data.data)
      Alerts({type: 'success', text: 'Intent successfully added'})
      this.setState(state =>({
        ...state,
        questions
      }), () => {
        this.addQuestionDialog('newQuestionModal');
        this.createTableData();
      })
    }).catch(err=> {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  saveQuestion = () => {
    const question = {...this.state.selectedQuestion};

    if(question?.publicSkill === true && !question.skill) {
      return Alerts({type: 'warning', text: 'Skill is required if "Show in Public Skills Page" is selected'});
    }
  
    if(question?.publicSkill === true && !question.howToUse) {
      return Alerts({type: 'warning', text: 'How to Use is required if "Show in Public Skills Page" is selected'});
    }
    
    axios.put(`/api/question/${question.id}`, question).then(data =>{
      const questions = [...this.state.questions];

      const index = questions.findIndex(x => x.id === data.data.id);

      if(index !== -1) {
        questions.splice(index, 1, data.data)
      }

      this.setState(state => ({
        ...state,
        questions: questions
      }), () => {
        Alerts({type: 'success', text: "Question successfully updated"})
        this.dialogHandler();
        this.createTableData();
      })

    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  dialogHandler = () => {
    if(this.state.questionModal.open) {
      this.setState(state =>({
        ...state,
        selectedQuestion: null,
        questionModal: defaults.questionModal
      }))
    } else {
      this.setState(state =>({
        ...state,
        questionModal: {
          ...state.questionModal,
          open: true
        }
      }))
    }
  }

  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }), () => {
      this.createTableData();
    })
  }

  editQuestion = (id) => (event) => {
    const selected = this.state.questions.find(x => x.id === id);
    this.setState(state => ({
      ...state,
      selectedQuestion: selected
    }))
    this.dialogHandler();
  }

  translationModalHandler = () => {
    if(this.state.translateModal.open) {
      this.setState(state => ({
        ...state,
        translateModal: defaults.translateModal
      }))
    } else {
      this.setState(state => ({
        ...state,
        translateModal: {
          ...state.translateModal,
          open: true
        }
      }))
    }
  }
  
  exportBI = () => {
    const link = document.createElement("a");
    const json =  JSON.stringify(this.state.questions);
    link.href = window.URL.createObjectURL(
      new Blob([json], { type: "application/json" })
    );
    link.download = "questions.json";

    document.body.appendChild(link);

    link.click();
  }

  createTableData = async () => {
    let filteredQuestions = this.state.questions;
    const {search, ...rest} = this.state.filters;

    if(search || Object.values(rest).some(x => Object.values(x).includes(true))) {
      filteredQuestions = filteredQuestions.filter(x => 
        (
          x.name?.toLowerCase().match(search.toLowerCase())
        ) && 
        (Object.values(rest.type).includes(true) ?
          (
            (rest.type.actionBased && x.action) || 
            (rest.type.chitChat && !x.action)
          ) : true
        ) && 
        (Object.values(rest.status).includes(true) ?
          (
            (rest.status.active && x.enabled) || 
            (rest.status.disabled && !x.enabled)
          ) : true
        )
      )
    }

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[2],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[3],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[4],
        sortable: true,
      },
      {
        label: "",
        sortable: false,
      }
    ]

    const tableRows = await Promise.all(filteredQuestions.map(async x => {
      let action = null;

      if(x.action) {
        action = this.state.commands.find(k => k.id === x.action);
      }
      
      const actions = [
        {"display": this.props.languagePack.tableActions.edit, action: this.editQuestion(x.id)},
        {"display": this.props.languagePack.tableActions[x.enabled ? "disable" : "enable"], action: this.toggleQuestion(!x.enabled)},
        {color: "error", "display": this.props.languagePack.tableActions.delete, action: this.deleteQuestion()},
      ];

      let status = x.enabled ? {text: this.props.languagePack.filters.status.active, color: "success"} : {text: this.props.languagePack.filters.status.disabled, color: "error"};

      const answer = x.answers.length ? x.answers.find(a => a.language === this.props.currentLanguage)?.answer || ""  : "";
      return {
        id: x.id,
        onClick: this.editQuestion(x.id),
        cells: [
          {
            sortBy: x.name.toLowerCase(),
            type: 'imagetext',
            options: {
              imageUrl: action ? await helper.integrationImage(action.integrationId) : null,
              alt: x.name,
              shape: 'square',
              altType: 'text',
              text: {
                primary: helper.limitStringLen(x.name, 50),
                secondary: action ? this.props.integrations[action.integrationId].product : null
              }
            }
          },
          {
            sortBy: action ? action.label : "",
            type: 'text',
            options: {
              text: {
                primary: action ? action.label : "",
              },
              disableWrap: true
            }
          },
          {
            sortBy: answer,
            type: 'text',
            options: {
              text: {
                primary: answer,
              },
              lineLimit: 2
            }
          },
          {
            sortBy: status.text,
            type: 'status',
            options: {
              text: status.text,
              color: status.color
            }
          },
          {
            sortBy: x.showOnHelp ? "1" : "0",
            type: 'checkbox',
            options: {
              onCheck: this.showOnHelp(x.id), 
              checked: x.showOnHelp || false
            }
          },
          {
            type: 'actions', 
            options: {
              heading: this.props.languagePack.tableActions.heading,
              button: <MoreVert/>, 
              click: () => this.setQuestion(x.id),
              actions: actions
            }
          }
        ]
      }
    }))

    this.setState(state => ({
      ...state,
      tableData: {
        labels: labels,
        rows: tableRows
      }
    }))
  }

  updateTranslatedQuestion = (answers, questions) => {
    const selected = {...this.state.selectedQuestion};

    selected.answers = [...selected.answers, ...answers];
    selected.questions = [...selected.questions, ...questions];

    this.setState(state => ({
      ...state,
      selectedQuestion: selected
    }))
  }

  deleteUserSuggestion = (id) => {
    const userSuggestions = [...this.state.userSuggestions]; 
    axios.delete('/api/usersuggestion/'+id).then(data => {
      const filtered = userSuggestions.filter(x => x.id !== id);
      
      this.setState(state => ({
        ...state,
        userSuggestions: filtered
      }))
      Alerts({type: 'success', text: "User suggestion successfully deleted"})
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    })
  }

  resolveUserSuggestion = (userSuggestion) => {
    const payload = [{
      question: userSuggestion.message,
      language: this.props.currentLanguage,
      userSuggestion: true,
      userSuggestionRef: userSuggestion.id
    }];
    axios({
      url: '/api/question/'+ userSuggestion.questionId + "/variants", 
      method: "PUT",
      headers: {
        "Content-Type": 'application/json'
      },
      data: JSON.stringify(payload)
    }).then(data => {
      const questions = [...this.state.questions];
      const userSuggestions = [...this.state.userSuggestions];

      const toUpdate = questions.find(x => x.id === userSuggestion.questionId);
      const filtered = userSuggestions.filter(x => userSuggestion.id !== x.id)

      if(toUpdate) {
        toUpdate.questions = data.data.questions 
      }

      this.setState(state => ({
        ...state,
        questions: questions,
        userSuggestions: filtered
      }))
      Alerts({type: 'success', text: "Variant successfully added"})
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    }) 
  }
  
  DialogStateHandler = (dialog) => () => {
    if (this.state[dialog].open) {
      this.setState(state =>({
        ...state,
        [dialog]: defaults[dialog]
      }))
    } else {
      this.setState(state => ({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: true
        }
      }))
    }
  }

  toggleQuestion = (enable, initDialog = true) => async () => {
    if (initDialog && !enable) {
      return this.setState(state => ({
        ...state,
        questionDisableDialog: {
          ...this.state.questionDisableDialog,
          numberOfRecords: 1,
          multiple: false,
          open: true
        }
      }))
    }

    const question = this.state.selectedQuestion;
    const questions = [...this.state.questions];
    const toggleQuestion = await questions.find(x => {
      return x.id === question.id;
    })
    
    try {
      const { data } = await axios.patch(`/api/question/${question.id}`,
       JSON.stringify([
        { "op": "replace", "path": "/enabled", "value": enable }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
      });

      toggleQuestion.enabled = data.enabled;

      this.setState(state => ({
        ...state,
        questions,
        questionDisableDialog: {
          ...this.state.questionDisableDialog,
          open: false
        }
      }), () => {
        this.createTableData();
      })
      Alerts({text: `Question '${question.name}' successfully ${enable ? "enabled" : "disabled"}`, type: 'success'});
    } catch (err) {
      Alerts({text: err?.response?.data || err.message, type: "error"});
    }
  }


  showOnHelp = (id) => async () => {
    const questions = [...this.state.questions];
    const question = await questions.find(x => {
      return x.id === id;
    })

    const max = 6;
    if (questions.filter(x => x.showOnHelp === true).length === max && !question.showOnHelp)
      return Alerts({text: `Only ${max} questions can be shown on help.`, type: 'error'});
    
    try {
      const { data } = await axios.patch(
        `/api/question/${id}`,
        JSON.stringify([
          { "op": "replace", "path": "/showOnHelp", "value": !question.showOnHelp }
        ]),
        { headers: { "Content-Type": "application/json" } }
      );

      question.showOnHelp = data.showOnHelp;

      this.setState(state => ({
        ...state,
        questions
      }), () => {
        this.createTableData();
      })
    } catch (err) {
      Alerts({text: err?.response?.data || err.message, type: "error"});
    }
  }

  deleteQuestion = (initDialog = true) => async () => {
    if (initDialog) {
      return this.setState(state => ({
        ...state,
        questionDeleteDialog: {
          ...this.state.questionDeleteDialog,
          numberOfRecords: 1,
          multiple: false,
          open: true
        }
      }))
    }
    const question = this.state.selectedQuestion;
    try {
      await axios.delete(`/api/question/${question.id}`)

      const questions = [...this.state.questions];
      const index = await questions.findIndex(x => {
        return x.id === question.id;
      })

      if(index !== -1) {
        questions.splice(index, 1);
      }

      this.setState(state => ({
        ...state,
        questions,
        questionDeleteDialog: {
          ...this.state.questionDeleteDialog,
          open: false
        }
      }), () => {
        this.createTableData();
      })
      
      Alerts({text: `Question '${question.name}' successfully deleted`, type: 'success'})
    } catch (err) {
      Alerts({text: err?.response?.data || err.message, type: "error"});
    }
  }

  questionSelectionToggle = (enableQuestion = true, initDialog = true) => async () => {
    const selectedQuestions = [...this.state.selectedQuestions];
    if (selectedQuestions.length === 0)
      return;

    if (!enableQuestion && initDialog) {
      return this.setState(state => ({
        ...state,
        questionDisableDialog: {
          ...this.state.questionDisableDialog,
          numberOfRecords: selectedQuestions.length,
          multiple: true,
          open: true
        }
      }))
    }
    
    const questions = [...this.state.questions];

    const results = await Promise.allSettled(selectedQuestions.map(async id => {
      const toggledQuestion = await questions.find(x => {
        return x.id === id;
      })

      const { data } = await axios.patch(`/api/question/${id}`,
        JSON.stringify([
        { "op": "replace", "path": "/enabled", "value": enableQuestion }
      ]), {
        headers: {
          "Content-Type": "application/json"
        }
      });
      toggledQuestion.enabled = data.enabled;
      selectedQuestions.splice(selectedQuestions.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully ${enableQuestion ? "enabled" : "disabled"} ${successfulResults.length} question${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to ${enableQuestion ? "enable" : "disable"} ${failedResults.length} question${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully ${enableQuestion ? "enabled" : "disabled"} ${successfulResults.length} question${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to ${enableQuestion ? "enable" : "disable"} ${failedResults.length} question${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

    this.setState(state => ({
      ...state,
      questions,
      questionDisableDialog: {
        ...this.state.questionDisableDialog,
        open: false
      },
      selectedQuestions
    }), () => {
      this.createTableData();
    })
  }

  questionSelectionDelete = (initDialog = true) => async () => {
    const selectedQuestions = [...this.state.selectedQuestions];
    if (selectedQuestions.length === 0)
      return;

    if (initDialog) {
      return this.setState(state => ({
        ...state,
        questionDeleteDialog: {
          ...this.state.questionDeleteDialog,
          numberOfRecords: selectedQuestions.length,
          multiple: true,
          open: true
        }
      }))
    }
    
    const questions = [...this.state.questions];

    const results = await Promise.allSettled(selectedQuestions.map(async id => {
      const toggledQuestion = await questions.find(x => {
        return x.id === id;
      })
      const { data } = await axios.delete(`/api/question/${id}`)
      questions.splice(questions.indexOf(toggledQuestion), 1);
      selectedQuestions.splice(selectedQuestions.indexOf(id), 1);
    }));

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully deleted ${successfulResults.length} question${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} question${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully deleted ${successfulResults.length} question${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to delete ${failedResults.length} question${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

    this.setState(state => ({
      ...state,
      questions,
      questionDeleteDialog: {
        ...this.state.questionDeleteDialog,
        open: false
      },
      selectedQuestions
    }), () => {
      this.createTableData();
    })
  }


  selectQuestionHandler = (question) => {
    let selectedQuestions = [...this.state.selectedQuestions];
    if (question) {
      const questionIndex = selectedQuestions.indexOf(question);
      questionIndex > -1 
      ? selectedQuestions.splice(questionIndex, 1)
      : selectedQuestions.push(question);
    } else {
        selectedQuestions = selectedQuestions.length !== this.state.tableData.rows.length 
        ? this.state.tableData.rows.map(x => x.id) 
        : [];
    }

    this.setState(state => ({
      selectedQuestions
    }), () => {
      this.createTableData();
    });
  }

  
  setQuestion = async (c) => {
    const question = await this.state.questions.find(x => {
      return x.id === c;
    })
    if(question) {
      this.setState(state => ({
        ...state,
        selectedQuestion: question
      }))
    }
  }

  hasDependencies() {
    return this.state.questions && this.state.commands && this.props.enabledLanguages
  }

  render() {
    const {classes, languagePack, enabledLanguages, allLanguages, theme} = this.props;

    if(!this.hasDependencies()) {
      return <LoadingSpinner/>;
    }
    
    const noneSelected = this.state.selectedQuestions.length === 0;

    return (
      <React.Fragment>
        <QuestionModal 
          key="edit-dialog"
          open={this.state.questionModal.open} 
          languagePack={languagePack.questionModal} 
          userSuggestionLanguage={languagePack.userSuggestion} 
          dialogHandler={this.dialogHandler}
          question={this.state.selectedQuestion}
          enabledLanguages={enabledLanguages}
          allLanguages={allLanguages}
          fieldHandler={this.fieldHandler}
          commands={this.state.commands}
          saveQuestion={this.saveQuestion}
          translateHandler={this.translationModalHandler}
          deleteUserSuggestion={this.deleteUserSuggestion}
          resolveUserSuggestion={this.resolveUserSuggestion}
          userSuggestions={this.state.selectedQuestion ? this.state.userSuggestions.filter(x => x.questionId === this.state.selectedQuestion.id) : []}
        />
        <QuestionModal
          key="add-dialog" 
          open={this.state.newQuestionModal.open} 
          languagePack={languagePack.newQuestionModal} 
          userSuggestionLanguage={languagePack.userSuggestion} 
          dialogHandler={this.DialogStateHandler('newQuestionModal')}
          question={this.state.selectedQuestion}
          enabledLanguages={enabledLanguages}
          allLanguages={allLanguages}
          fieldHandler={this.fieldHandler}
          commands={this.state.commands}
          saveQuestion={this.addQuestion}
          translateHandler={this.translationModalHandler}
          deleteUserSuggestion={this.deleteUserSuggestion}
          resolveUserSuggestion={this.resolveUserSuggestion}
          userSuggestions={this.state.selectedQuestion ? this.state.userSuggestions.filter(x => x.questionId === this.state.selectedQuestion.id) : []}
        />
        <TranslateModal
          open={this.state.translateModal.open}
          close={this.translationModalHandler}
          languagePack={languagePack.translationModal}
          enabledLanguages={enabledLanguages}
          question={this.state.selectedQuestion}
          updateTranslatedQuestion={this.updateTranslatedQuestion}
        />
        <QuestionWarningDialog
          languagePack={languagePack.questionDeleteDialog}
          open={this.state.questionDeleteDialog.open}
          numberOfRecords={this.state.questionDeleteDialog.numberOfRecords}
          onCancel={this.DialogStateHandler("questionDeleteDialog")}
          onClose={this.DialogStateHandler("questionDeleteDialog")}
          onConfirm={this.state.questionDeleteDialog.multiple ? this.questionSelectionDelete(false) : this.deleteQuestion(false)}
        />
        <QuestionWarningDialog
          languagePack={languagePack.questionDisableDialog}
          open={this.state.questionDisableDialog.open}
          numberOfRecords={this.state.questionDisableDialog.numberOfRecords}
          onCancel={this.DialogStateHandler("questionDisableDialog")}
          onClose={this.DialogStateHandler("questionDisableDialog")}
          onConfirm={this.state.questionDisableDialog.multiple ? this.questionSelectionToggle(false, false) : this.toggleQuestion(false, false)}
        />
        <HelpCardDialog
          languagePack={languagePack.helpCardDialog}
          open={this.state.helpCardDialog.open}
          onClose={this.DialogStateHandler("helpCardDialog")}
          questions={this.state.questions.filter(x => x.showOnHelp)}
        />
        <WelcomeCardDialog
          languagePack={languagePack.welcomeCardDialog}
          open={this.state.welcomeCardDialog.open}
          onClose={this.DialogStateHandler("welcomeCardDialog")}
        />
        <Card elevation={0} className={["cardWrapper", "stickyToolbar"].join(' ')}>
          <Grid container>
            <Grid 
              item 
              xs={12}
              className="toolbarWrapper"
            >
              <Toolbar
                filterValue={this.state.filters.search}
                filterTranslation={languagePack.filters}
                filterFieldHandler={this.filterFieldHandler}
                actions={[
                  {icon: <EmojiPeople fontSize="small"/>, color: theme.palette.warning.main, tooltip: languagePack.actions.welcomeCard, onClick: this.DialogStateHandler("welcomeCardDialog")},
                  {icon: <HelpOutline fontSize="small"/>, color: theme.palette.warning.main, tooltip: languagePack.actions.helpCard, onClick: this.DialogStateHandler("helpCardDialog")},
                  {icon: <FontAwesomeIcon icon={faFileExport}/>, color: theme.palette.warning.main, tooltip: languagePack.actions.export, onClick: () => this.exportBI()},
                  {type: "add", tooltip: languagePack.actions.add, onClick: this.DialogStateHandler("newQuestionModal")},
                  {type: "enable", disabled: noneSelected, tooltip: languagePack.actions.enable, onClick: this.questionSelectionToggle(true)},
                  {type: "disable", disabled: noneSelected, tooltip: languagePack.actions.disable, onClick: this.questionSelectionToggle(false)},
                  {type: "delete", disabled: noneSelected, tooltip: languagePack.actions.delete, onClick: this.questionSelectionDelete()}
                ]}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                recordNumber={this.state.questions?.length || 0}
                selectedNumber={this.state.selectedQuestions?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
              />
            </Grid>
            <Grid item xs={12} className="h100">
              <Table
                dense
                stickyHeader
                tableData={this.state.tableData}
                selectionHandler={this.selectQuestionHandler}
                selectedArr={this.state.selectedQuestions}
              />
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const page = window.location.pathname.split('/')[1]
  return {
    uiLanguage: state.uiLanguage,
    languagePack: state.languagePack[page],
    enabledLanguages: state.enabled,
    allLanguages: state.languages,
    integrationLanguagePacks: state.integrationLanguagePacks,
    integrations: state.integrations,
    currentLanguage: state.currentLanguage
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(BotIntelligence)));
