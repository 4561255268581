import React, { Component } from 'react';
import {Grid, Card, Button} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {Delete, Check, RemoveCircle} from '@material-ui/icons';
import {Toolbar, Table, TextInput, LoadingSpinner, Alerts} from '../../components';
import {ExpectedOutcomeDialog} from './components';
import {connect} from 'react-redux';
import axios from 'axios';
import defaults from './defaults';
import { Autocomplete } from '@material-ui/lab';

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  actionButton: {
    maxWidth: "36px",
    minWidth: "36px",
    backgroundColor: "rgba(244,246,249,1)", //TODO Update Greys in theme
    marginRight: theme.spacing(1),
    "&:last-of-type": {
      marginRight: 0
    }
  },
  save: {
    color: theme.palette.success.main
  },
  discard: {
    color: theme.palette.error.main
  },
  buttonWrapper: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1
  }
})

class ExpectedOutcomes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expectedOutcomes: null,
      tableData: null,
      questions: null,
      selected: null,
      selectedExpectedOutcomes: [],
      dialogs: {
        expectedOutcomeDialog: defaults.dialogs.expectedOutcomeDialog
      },
      filters: {
        search: "",
        status: defaults.filters.status
      }
    }
  }

  componentDidMount() {
    this.getExpectedOutcomes();  
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiLanguage !== this.props.uiLanguage)
      this.createTableData();
  }

  getExpectedOutcomes = () => {
    axios.get('/api/expectedoutcomes').then(ee => {
      axios.get('/api/questions').then(questions => {
        this.setState(state => ({
          ...state,
          expectedOutcomes: ee.data,
          questions: questions.data
        }))

        this.createTableData(ee.data);
      })
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  dialogStateHandler = (dialog) => {
    if (this.state.dialogs[dialog].open) {
      this.setState(state =>({
        ...state,
        dialogs: {
          ...state.dialogs,
          [dialog]: defaults.dialogs[dialog]
        }
      }))
    } else {
      this.setState(state => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [dialog]: {
            ...state[dialog],
            open: true
          }
        }
      }))
    }
  }

  setExpectedOutcome = (id) => {
    const expectedOutcomes = [...this.state.expectedOutcomes];

    const selected = expectedOutcomes.find(x => x.id === id);

    if(selected) {
      this.setState(state => ({
        ...state,
        selected
      }))
    }
  }

  deleteExpectedOutcome = (event, id, showAlert) => {
    if(event)
      event.stopPropagation();

    if(!id) 
      id = this.state.selected.id

    axios.delete('/api/expectedoutcome/'+id).then(data => {
      const expectedOutcomes = [...this.state.expectedOutcomes];
      const index = expectedOutcomes.findIndex(x => id === x.id);
      if(index !== -1) {
        expectedOutcomes.splice(index, 1);
        
      this.setState(state => ({
          ...state,
          expectedOutcomes: expectedOutcomes
        }));
      }

      if(showAlert)
        Alerts({type: 'success', text: "Expected outcome successfully deleted"});
      
      this.createTableData();
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  deleteManyExpectedOutcomes = async () => {
    const selected = [...this.state.selectedExpectedOutcomes];
    const expectedOutcomes = [...this.state.expectedOutcomes];
    const results = await Promise.allSettled(selected.map(async id => {
      const deletedEO = await expectedOutcomes.find(x => {
        return x.id === id;
      })

      await axios.delete('/api/expectedoutcome/'+id).then(data => {
        selected.splice(selected.indexOf(id), 1)
        expectedOutcomes.splice(expectedOutcomes.findIndex(x => x.id === id), 1)
      });
    }));

    this.setState(state => ({
      ...state,
      selectedExpectedOutcomes: selected,
      expectedOutcomes
    }), () => {
      this.createTableData();
    })

    const failedResults = results.filter(x => x.status === "rejected");
    const successfulResults = results.filter(x => x.status === "fulfilled");
    
    if (successfulResults.length > 0)
      if (failedResults.length > 0)
        Alerts({ text: `Successfully deleted ${successfulResults.length} expected outcome${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} expected outcome${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
      else
        Alerts({ text: `Successfully deleted ${successfulResults.length} expected outcome${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
    else
      Alerts({ text: `Failed to delete ${failedResults.length} expected outcome${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

  }

  setQuestionVariant = (id) => (event, val) => {
    const expectedOutcomes = [...this.state.expectedOutcomes]
    const selected = expectedOutcomes.find(x => x.id === id);
    if(!val) {
      delete selected.question;
    } else {
      selected.question = val.id
    }

    this.setState(state => ({
      ...state,
      expectedOutcomes
    }), () => {
      this.createTableData();
    })
  }

  resolveExpectedOutcome = async (event, id) => {
    if(id) {
      if(event)
        event.stopPropagation();
  
      const selected = id ? [...this.state.expectedOutcomes].find(x => x.id === id) : this.state.selected; 
  
      axios.put(`/api/question/${selected.question}/variants`, JSON.stringify([{question: selected.message, language: this.props.currentLanguage, userSuggestion: true}]), {headers: {"Content-Type": 'application/json'}}).then(data => {
        this.deleteExpectedOutcome(null, id);
        Alerts({type: 'success', text: "Variant Successfully Added"})
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message})
      })
    } else {
      const selected = [...this.state.selectedExpectedOutcomes];
      if(selected.length === 0)
        return

      const EO = [...this.state.expectedOutcomes];

      const results = await Promise.allSettled(selected.map(async k => {
        const resolvedEO = await EO.find(x => x.id === k)
        await axios.put(`/api/question/${resolvedEO.question}/variants`, JSON.stringify([{question: resolvedEO.message, language: this.props.currentLanguage, userSuggestion: true}]), {headers: {"Content-Type": 'application/json'}}).then(data => {
          this.deleteExpectedOutcome(null, resolvedEO.id);
          selected.splice(selected.indexOf(resolvedEO.id), 1)
        }) 
      }))

      this.setState(state => ({
        ...state,
        selectedExpectedOutcomes : selected
      }));

      const failedResults = results.filter(x => x.status === "rejected");
      const successfulResults = results.filter(x => x.status === "fulfilled");
      
      if (successfulResults.length > 0)
        if (failedResults.length > 0)
          Alerts({ text: `Successfully added ${successfulResults.length} variant${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to add ${failedResults.length} variant${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
        else
          Alerts({ text: `Successfully added ${successfulResults.length} variant${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
      else
        Alerts({ text: `Failed to add ${failedResults.length} variant${failedResults.length > 1 ? "s" : ""}.`, type: "error" });
  
    }
  }

  createTableData = async () => {
    let filteredEE = this.state.expectedOutcomes
    const {search, ...rest} = this.state.filters;

    if(search) {
      filteredEE = await filteredEE.filter(x => 
        x.message?.toLowerCase().match(search.toLowerCase())
      )
    }

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[2],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[3],
        sortable: false,
      }
    ]

    
    const tableRows = filteredEE.map(x => {
      const userSuggestion = this.state.questions.find(q => q.questions.some(v => v.question === x.message));
      const question = this.state.questions.find(q => q.id === x.question);

      return {
        id: x.id,
        onClick: (event) => {
          event.stopPropagation();
          this.setExpectedOutcome(x.id);
          this.dialogStateHandler('expectedOutcomeDialog');
        },
        cells: [
          x.message,
          x.outcomes.length < 1 ? x.outcomes[0] : x.outcomes[0],
          {
            sortBy: question ? question?.name.toLowerCase() : "",
            type: 'custom',
            options: {
              key: x.id,
              element: (
                <span
                  onClick={event => event.stopPropagation()}
                >
                  <Autocomplete
                    size='small'
                    key={x.id}
                    id={x.id}
                    value={question}
                    disabled={userSuggestion}
                    name={"autocomplete-"+x.id}
                    options={this.state.questions ? this.state.questions : []}
                    loading={!this.state.questions}
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextInput {...params} placeholder="Select a Question" />}
                    onChange={this.setQuestionVariant(x.id)}
                  />
                </span>
              )
            }
          }, 
          {
            sortBy: false,
            type: 'custom', 
            options: {
              key: `action-buttons-${x.id}`,
              element: (
                <React.Fragment>
                  <div className={this.props.classes.buttonWrapper}>
                    <div className={this.props.classes.spacer}></div>
                    <Button className={[this.props.classes.actionButton, this.props.classes.save].join(' ')} onClick={(event) => this.resolveExpectedOutcome(event, x.id)} disabled={this.state.expectedOutcomes.find(k => k.id === x.id).question ? false : true}>
                      <Check fontSize="small"/>
                    </Button>
                    <Button className={[this.props.classes.actionButton, this.props.classes.discard].join(' ')} onClick={(event) => this.deleteExpectedOutcome(event, x.id, true)}>
                      <RemoveCircle fontSize="small"/>
                    </Button>
                  </div>
                </React.Fragment>
              )
            }
          }
        ]
      }
    })

    const tableData = {
      rows: tableRows,
      labels: labels
    }

    this.setState(state => ({
      ...state,
      tableData: tableData
    }))
  }
  
  filterFieldHandler = async (event) => {
    await this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }))

    this.createTableData()
  }

  dialogAutocompleteHandler = (e, val) => {
    this.setState(state => ({
      ...state,
      selected: {
        ...state.selected,
        question: val?.id || null
      }
    }))
  }

  tableSelectionHandler = (question) => {
    let selectedExpectedOutcomes = [...this.state.selectedExpectedOutcomes];
    if (question) {
      const questionIndex = selectedExpectedOutcomes.indexOf(question);
      questionIndex > -1 
      ? selectedExpectedOutcomes.splice(questionIndex, 1)
      : selectedExpectedOutcomes.push(question);
    } else {
        selectedExpectedOutcomes = selectedExpectedOutcomes.length !== this.state.tableData.rows.length 
        ? this.state.tableData.rows.map(x => x.id) 
        : [];
    }

    this.setState(state => ({
      selectedExpectedOutcomes
    }), () => {
      this.createTableData();
    });
  }

  
  render() {
    if(!this.state.expectedOutcomes || !this.state.tableData || !this.state.questions) {
      return <LoadingSpinner/>;
    } 

    const {languagePack, theme} = this.props;

    return (
      <React.Fragment>
        <ExpectedOutcomeDialog
          open={this.state.dialogs.expectedOutcomeDialog.open}
          close={() => this.dialogStateHandler('expectedOutcomeDialog')}
          languagePack={languagePack.dialog}
          expectedOutcome={this.state.selected}
          questions={this.state.questions}
          autocompleteHandler={this.dialogAutocompleteHandler}
          submitHandler={this.resolveExpectedOutcome}
          deleteHandler={this.deleteExpectedOutcome}
        />
        <Card elevation={0} className={['cardWrapper', 'stickyToolbar']}>
          <Grid container>
            <Grid item xs={12} className="toolbarWrapper">
              <Toolbar
                filterValue={this.state.filters.search}
                filterFieldHandler={this.filterFieldHandler}
                recordNumber={this.state.expectedOutcomes?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
                actions={[
                  {icon: <Check fontSize="small"/>, color: theme.palette.success.main, tooltip: languagePack.actions.add, onClick: () => this.resolveExpectedOutcome(), disabled: !this.state.selectedExpectedOutcomes.length},
                  {icon: <RemoveCircle fontSize="small"/>, color: theme.palette.error.main, tooltip: languagePack.actions.delete, onClick: () => this.deleteManyExpectedOutcomes(), disabled: !this.state.selectedExpectedOutcomes.length},
                ]}
              />
            </Grid>
            <Grid item xs={12} className="h100">
              <Table
                dense
                stickyHeader
                sortable
                tableData={this.state.tableData}
                recordsPerPage={15}
                defaultSort={0}
                selectedArr={this.state.selectedExpectedOutcomes}
                selectionHandler={this.tableSelectionHandler}
              />
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const page = window.location.pathname.split('/')[1]
  return {
    uiLanguage: state.uiLanguage,
    languagePack: state.languagePack[page],
    currentLanguage: state.currentLanguage
  }
}

export default withTheme(connect(mapStateToProps)(withStyles(styles)(ExpectedOutcomes)));