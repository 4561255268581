import React, { Component } from 'react';
import {Grid, Card, InputAdornment} from '@material-ui/core';
import {withTheme, withStyles} from '@material-ui/styles';
import {MoreVert, Search} from '@material-ui/icons';
import {Toolbar, Table, LoadingSpinner, Alerts, TextInput, Typography} from '../../components';

import {connect} from 'react-redux';
import axios from 'axios';
import defaults from './defaults';
import helper from '../../helpers/helper';

import { HelpDialog } from "./components";

const styles = theme => ({
  card: {
    padding: 0
  },
  wrapper: {
    height: '100vh',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  heading: {
    color: theme.palette.text.primary,
    fontFamily: "Poppins !important",
    paddingBottom: theme.spacing(1.5)
  },
  helpText: {
    width: "50%",
    paddingBottom: theme.spacing(3.5)
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  search: {
  },
  textQuaternary: {
    color: theme.palette.text.quaternary,
  },
  textField: {
    marginBottom: theme.spacing(1),
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& fieldset": {
      border: `1px solid ${theme.palette.additionals.border}`
    }
  }
})

class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoad: true,
      questions: null,
      tableData: null,
      selectedQuestion: null,
      helpDialog: defaults.helpDialog,
      filters: {
        search: ""
      }
    }
  }

  componentDidMount() {
    this.getQuestions();
  }

  componentDidUpdate(prevProps) {
    if (this.hasDependencies()) {
      if (
        (prevProps.uiLanguage !== this.props.uiLanguage) || 
        (prevProps.currentLanguage !== this.props.currentLanguage)
      )
        this.createTableData();

      if (prevProps.enabledLanguages !== this.props.enabledLanguages)
        this.getQuestions();
    }
  }

  getQuestions = () => {
    axios.get('/api/help').then(questions => {
      this.setState(state => ({
        ...state,
        questions: questions.data
      }), () => {
        this.createTableData();
      })
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  dialogHandler = () => {
    if(this.state.helpDialog.open) {
      this.setState(state =>({
        ...state,
        helpDialog: defaults.helpDialog
      }), () => {
        window.history.replaceState(null, "", window.location.pathname + window.location.search);
      })
    } else {
      this.setState(state =>({
        ...state,
        helpDialog: {
          ...state.helpDialog,
          open: true
        }
      }), () => {
        window.history.replaceState(null, "", `#${this.state.selectedQuestion.id}`);
      })
    }
  }

  emptySelectedQuestion = () => {
    this.setState(state =>({
      ...state,
      selectedQuestion: null
    }));
  }

  showSkill = (id) => () => {
    const selected = this.state.questions.find(x => x.id === id);
    if (!selected)
      return;
    this.setState(state => ({
      ...state,
      selectedQuestion: selected
    }), () => this.dialogHandler());
  }

  createTableData = async () => {
    let filteredQuestions = this.state.questions;
    const {search, ...rest} = this.state.filters;

    if(search || Object.values(rest).some(x => Object.values(x).includes(true))) {
      filteredQuestions = filteredQuestions.filter(x => (
          x.skill?.toLowerCase().match(search.toLowerCase()) ||
          x.howToUse?.toLowerCase().match(search.toLowerCase())
        )
      )
    }

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true,
      }
    ]

    const tableRows = await Promise.all(filteredQuestions.map(async x => {
      return {
        id: x.id,
        onClick: this.showSkill(x.id),
        cells: [
          {
            sortBy: x?.skill?.toLowerCase(),
            type: 'text',
            options: {
              text: {
                primary: x?.skill
              },
              disableWrap: true,
              style: {
                color: this.props.theme.palette.text.tertiary
              }
            }
          },
          {
            sortBy: x?.howToUse?.toLowerCase(),
            type: 'text',
            options: {
              text: {
                primary: x?.howToUse,
              },
              lineLimit: 2
            }
          }
        ]
      }
    }))

    this.setState(state => ({
      ...state,
      tableData: {
        labels: labels,
        rows: tableRows
      }
    }), () => {
      if (this.state.initLoad)
        this.setState(state => ({
          initProps: false
        }), () => {
          const id = window.location.hash.substring(1);
          if (id)
            this.showSkill(id)()
        })
    })
  }

  hasDependencies() {
    return this.state.questions
  }

  render() {
    const {classes, languagePack} = this.props;
    if(!this.hasDependencies()) {
      return <LoadingSpinner/>;
    }

    return (
      <React.Fragment>
        <HelpDialog
          languagePack={languagePack.helpDialog}
          question={this.state.selectedQuestion}
          open={this.state.helpDialog.open}
          close={this.dialogHandler}
          onExited={this.emptySelectedQuestion}
        />
        <Grid container spacing={2} justifyContent="space-between" className={classes.wrapper}>
          <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
            <Typography variant="h6" className={classes.heading}>
              {languagePack.heading}
            </Typography>
            <Typography variant="body2" className={[classes.helpText, classes.textQuaternary].join(' ')}>
              {languagePack.helpText}
            </Typography>
            <TextInput
              startAdornment={
                <InputAdornment position="start">
                  <Search style={{fontSize: "1.463em"}} className={classes.textSecondary}/>
                </InputAdornment>
              }
              label={languagePack.fields.search}
              size="small"
              name="search"
              // className={classes.textField}
              inputPropsClass={classes.textField}
              onChange={this.filterFieldHandler}
            />
          </Grid>
          <Grid item xs={12} style={{height: "calc(100% - 152px)"}}>
            <Card elevation={0} className={classes.card}>
              <Table
                dense
                stickyHeader
                tableData={this.state.tableData}
              />
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const page = window.location.pathname.split('/')[1]
  return {
    uiLanguage: state.uiLanguage,
    languagePack: state.languagePack[page],
    enabledLanguages: state.enabled,
    allLanguages: state.languages,
    integrationLanguagePacks: state.integrationLanguagePacks,
    integrations: state.integrations,
    currentLanguage: state.currentLanguage
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(Skills)));
