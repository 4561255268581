import React, { Component } from 'react';
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"

class Tagify extends Component {
  render() {
    const {classes, ...rest} = this.props;
    return (
      <Tags
        {...rest}
      />
    );
  }
}

export default Tagify;