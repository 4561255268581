import React, {useState} from 'react';
import {Sidebar, Topbar, Dialog, LanguageDialog, LicenseDialog} from './components';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/styles';
import defaults from './defaults.json';
import axios from "axios";
import {Alerts} from '../../components';

const styles = theme => ({
  content: {
    height: '100%',
    padding: theme.spacing(1),
    height: "calc(100vh - 72px)"
  },
  wrapper: {
    padding: '55px 0 0 64px'
  }
});

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: defaults.dialog,
      languageDialog: defaults.languageDialog,
      licenseDialog: {
        open: false,
        anchor: null
      },
      page: null,
      availableLanguages: null,
      systemInfo: null
    }
  }
  
  componentDidMount() {
    this.getEnabledLanguages();
    this.getSystemInfo();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.page !== this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle) {
      this.setState(state => ({
        ...state,
        page: this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle
      }))
    }
    if (prevProps.uiLanguage !== this.props.uiLanguage && this.props.enabledLanguages)
      this.getEnabledLanguages();
  }

  getSystemInfo = () => {
    axios.get('/api/systeminfo').then(data => {
      this.setState(state =>({
        ...state,
        systemInfo: data.data
      }))
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  dialogHandler = event => {
    if(this.state.dialog.open) {
      this.setState(state => ({
        ...state,
        dialog: defaults.dialog
      }))
    } else {
      const id = event.currentTarget.id
      this.setState(state => ({
        ...state,
        dialog: {
          ...state.dialog,
          type: id.split('-')[0],
          open: true
        }
      }))
    }
  }

  getEnabledLanguages = () => {
    const languages = this.props.allLanguages;
    axios.get('/api/languages/enabled').then(async (data) => {
      let sorted = data.data.map(x => {
        return {...x, label: languages[x.language]}
      }).sort(function (a, b) { 
        if (languages[a.language] < languages[b.language]) {
          return -1;
        }
        if (languages[a.language] > languages[b.language]) {
          return 1;
        }
        return 0
      });

      const { uiLanguage, currentLanguage, setCurrentLanguage } = this.props;
      if(!currentLanguage || !sorted.some(x => x.language === currentLanguage)) {
        const cL = sorted.find(x => x.language === uiLanguage?.split("-")?.[0]) || sorted?.[0];
        setCurrentLanguage({ currentLanguage: cL?.language || null });
      }

      await this.props.setEnabledLanguages({enabledLanguages: sorted})
      await this.getAvailableLanguages(sorted)

    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }
  
  feedbackSubmitHandler = () => {
    this.setState(state => ({
      ...state,
      dialog: {
        ...state.dialog,
        submitting: true
      }
    }))

    const payload = {...this.state.dialog.fields, url: window.location.href}

    if(this.state.dialog.type === 'support') {
      payload.issue = true;
    }

    axios.post('/api/feedback', payload).then(data => {
      this.setState(state => ({
        ...state,
        dialog: {
          ...state.dialog,
          submitting: false
        }
      }))
      Alerts({type: 'success', text: this.state.dialog.type === 'feedback' ? "Thank you for your feedback." : "Submitting Ticket"});
      this.dialogHandler();
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  getAvailableLanguages = (languages) => {
    if(!this.props.enabledLanguages && !languages) {
      return;
    }

    const enabledLanguages = languages || this.props.enabledLanguages;

    const available = Object.keys(this.props.allLanguages).map(x => {      
      if(!enabledLanguages.find(k => k.language === x)){
        return {languageCode: x, language: this.props.allLanguages[x]}
      }
      return;
    }).filter(k => k !== undefined)

    this.setState(state => ({
      ...state,
      availableLanguages: available
    }))

    return available;
  }

  dialogFieldHandler = event => {
    const fieldIdentifiers = event.target.name.split('-');
    this.setState(state => ({
      ...state,
      dialog: {
        ...state.dialog,
        fields: {
          ...state.dialog.fields,
          [fieldIdentifiers[1]]: event.target.value
        }
      }
    }))
  }

  licenseDialogHandlerEnter = (event) => {
    this.setState(state => ({
      ...state,
      licenseDialog: {
        ...state.licenseDialog,
        anchor: state.licenseDialog.anchor ? state.licenseDialog.anchor : event.target,
        open: true
      }
    }))
  }

  licenseDialogHandlerLeave = (event) => {
    this.setState(state => ({
      ...state,
      licenseDialog: {
        ...state.licenseDialog,
        open: false
      }
    }))
  }

  languageDialogOpenHandler = (reset = false) => () => {
    if(reset) {
      return this.setState(state => ({
        ...state,
        languageDialog: defaults.languageDialog
      }))
    }

    if(this.state.languageDialog.open) {
      this.setState(state => ({
        ...state,
        languageDialog: {
          ...state.languageDialog,
          open: false
        }
      }))
    } else {
      this.setState(state => ({
        ...state,
        languageDialog: {
          ...state.languageDialog,
          open: true
        }
      }))
    }
  }

  languageDialogStepHandler = (action) => {
    const currentStep = {...this.state.languageDialog}.step;
    if(action) {
      this.setState(state => ({
        ...state,
        languageDialog: {
          ...state.languageDialog,
          step: action === 'next' ? currentStep + 1 : currentStep - 1
        }
      }))
    }
  }

  render() {
    const {classes, children, navigation, modals} = this.props;
    if(!navigation.navigation && !navigation.topNavigation) {
      return null
    }

    return(
      <React.Fragment>
        <LicenseDialog 
          open={this.state.licenseDialog.open}
          license={this.state.systemInfo}
          languagePack={modals.systemInfo}
          anchor={this.state.licenseDialog.anchor}
          close={this.licenseDialogHandler}
        />
        <Dialog 
          type={this.state.dialog.type} 
          open={this.state.dialog.open} 
          values={this.state.dialog.fields} 
          languagePack={modals} 
          submit={this.feedbackSubmitHandler} 
          fieldHandler={this.dialogFieldHandler} 
          dialogHandler={this.dialogHandler} 
          submitting={this.state.dialog.submitting}
        />
        {
          this.props.allLanguages ? <LanguageDialog 
            languagePack={modals.language} 
            step={this.state.languageDialog.step} 
            open={this.state.languageDialog.open} 
            close={this.languageDialogOpenHandler(false)} 
            exit={this.languageDialogOpenHandler(true)} 
            stepHandler={this.languageDialogStepHandler}
            allLanguages={this.props.allLanguages}
            enabledLanguages={this.props.enabledLanguages}
            availableLanguages={this.state.availableLanguages}
            updateEnabledLangauges={this.getEnabledLanguages}
          /> : null
        }
        <div className={classes.wrapper}>
          <Topbar language={navigation.topNavigation} title={this.state.page} dialogHandler={this.dialogHandler}/>
          <Sidebar language={navigation.navigation} languageDialogOpenHandler={this.languageDialogOpenHandler(false)} licenseDialogEnter={this.licenseDialogHandlerEnter} licenseDialogLeave={this.licenseDialogHandlerLeave}/>
          <main className={classes.content}>
            {children}
          </main>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({languagePack, languages, enabled, currentLanguage, uiLanguage}) => {
  const page = window.location.pathname.split('/')[1];
  return {
    languagePack: languagePack,
    navigation: {navigation: languagePack.navigation, topNavigation: languagePack.topNavigation, title: languagePack[page].pageTitle},
    modals: {support: languagePack.supportModal, feedback: languagePack.feedbackModal, language: languagePack.languageModal, systemInfo: languagePack.systemInfoModal},
    allLanguages: languages,
    enabledLanguages: enabled,
    currentLanguage,
    uiLanguage
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setEnabledLanguages: x => dispatch({type: 'SETENABLEDLANGUAGES', payload: x}),
    setCurrentLanguage: x => dispatch({type: 'SETCURRENTLANGUAGE', payload: x}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main));