import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import logo from '../../assets/branding/Onebot white.svg';
import {connect} from 'react-redux';
import {TextInput, LoginButton, Alerts} from '../../components';
import axios from 'axios';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    height: "100vh",
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: "center"
  },
  container: {
    maxWidth: '450px',
    width: '100%',
  },
  logo: {
    height: '100px',
    marginBottom: '2.5em'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    paddingLeft: "15px",
    paddingRight: "15px",
    justifyContent: 'center'
  },
  textField: {
    marginBottom: "1rem"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },
  pwLink: {
    color: theme.palette.common.white,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  spacer: {
    marginBottom: '1rem'
  },
  heading: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0em"
  }
})

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    }
  }
  
  resetSubmit = (event) => {
    event.preventDefault();

    if(!this.state.email) {
      Alerts({text: "Email is required", type: 'warning'});
      return;
    }
    
    axios.post('/auth/passwordreset', {username: this.state.email}).then(data => {
      this.props.history.push('/reset-requested')
    }).catch(err => {
      Alerts({text: "<strong>Login Error:</strong><br/>"+err.response?.data || err.message, type: 'error'});
    })
  }

  fieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      email: event.target.value
    }))
  }

  render() {
    const {classes, languagePack} = this.props;


    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid 
            container
            alignItems='center'
          >
            <Grid 
              item
              xs={12}
              className={classes.row}
              style={{
                marginBottom: "3.75rem"
              }}
            >
              <img className={classes.logo} src={logo} alt="logo"/>
              <Typography align="center" className={classes.heading}>
                {languagePack.pageTitle}
              </Typography>
              <Typography align="center" variant="body2">
                {languagePack.helpText}
              </Typography>
            </Grid>
            <Grid 
              item
              xs={12}
              className={classes.row}
            >
              <form action="" onSubmit={this.resetSubmit}>
                <TextInput variant="login" name="username" className={classes.spacer} type="text" placeholder={languagePack.username} autoComplete='username'onChange={this.fieldHandler}/>
                <div className={classes.buttonContainer}>
                  <LoginButton className={classes.spacer} text={languagePack.button}/>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languagePack}) => {
  return {
    languagePack: languagePack['reset-request']
  }
}

export default connect(mapStateToProps)(withStyles(styles)(PasswordReset));