import React, { Component } from 'react';
import { Grid, Card, CardHeader, CardContent} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {Table, Typography} from '../../../../components';
import {connect} from 'react-redux';
import integrations from '../../../appstore/integrations.json';

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
})

class TopCommands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commands: null,
      tableData: null
    }
  }

  componentDidMount() {
    this.createTableData();
  } 

  componentDidUpdate(prevProps) {
    if (prevProps.uiLanguage !== this.props.uiLanguage)
      this.createTableData();
  }
  
  createTableData = () => {
    const commands = {};
    const tableRows = [];
    Object.keys(this.props.integrationLanguagePacks).map(x => {
      Object.keys(this.props.integrationLanguagePacks[x].commands).map(k => {
        commands[k] = this.props.integrationLanguagePacks[x].commands[k]
      })
    })
    this.props.pastWeek.map(x => {
      if (commands[x.command]){
        const command = this.props.commands.find(c => c.id === x.command)
        if(command) {
          tableRows.push(
          {
            id: x.command, 
            cells: [
              commands[x.command].name, 
              integrations[command.integrationId].product,
              x.total, 
              x.pastWeek ? x.pastWeek.total : 0
            ]
          }
        )}
      }
    })

    const {languagePack} = this.props;
    const tableData = {
      rows: tableRows.splice(0, 5),
      labels: [
        {
          label: languagePack.table[0],
          sortable: true
        },
        {
          label: languagePack.table[1],
          sortable: true
        },
        {
          label: languagePack.table[2],
          sortable: true
        },
        {
          label: languagePack.table[3],
          sortable: true
        }
      ]
    }

    this.setState(state => ({
      ...state,
      commands: commands,
      tableData: tableData
    }))

  }

  render() {
    const {classes, languagePack, pastWeek} = this.props;

    if(!this.state.tableData) {
      return null;
    }

    return (
      <Grid 
        item
        xs={12}
        md={8}
        lg={9}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>  
          <CardHeader
            title={(<Typography variant="body1" bold>{languagePack.heading}</Typography>)}
            subheader={(<Typography variant="body2">{languagePack.helpText}</Typography>)}
            disableTypography
          />
          <CardContent>
            <Table 
              dense
              sortable
              tableData={this.state.tableData}
              defaultSort={2}
              defaultSortOrder="desc"
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uiLanguage: state.uiLanguage,
    integrationLanguagePacks: state.integrationLanguagePacks
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TopCommands));