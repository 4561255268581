import React, { Component } from 'react';
import { Card, CardHeader, CardContent , Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {Typography} from '../../../../components';
import Chart from 'react-apexcharts';

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  }
})

class RegisteredUsers extends Component {
  render() {
    const {classes, languagePack, users} = this.props;

    const registered = users.filter(x => {
      return x.teamsId !== undefined;
    }).length;

    const options = {
      chart: {
        type: 'radialBar',
        fontFamily: 'Poppins, sans-serif'
      },
      plotOptions: {
          radialBar: {
              hollow: {
                  margin: 0,
                  size: "65%"
              },
              dataLabels: {
                  showOn: "always",
                  name: {
                      show: false,
                      fontWeight: '700'
                  },
                  value: {
                      color: "#3F4254",
                      fontSize: "30px",
                      fontWeight: '700',
                      offsetY: 12,
                      show: true,
                      formatter: function (val) {
                          return val + '%';
                      }
                  }
              },
              track: {
                  background: "rgba(11, 183, 131, 0.26)",
                  strokeWidth: '100%'
              }
          }
      },
      colors: ["rgb(11, 183, 131)"],
      stroke: {
          lineCap: "round",
      },
      labels: ["Progress"]
    }

    return (
      <Grid 
        item
        xs={12}
        md={4}
        lg={3}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>
          <CardHeader
            title={(<Typography variant="body1" bold>{languagePack.heading}</Typography>)}
            subheader={(<Typography variant="body2">{languagePack.helpText}</Typography>)}
            disableTypography
          />
          <CardContent>
            <Chart
              options={options}
              series={[[(((registered / users.length) * 100) || 0).toFixed(0)]]}
              type="radialBar"
            />
            <Typography variant="body1">
              {languagePack.supportingText}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(RegisteredUsers);