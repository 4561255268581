import Noty from 'noty';
import '../../../node_modules/noty/lib/noty.css';
import '../../../node_modules/noty/lib/themes/sunset.css';
import PropTypes from 'prop-types';

const Alerts = ({type, show = true, ...rest}) => {
  const alert = new Noty({
    type,
    timeout: type === 'error' ?  false : 5000,
    theme: 'sunset',
    ...rest
  });

  if (show) alert.show();

  return alert;
}

Alerts.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Alerts;