import React, { Component, createRef } from 'react';
import { Grid, Link, Divider, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Alerts, AdaptiveCardPreview, Autocomplete, TextInput, LoadingSpinner, Typography, Button, Tooltip, Checkbox, SliderTabs } from '../../components';
import axios from 'axios';
import { ArrowBack, AddCircle, Cancel } from '@material-ui/icons';
import integrations from '../appstore/integrations.json';
import commandSetup from "../customcommands/components/commanddialog/commandsetup";
import { Template } from "adaptivecards-templating";
import {connect} from 'react-redux';
import { getBusinessObjects, getSearches, getBusinessObjectFields, getCustomCommandActions } from "../customcommands/components/commanddialog/commandsetup/7676f599-c6d4-490e-8d37-2292e81f6043/api";



const styles = theme => ({
  drawer: {
    overflowY: 'auto',
    height: 'calc(100% - 66px)',
    position: "relative",
    scrollBehavior: 'smooth',
    paddingTop: theme.spacing(2.5)
  },
  drawerWrapper: {
    backgroundColor: theme.palette.common.white,
    marginTop: "-8px",
    marginLeft: "-8px",
    marginBottom: "-8px",
    height: "calc(100vh - 55px)",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3.25)}px`,
    paddingTop: 0,
  },
  drawerStickyHeader: {
    position: "sticky",
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 1000
  },
  previewbutton: {
    display: "flex",
    justifyContent: "center"
  },
  additionalFieldsAdd: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: 4,
      color: theme.palette.info.main
    }
  },
  additionalFieldsCancel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      cursor: "pointer",
      color: theme.palette.error.main
    }
  },
  customerFieldMappingAdd: {
    "& > span": {
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: 4,
        color: theme.palette.info.main
      }
    }
  },
  customerFieldMappingCancel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      cursor: "pointer",
      color: theme.palette.error.main
    }
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  backText: {
    fontSize: "14px",
    fontFamily: 'Arial',
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#0053F0",
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'end'
  },
  acWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  saveButton: {
    marginLeft: theme.spacing(1)
  }
})

class CustomCommandEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      command: null,
      configs: null,
      searches: null,
      businessObjects: null,
      businessObjectFields: null,
      templates: null,
      dependencies: {
        "templatePreview": null
      }
    }
    this.scrollContainer = createRef();
  }

  componentDidMount() {
    Promise.all([
      this.getConfigs(),
      this.getCommands(),
      this.getCardTemplates(),
      this.getBusinessObjects(),
      this.getCustomCommandActions()
    ]);
  }

  componentDidUpdate(prevProps, prevState) {
    const {configs, command, templates} = this.state;
    if(command !== null && configs !== null && (prevState.command === null || prevState.configs === null)) {
      const config = configs.find(x => x.id === command?.integrationId);
      if (command.busObId !== undefined) {
        this.getSearches(command.busObId);
        this.getBusinessObjectFields(command.busObId);
      }

      if (command.customerFieldMapping && config) {
        this.getBusinessObjectFields(config.businessObjects.customer.busObId);
      }
    }

    if (command !== null && templates !== null && (prevState.command === null || prevState.templates === null)) {
      const template = templates.find(x => x.id === command.templateId) || templates[0];
      const values = this.addInitialAdditionalField(template,  command.values)
      this.setState(state => ({
        ...state,
        command: {
          ...command,
          values,
          templateId: template.id
        }
      }))
    }
  }

  getBusinessObjects = getBusinessObjects;

  getSearches = getSearches;

  getBusinessObjectFields = getBusinessObjectFields;

  getCustomCommandActions = getCustomCommandActions;

  filterCommandValues = (commandValues) => {
    const values = { ...commandValues };
  
    for (const valName in values) {
      const val = values[valName];
      if (Array.isArray(val))
        values[valName] = val.filter(x => x !== null);
    }
  
    return values;
  }

  addNullToValues = (commandValues) => {
    const values = {...commandValues};

    for(const val in values) {
      if (Array.isArray(values[val]) && values[val].length === 0)
        values[val].push(null)
    }

    return values;
  }

  templatePreviewHandler = async () => {
    try {
      const commandToPreview = { ...this.state.command };

      commandToPreview.actions = this.filterActionValues(commandToPreview.actions);

      commandToPreview.values = this.filterCommandValues(commandToPreview.values);

      const { data } = await axios.post(`/api/commands/custompreview`, commandToPreview);
      this.setDependencies("templatePreview", data);
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  setDependencies = (key, value)  => {
    this.setState(state =>({
      ...state,
      dependencies: {
        ...state.dependencies,
        [key]: value
      }
    }))
  }

  getCommands = async () => {
    try {
      const { data } = await axios.get('/api/commands?custom=true');
      const id = window.location.pathname.split('/')[2];
      let selected = data.find(x => x.id === id);
      if(selected) {
        selected.actions = this.addNullCustomActionHandler(selected.actions);
        this.setState(state => ({
          ...state,
          command: selected
        }))
      } else {
        this.setState(state => ({
          command: {
            integrationId: "7676f599-c6d4-490e-8d37-2292e81f6043",
            actions: [null],
            values: {}
          }
        })) 
      }
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }

  getConfigs = async () => {
    try {
      const { data } = await axios.get('/api/configs');
      this.setState(state => ({
        ...state,
        configs: data,
      }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }
  
  getCardTemplates = async () => {
    try {
      const { data } = await axios.get('/api/commands/templates');
      this.setState(state => ({
        ...state,
        templates: data
      }))
    } catch(err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
      throw err;
    }
  }

  commandUpdateHandler = (event) => {
    const selector = event.target.name.split('-');
    this.setState(state => ({
      ...state,
      [selector[0]]: {
        ...state.command,
        [selector[1]]: event.target.value
      }
    }))
  }

  filterCommandValues = (commandValues) => {
    const values = { ...commandValues };
  
    for (const valName in values) {
      const val = values[valName];
      if (Array.isArray(val))
        values[valName] = val.filter(x => x !== null);
    }
  
    return values;
  }

  filterActionValues = (actions) => {
    return actions.filter(x => x !== null);
  }

  saveCommand = (draft = false) => async () => {
    const commandToSave = { ...this.state.command, draft, ...(!draft && { enabled: true }) };
    commandToSave.values = this.filterCommandValues(commandToSave.values);
    commandToSave.actions = this.filterActionValues(commandToSave.actions);
    commandToSave.method = "GET";
    const id = commandToSave?.id || "";
    
    const { data } = await axios[id ? "put" : "post"](`/api/command/${id}`, commandToSave);

    data.actions = await this.addNullCustomActionHandler(data.actions);
    // if(data.values.field.length)
    data.values = this.addNullToValues(data.values)

    this.setState({
      ...this.state,
      command: data
    })

    Alerts({text: `Command '${data.name}' successfully ${id ? "updated" : "added"}`, type: 'success'});
  }

  defaultTemplateData = (template) => {
    if (!template)
      return;

    const defaultData = {};

    for (const { name, defaultValue, image } of template.fields)
      if (image)
        defaultData[name] = `${process.env.PUBLIC_URL}/default.png`;
      else
        defaultData[name] = defaultValue;

    for (const { name, defaultValue } of template.additionalFields)
      defaultData[name] = defaultValue;

    const t = new Template(template.adaptiveCard);
    return t.expand({ $root: defaultData });
  }

  customerFieldMappingCheckboxHandler = (event) => {
    const { command, configs } = this.state;
    const config = configs.find(x => x.id === command.integrationId);
    const updatedCommand = { ...command };

    if (updatedCommand.customerFieldMapping !== undefined)
      delete updatedCommand.customerFieldMapping;
    else {
      this.getBusinessObjectFields(config.businessObjects.customer.busObId);
      updatedCommand.customerFieldMapping = [{
        customerFieldId: "",
        businessObjectFieldId: "",
      }];
    }

    this.setState(state => ({
      ...state,
      command: updatedCommand
    }));
  }

  customerFieldMappingHandler = (index, name) => async (event, value) => {
    const { command } = this.state;
    const customerFieldMapping = [...command.customerFieldMapping];
    customerFieldMapping.splice(index, 1, {
      ...command.customerFieldMapping[index],
      [name]: value?.fieldId || ""
    })
    this.setState(state => ({ 
      ...state,
      command:{
        ...command, 
        customerFieldMapping 
      }
    }));
  }

  addCustomerFieldMapping = (index) => (event) => {
    const { command } = this.state;
    const customerFieldMapping = [ ...command.customerFieldMapping ];
    customerFieldMapping.splice(index + 1, 0, {
      customerFieldId: "",
      businessObjectFieldId: ""
    });
    this.setState(state => ({
      ...state,
      command: {
        ...state.command,
        customerFieldMapping
      }
    }))
  }

  removeCustomerFieldMapping = (index) => (event) => {
    const { command } = this.state;
    const customerFieldMapping = [ ...command.customerFieldMapping ];
    customerFieldMapping.splice(index, 1);
    this.setState(state => ({
      ...state,
      command: {
        ...state.command,
        customerFieldMapping
      }
    }))
  }

  addAdditionalFieldHandler = (name, index) => (event) => {
    const { command } = this.state;
    const values = { ...command.values };
    values[name].splice(index + 1, 0, null);
    this.setState(state =>({
      ...command,
      values
    }))
  }

  removeAdditionalFieldHandler = (name, index) => (event) => {
    const { command } = this.state;
    const values = { ...command.values };
    values[name].splice(index, 1);
    this.setState(state =>({
      ...command,
      values
    }))
  }

  customActionsCheckboxHandler = (event) => {
    const { command } = this.state;
    const updatedCommand = { ...command };
    if (command.actions) {
      delete updatedCommand.actions
    } else {
      updatedCommand.actions = [
        null
      ]
    }
    this.setState(state => ({
      command: updatedCommand
    }))
  }

  customActionsHandler = (index) => (event, value) => {
    const { command } = this.state;
    const actions = [...command.actions];
    actions[index] = value;
    this.setState(state => ({
      command: {
        ...command,
        actions
      }
    }))
  }

  addCustomActionHandler = (index) => (event) => {
    const { command } = this.state;
    const actions = [...command.actions];
    if (actions.length >= 6)
      return Alerts({ type: "error", text: "Only a maximum of 6 actions allowed"})
    actions.splice(index + 1, 0, null);
    this.setState(state => ({
      command: {
        ...command,
        actions
      }
    }))
  }

  removeCustomActionHandler = (index) => (event) => {
    const { command } = this.state;
    const actions = [...command.actions];
    actions.splice(index, 1);
    this.setState(state => ({
      command: {
        ...command,
        actions
      }
    }))
  }

  addNullCustomActionHandler = (actions) => {
    if(actions) {
      return [...actions, null];
    }

    return [null]
  }

  templateFieldHandler = (name, AFIndex = null) => async (event, value) => {
    const fieldId = value?.fieldId || null;
    const { command } = this.state;
    const values = { ...command.values };
    if (!fieldId && AFIndex === null)
      delete values[name];
    else if (AFIndex !== null)
      values[name][AFIndex] = fieldId;
    else
      values[name] = fieldId;
    
      this.setState(state => ({
      ...state,
      command: {
        ...state.command,
        values
      }
    }))
  }

  templateHandler = (event, value) => {
    const updatedCommand = {
      ...this.state.command,
      "templateId": value?.id,
      "values": {}
    };

    updatedCommand.values = this.addInitialAdditionalField(value, updatedCommand.values);
    this.setState(state => ({
      ...state,
      command: updatedCommand
    }))
  }

  addInitialAdditionalField = (template, commandValues) => {
    const values = { ...commandValues };
    for (const field of template?.additionalFields)
    if (!values[field.name] || values[field.name].length === 0){
      values[field.name] = [null];
    }
    return values;
  }

  businessObjectHandler = async  (event, value) => {
    const updatedCommand = { ...this.state.command };
    const busObId = value?.busObId || null;
    if (!busObId)
      delete updatedCommand.busObId;
    else
      updatedCommand.busObId = busObId;
    
    delete updatedCommand.search;
    // updatedCommand.values = {};
    const template = this.state.templates.find(x => x.id === updatedCommand.templateId);
    updatedCommand.values = this.addInitialAdditionalField(template, updatedCommand.values)

    this.setState(state =>({
      ...state,
      command: updatedCommand
    }))

    if (busObId) {
      this.getSearches(busObId);
      this.getBusinessObjectFields(busObId);
    }
  }

  searchHandler = async  (event, value) => {
    const updatedCommand = { ...this.state.command };
    if (value) {
      const { id, scope, scopeOwner } = value;
      updatedCommand.search = {
        searchId: id,
        scope,
        scopeOwner
      };
    } else {
      delete updatedCommand.search;
    }

    this.setState(state =>({
      ...state,
      command: updatedCommand
    }))
  }

  render() {
    if(!this.state.templates || !this.state.command || !this.state.configs) {
      return <LoadingSpinner/>
    }

    const {classes, languagePack} = this.props;
    const {command, configs, businessObjects, businessObjectFields, searches, customCommandActions} = this.state;
    
    // Setup Dependencies
    const config = configs.find(x => x.id === command.integrationId);

    const busObId = command.busObId || null;
    const search = command.search || null;
    
    const customerBusObId = config?.businessObjects?.customer?.busObId || null;
    const customerBusinessObjectFields = businessObjectFields?.[customerBusObId] || null;

    const selectedBusinessObjectFields = businessObjectFields?.[busObId] || null;
    const selectedBusinessObjectSearches = searches?.[busObId] || null;


    const busObIdVal = businessObjects?.find(x => x.busObId === busObId) || null;
    const searchVal = selectedBusinessObjectSearches?.find(x => x.id === search?.searchId) || null;

    const useCustomerFieldMapping = command.customerFieldMapping !== undefined;

    const integrationsWithId = Object.keys(integrations).map(x => ({ ...integrations[x], id: x }))
    const validIntegrations = integrationsWithId.filter(x => commandSetup[x.id] !== undefined);

    const selectedIntegration = validIntegrations.find(x => x.id === this.state.command?.integrationId)
    
    const selectedIntegrationConfig = this.state.configs?.find(x => x.id === selectedIntegration?.id) || null;

    let adaptiveCard;
    let selectedTemplate
  
    selectedTemplate = this.state.templates?.find(x => x.id === this.state.command.templateId) || this.state?.templates?.[0];
    const defaultTemplate = this.defaultTemplateData(selectedTemplate);
    adaptiveCard = this.state.dependencies?.templatePreview?.content || defaultTemplate || {};
  
    const IntegrationSetup =  commandSetup[this.state.command?.integrationId]?.setup;
    const IntegrationCardSetup =  commandSetup[this.state.command?.integrationId]?.card;
    
    const tabsArr = [
      {
        href: 'setup',
        text: 'Setup'
      },
      {
        href: 'search',
        text: 'Search'
      },
      {
        href: 'card',
        text: 'Card'
      },
      {
        href: 'actions',
        text: 'Actions'
      },
    ]

    const template = this.state.templates.find(x => x.id === this.state.command.templateId)

    return (
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}className={classes.drawerWrapper}>
          {/* Back + Tabs  */}
          <Grid container spacing={2} className={classes.drawerHeader}>
            <Grid item xs={12}>
              <Link href="/custom-commands">
                <Typography className={classes.backText} style={{marginTop: "12px", marginBottom: "12px"}}>
                  <ArrowBack style={{fontSize: '0.74rem', marginRight: "4px"}}/> 
                  Back to custom commands
                </Typography>
              </Link>
              <SliderTabs
                scrollRef={this.scrollContainer}
                tabs={tabsArr}
                // tolerance={50}
              />
            </Grid>
          </Grid>
          {/* Scollable Container */}
          <Grid container spacing={2} ref={this.scrollContainer} className={classes.drawer}>
            {/* Setup */}
            <Grid item xs={12} id="setup">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {languagePack.setup.heading}
                  </Typography>
                </Grid>
                <Grid item xs={12}  style={{paddingBottom: "8px"}}>
                  <Typography variant="body1">
                    {languagePack.setup.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    key="command-name"
                    name="command-name"
                    label={languagePack.setup.fields.name.label}
                    value={this.state.command?.name}
                    onChange={(event) => this.commandUpdateHandler(event)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    key="command-description"
                    name="command-description"
                    label={languagePack.setup.fields.description.label}
                    value={this.state.command?.description}
                    onChange={(event) => this.commandUpdateHandler(event)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={selectedIntegration}
                    disabled={this.command?.id !== undefined}
                    loading={validIntegrations === null}
                    key={`command-integrationId`}
                    options={validIntegrations}
                    getOptionLabel={(option) => option.product}
                    label={languagePack.setup.fields.app.label}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    label={languagePack["integrationFields"][selectedIntegration.id]["businessObject"].label}
                    // disabled={command?.id !== undefined}
                    onRefresh={() => this.getBusinessObjects(true)}
                    loading={businessObjects === null}
                    options={businessObjects || []}
                    groupBy={(option) => option.type}
                    getOptionLabel={(option) => option.displayName}
                    onChange={this.businessObjectHandler}
                    value={busObIdVal}
                  />
                  {/* <Autocomplete
                    // key={`command-${integration.id}-customerFieldMapping-${i}-businessObjectField`}
                    label={languagePack["integrationFields"][selectedIntegration.id]["customerFieldMapping"].businessObjectField}
                    onRefresh={() => this.getBusinessObjectFields(busObId || null, true)}
                    loading={busObId && selectedBusinessObjectFields === null}
                    options={selectedBusinessObjectFields || []}
                    getOptionLabel={(option) => option.displayName}
                    // onChange={this.customerFieldMappingHandler(i, "businessObjectFieldId")}
                    // disabled={!busObId}
                    // value={selectedBusinessObjectField}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider}/>
                </Grid>
              </Grid>
            </Grid>
            {/* Search */}
            <Grid item xs={12} id="search">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {languagePack.search.heading}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {languagePack.search.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    label={languagePack["integrationFields"][selectedIntegration.id]["search"].label}
                    onRefresh={() => this.getSearches(busObId, true)}
                    loading={busObId && selectedBusinessObjectSearches === null}
                    options={selectedBusinessObjectSearches || []}
                    getOptionLabel={(option) => option.displayName}
                    onChange={this.searchHandler}
                    disabled={!busObId}
                    value={searchVal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={`command-${selectedIntegration.id}-customerFieldMapping-checkbox`}
                        checked={useCustomerFieldMapping}
                        onChange={this.customerFieldMappingCheckboxHandler}
                      />
                    }
                    label={<Typography variant="body2" bold>{languagePack["integrationFields"][selectedIntegration.id]["customerFieldMappingCheckbox"].label}</Typography>}
                  />
                </Grid>
                {
                  command.customerFieldMapping?.map((x, i) => {
                    const selectedCustomerField = customerBusinessObjectFields?.find(f => f.fieldId === x.customerFieldId) || null;
                    const selectedBusinessObjectField = selectedBusinessObjectFields?.find(f => f.fieldId === x.businessObjectFieldId) || null;

                    const hasCancelButton = command.customerFieldMapping?.length > 1;
                    const hasAddButton = command.customerFieldMapping?.length === i + 1;
                    return <>
                    <Grid item xs={hasCancelButton ? 11 : 12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Autocomplete
                            key={`command-${selectedIntegration.id}-customerFieldMapping-${i}-customerField`}
                            label={languagePack["integrationFields"][selectedIntegration.id]["customerFieldMapping"].customerField}
                            onRefresh={() => this.getBusinessObjectFields(customerBusObId, true)}
                            loading={customerBusObId && customerBusinessObjectFields === null}
                            options={customerBusinessObjectFields || []}
                            getOptionLabel={(option) => option.displayName}
                            onChange={this.customerFieldMappingHandler(i, "customerFieldId")}
                            disabled={!customerBusObId}
                            value={selectedCustomerField}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            key={`command-${selectedIntegration.id}-customerFieldMapping-${i}-businessObjectField`}
                            label={languagePack["integrationFields"][selectedIntegration.id]["customerFieldMapping"].businessObjectField}
                            onRefresh={() => this.getBusinessObjectFields(busObId, true)}
                            loading={busObId && selectedBusinessObjectFields === null}
                            options={selectedBusinessObjectFields || []}
                            getOptionLabel={(option) => option.displayName}
                            onChange={this.customerFieldMappingHandler(i, "businessObjectFieldId")}
                            disabled={!busObId}
                            value={selectedBusinessObjectField}
                          />
                        </Grid>
                      </Grid>
                    </Grid> 
                    { 
                      hasCancelButton && 
                      <Grid className={classes.customerFieldMappingCancel} item xs={1}>
                        <Tooltip
                          text={languagePack["integrationFields"][selectedIntegration.id]["customerFieldMapping"].removeFieldMapping}
                          placement="bottom"
                        >
                          <Cancel fontSize="small" onClick={this.removeCustomerFieldMapping(i)}/>
                        </Tooltip>
                      </Grid>
                    }
                    {
                      hasAddButton &&
                      <Grid className={classes.customerFieldMappingAdd} item xs={12}>
                        <span onClick={this.addCustomerFieldMapping(i)}>
                          <AddCircle fontSize="inherit"/>
                          <Typography variant="body2" bold>{languagePack["integrationFields"][selectedIntegration.id]["customerFieldMapping"].newFieldMapping}</Typography>
                        </span>
                      </Grid>
                    }
                  </>
                  }) 
                }
                <Grid item xs={12}>
                  <Divider className={classes.divider}/>
                </Grid>
              </Grid>
            </Grid>
            {/* Config */}
            <Grid item xs={12} id="card">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {languagePack.config.heading}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {languagePack.config.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    label={languagePack["integrationFields"][selectedIntegration.id]["search"].label}
                    value={selectedTemplate}
                    key={`command-templateId`}
                    options={this.state.templates}
                    getOptionLabel={(option) =>  option.name}
                    onChange={this.templateHandler}
                  />
                </Grid>
                {
                  template?.fields.map(x => {
                    const fieldId = this.state.command.values[x.name] || null;
                    const fieldIdVal = selectedBusinessObjectFields?.find(x => x.fieldId === fieldId) || null;
                    return (
                      <Grid item xs={12} key={`command-${this.state.command.id || "new"}-template-${x.name}`}>
                        <Autocomplete
                          label={x.label}
                          loading={selectedBusinessObjectFields === null}
                          options={selectedBusinessObjectFields || []}
                          getOptionLabel={(option) => option.displayName}
                          onChange={this.templateFieldHandler(x.name)}
                          onRefresh={() => this.getBusinessObjectFields(this.state.command.busObId, true)}
                          value={fieldIdVal}
                        />
                      </Grid>
                    )
                  })
                }
                {
                  template?.additionalFields.map(x => {
                    return <>
                      <Grid item xs={12} key={`command-${this.state.command.id || "new"}-template-${x.name}-header`}>
                        <Typography variant="body2" bold>{x.headerText}</Typography>
                      </Grid>
                      {
                        Array.isArray(this.state.command.values[x.name])
                          ? this.state.command.values[x.name].map((fieldId, i) => {
                            const fieldIdVal = selectedBusinessObjectFields?.find(x => x.fieldId === fieldId) || null;
                            const hasCancelButton = this.state.command.values[x.name]?.length > 1;
                            return <Grid item xs={12} key={`command-${this.state.command.id || "new"}-template-${x.name}-${i}`}>
                              <Grid container spacing={2}>
                                <Grid item xs={hasCancelButton ? 10 : 11}>
                                  <Autocomplete
                                    label={`${x.headerText} - ${i + 1}`}
                                    loading={selectedBusinessObjectFields === null}
                                    options={selectedBusinessObjectFields || []}
                                    getOptionLabel={(option) => option.displayName}
                                    onChange={this.templateFieldHandler(x.name, i)}
                                    onRefresh={() => this.getBusinessObjectFields(this.state.command.busObId, true)}
                                    value={fieldIdVal}
                                    fullWidth
                                  />
                                </Grid>
                                  { 
                                    hasCancelButton && 
                                    <Grid className={classes.additionalFieldsCancel} item xs={1}>
                                      <Tooltip
                                        text={languagePack["integrationFields"][selectedIntegration.id]["additionalFields"].removeField}
                                        placement="bottom"
                                      >
                                        <Cancel fontSize="small" onClick={this.removeAdditionalFieldHandler(x.name, i)}/>
                                      </Tooltip>
                                    </Grid>
                                  }
                                  <Grid className={classes.additionalFieldsAdd} item xs={1}>
                                    <Tooltip
                                      text={languagePack["integrationFields"][selectedIntegration.id]["additionalFields"].addField}
                                      placement="bottom"
                                    >
                                      <AddCircle fontSize="small" onClick={this.addAdditionalFieldHandler(x.name, i)}/>
                                    </Tooltip>
                                  </Grid>
                              </Grid>
                            </Grid>
                          }) 
                          : null
                      }
                    </>
                  })
                }
                <Grid item xs={12}>
                  <Divider className={classes.divider}/>
                </Grid>
              </Grid>
            </Grid>
            {/* Actions */}
            <Grid item xs={12} id="actions" style={{height: "110%"}}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h5">
                    {languagePack.actions.heading}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {languagePack.actions.description}
                  </Typography>
                </Grid>
                {
                  command.actions.map((x, i) => {
                    const action = customCommandActions?.find(a => x === a) || null;
                    const hasCancelButton = command.actions.length > 1;
                    return <>
                      <Grid item xs={hasCancelButton ? 10 : 11} key={`command-${command.id || "new"}-template-actions-${i}`}>
                        <Autocomplete
                          label={languagePack["integrationFields"][selectedIntegration.id]["customCommandActions"].label}
                          loading={customCommandActions === null}
                          options={customCommandActions || []}
                          getOptionLabel={(option) => option}
                          onChange={this.customActionsHandler(i)}
                          onRefresh={() => this.getCustomCommandActions(true)}
                          value={action}
                        />
                      </Grid>
                        { 
                          hasCancelButton && 
                          <Grid className={classes.additionalFieldsCancel} item xs={1}>
                            <Tooltip
                              text={languagePack["integrationFields"][selectedIntegration.id]["customCommandActions"].removeField}
                              placement="bottom"
                            >
                              <Cancel fontSize="small" onClick={this.removeCustomActionHandler(i)}/>
                            </Tooltip>
                          </Grid>
                        }
                        <Grid className={classes.additionalFieldsAdd} item xs={1}>
                          <Tooltip
                            text={languagePack["integrationFields"][selectedIntegration.id]["customCommandActions"].addField}
                            placement="bottom"
                          >
                            <AddCircle fontSize="small" onClick={this.addCustomActionHandler(i)}/>
                          </Tooltip>
                        </Grid>
                    </>
                  })
                }
                <Grid item xs={12} style={{height: "inherit"}}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={6} lg={8} xl={8}>
          <Grid container style={{height: "100%"}}>
            <Grid item xs={12} style={{height: "calc(100% - 40px)", display: 'flex', justifyContent: "center", flexDirection: "column"}}>
              <div className={classes.acWrapper}>
                <AdaptiveCardPreview template={adaptiveCard} />
                <div className={classes.previewbutton} style={{padding: "10px"}}>
                  <Button 
                    size="small" 
                    onClick={this.templatePreviewHandler}
                  >
                    {languagePack.preview.buttons.preview}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{maxHeight: "40px"}}>
              <div className={classes.buttonWrapper}>
                <Button className={classes.saveButton} size="small" disableElevation variant="contained" color="info" onClick={this.saveCommand(true)} style={{marginLeft: 'auto'}}>
                  Save as Draft
                </Button>
                <Button className={classes.saveButton} size="small" disableElevation variant="contained" color="success" onClick={this.saveCommand()}>
                  Save and Enable
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ languagePack, integrations }) => {
  return {
    languagePack: languagePack['custom-command-editor'],
    integrations
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomCommandEditor));