import supported from '../translation/supported.json';
import integrations from '../views/appstore/integrations.json';
import {createStore} from 'redux';

const initState = {
  uiLanguage: null,
  languagePack: {},
  supported: supported,
  enabled: null,
  currentLanguage: localStorage.getItem("currentLanguage"),
  integrationLanguagePacks: {},
  integrations: integrations,
  interceptorId: null,
  errorCount: 0
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SETLANGUAGE':
      return {
        ...state,
        uiLanguage: action.payload.uiLanguage,
        languagePack: action.payload.languagePack,
        integrationLanguagePacks: action.payload.integrationLanguagePacks,
        languages: action.payload.languages
      };
    case 'SETENABLEDLANGUAGES': 
      return {
        ...state,
        enabled: action.payload.enabledLanguages
      }
    case 'SETCURRENTLANGUAGE': 
      localStorage.setItem("currentLanguage", action.payload.currentLanguage);
      return {
        ...state,
        currentLanguage: action.payload.currentLanguage
      }
    case 'SETINTERCEPTORID': 
      return {
        ...state,
        interceptorId: action.payload.interceptorId
      }
    case 'SETERRORCOUNT': 
      return {
        ...state,
        errorCount: action.payload
      }
    default:
      return state;
  }
}

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;