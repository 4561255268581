import React, { Component } from 'react';
import { Grid, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';
import {Alerts, Typography} from '../../../../components';
import {withStyles} from '@material-ui/styles';
import axios from 'axios';
import integrations from '../../../appstore/integrations.json';
import helper from '../../../../helpers/helper'

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
  cardContent: {
    paddingTop: "1.5rem"
  },
  item: {
    marginBottom: theme.spacing(2.25),
    alignItems: "center",
    display: "flex",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  icon: {
    color: theme.palette.common.white,
    width: "40px",
    height: "40px",
    borderRadius: ".25rem",
    fontWeight: 500,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    textAlign: "center!important",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  text: {
    display: "flex",
    flexDirection: "column",
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    height: '32px',
    width: '32px'
  }
})

class TopApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commandStats: null
    }
  }  

  componentDidMount() {
    this.renderList()
  }

  renderList = async () => {
    const commandStats = {};
    
    await Promise.all(this.props.stats.commands.map(async x => {
      const command = this.props.commands.find(c => c.id === x.command);
      if(command) {
        if(!commandStats[command.integrationId]) {
          commandStats[command.integrationId] = {
            total: x.total
          };
        } else {
          commandStats[command.integrationId].total += x.total;
        }
        commandStats[command.integrationId].image = await helper.integrationImage(command.integrationId);
      }
    })) 
   
    this.setState(state => ({
      ...state,
      commandStats
    }));
  }

  render() {
    if(!this.state.commandStats) {
      return null;
    }

    const {classes, languagePack, stats} = this.props;
    
    return (
      <Grid 
        item
        xs={12}
        md={4}
        lg={3}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>  
          <CardHeader
            title={(<Typography variant="body1" bold>{languagePack.heading}</Typography>)}
            subheader={(<Typography variant="body2">{languagePack.helpText}</Typography>)}
            disableTypography
          />
          <CardContent className={classes.cardContent}>
            {
              Object.keys(this.state.commandStats).length > 0 ? Object.keys({...this.state.commandStats}).sort((a, b) => {
                if(this.state.commandStats[a].total > this.state.commandStats[a].total) {
                  return -1;
                } else {
                  return 1;
                }
              }).splice(0, 5).map((x, i) => {
                return <div key={x} className={classes.item}>
                  <div className={[classes.icon].join(' ')}>
                    <Avatar className={classes.avatar} src={this.state.commandStats[x].image}/>
                  </div>
                  <div className={classes.text}>
                    <Typography variant="cell">
                      {integrations[x].product}
                    </Typography>
                    <Typography variant="cell" className={classes.secondaryText}>
                      {integrations[x].by}
                    </Typography>
                  </div>
                </div>
              }) : null
            }
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(TopApps);