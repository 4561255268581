import React, {useState} from 'react';
import propTypes from 'prop-types';
import {Avatar, TableCell} from '@material-ui/core';
import {Typography} from '../../..';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  imageCell: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    justifyContent: "center"
  },
  imageText: {
    lineHeight: '20px',
    overflowWrap: 'anywhere'
  },
  imageSubText: {
    color: theme.palette.text.secondary,
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  avatar: {
    width: "32px",
    maxHeight: "32px",
    minHeight: "32px"
  },
  lineLimit: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical"
  }
})

const ImageText = props => {
  const {classes, text, style, ...rest} = props;
  const cn = [classes.imageText];
  const styles = {};

  if(rest.lineLimit) {
    cn.push(classes.lineLimit)
    styles.WebkitLineClamp = rest.lineLimit
  }

  if(rest.disableWrap) {
    styles.whiteSpace = 'nowrap'
  }

  return (
    <TableCell align='right' style={style}>
      <div className={classes.imageCell}>
        <div className={classes.text}>
          <div className={classes.textWrapper}>
            <Typography variant="cell" className={cn.join(' ')} style={{...styles, ...style}}>
              {text.primary}
            </Typography>
          </div>
          {
            text.secondary ? <div>
              <Typography variant="cell" className={[...cn, classes.imageSubText].join(" ")} style={{...styles, ...style}}>
                {text.secondary}
              </Typography>
            </div> : null
          }
        </div>
      </div>
    </TableCell>
  )
}

ImageText.propTypes = {
  imageUrl: propTypes.string,
  text: propTypes.object.isRequired
} 

export default withStyles(styles)(ImageText);