import React, { Component } from 'react';
import {withTheme} from '@material-ui/styles';
import {ThemeProvider} from '@material-ui/core';
import lodash from 'lodash';

export default function(ViewComponent, customTheme) {
  class withCustomTheme extends Component {
    constructor(props) {
      super(props)
    }

    render() {
      if(!customTheme) {
        return <ViewComponent/>
      }
      
      return (
        <ThemeProvider theme={(oldTheme) => {
          return lodash.merge(oldTheme, customTheme);
        }}>
          <ViewComponent/>
        </ThemeProvider>
      );
    }
  }
  
  return withTheme(withCustomTheme);
}

