import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid, Card} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {Delete, AssignmentTurnedIn, MoreVert} from '@material-ui/icons';
import {Toolbar, Table, Alerts, LoadingSpinner} from '../../components';
import defaults from './defaults.json';
import axios from 'axios';
import helper from '../../helpers/helper';
import * as Moment from 'moment';
import { DeleteWarningDialog, ErrorDialog } from "./components";
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

export class ErrorLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      tableData: null,
      selectedErrors: [],
      selectedError: null,
      filters: {
        search: "",
        date: defaults.filters.date,
        status: defaults.filters.status
      },
      deleteWarningDialog: defaults.deleteWarningDialog,
      errorDialog: defaults.errorDialog
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiLanguage !== this.props.uiLanguage)
      this.createTableData();
  }

  componentDidMount() {
    this.getErrors()
  }

  dialogHandler = (dialog) => {
    if(this.state[dialog].open) {
      this.setState(state => ({
        ...state,
        selectedError: null,
        [dialog]: {
          ...state[dialog],
          open: false
        }
      }))  
    } else {
      this.setState(state => ({
        ...state,
        [dialog]: {
          ...state[dialog],
          open: true
        }
      }))  
    }
  }

  getErrors = () => {
    axios.get('/api/errorlogs').then(data => {
      this.setState(state => ({
        ...state,
        errors: data.data
      }), () => {
        this.createTableData();
      })
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message});
    })
  }

  deleteError = (event, id, dialog) => {
    const errors = [...this.state.errors];
    if(id) {
      // Single
      axios.delete('/api/errorlog/'+id).then(data => {
        const index = errors.findIndex(x => x.id === id);
        if(index !== -1) {
          errors.splice(index, 1);
          this.setState(state => ({
            ...state,
            errors
          }), () => {
            this.createTableData();
            Alerts({type: 'success', text: "Error Successfully Deleted"});
            this.dialogHandler(dialog);
          })
        }
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message});
      })
    } else {
      // Multiple
      axios({
        url: '/api/errorlogs/', 
        method: "DELETE",
        headers: {
          "Content-Type": 'application/json'
        },
        data: JSON.stringify(this.state.selectedErrors)
      }).then(data => {
        this.setState(state => ({
          ...state,
          selectedErrors: []
        }))
        this.getErrors();
        Alerts({type: 'success', text: data.data.deleted + " Errors Deleted"});
        this.dialogHandler(dialog);
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message});
      })
    }
  }

  reviewError = async (event, id, dialog) => {
    const errors = [...this.state.errors];
    if(id) {
      // Single
      await axios.put(`/api/errorlog/${id}/markasread`).then(data => {
        const error = errors.find(x => x.id === id);
        
        if(error) {
          error.read = true;
        }
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message});
      })
    } else {
      // Multiple
      const selected = [...this.state.selectedErrors];
      await axios.put('/api/errorlogs/markasread', selected).then(async data => {
        data.data.map(x => {
          const error = errors.find(e => x.id === e.id);
          error.read = true;
        })
      }).catch(err => {
        Alerts({type: 'error', text: err.response?.data || err.message});
      })
    }
    this.setState(state => ({
      ...state,
      errors
    }), () => {
      helper.setErrorCount();
      this.createTableData();
      if(dialog) {
        this.dialogHandler(dialog)
      }
    })
  }

  tableSelectionHandler = (error) => {
    let selectedErrors = [...this.state.selectedErrors];
    if (error) {
      const errorIndex = selectedErrors.indexOf(error);
      errorIndex > -1 
      ? selectedErrors.splice(errorIndex, 1)
      : selectedErrors.push(error);
    } else {
      selectedErrors = selectedErrors.length !== this.state.tableData.rows.length 
      ? this.state.tableData.rows.map(x => x.id) 
      : [];
    }

    this.setState(state => ({
      ...state,
      selectedErrors
    }), () => {
      this.createTableData();
    });
  }

  setSelectedError = (id) => {
    const error = [...this.state.errors].find(x => x.id === id);

    if(error) {
      this.setState(state => ({
        ...state,
        selectedError: error
      }))
    }
  }

  additionalFiltersHandler = (event, type) => {
    const selectors = event.target.name.split('-');
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [type === 'radio' ? 'selected' : selectors[1]]: type === 'radio' ? event.target.value : event.target.checked
        }
      }
    }), () => {
      this.createTableData();
    })
  }

  viewError = (id) => {
    const error = [...this.state.errors].find(x => x.id === id);

    if(error) {
      this.setState(state => ({
        ...state,
        selectedError: error
      }), () => {
        this.dialogHandler('errorDialog')
      })
    }
  }

  createTableData = () => {
    let errors = [...this.state.errors];
    const {search, ...rest} = this.state.filters;

    const dateRef = {
      today: [moment().startOf('day'), moment()],
      yesterday:[moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      thisWeek: [moment().startOf('week').add(1, 'd'), moment().endOf('week').add(1, 'd')],
      lastWeek: [moment().subtract(1, 'w').startOf('week').add(1, 'd'), moment().subtract(1, 'w').endOf('week').add(1, 'd')]
    }


    
    if(rest.date.selected !== 'any') {
      errors = errors.filter(x => moment(x.dateLogged).within(moment.range(dateRef[rest.date.selected][0], dateRef[rest.date.selected][1])));
    }
 
    if(search || Object.values(rest.status).includes(true)) {
      errors = errors.filter(x => 
        (
          x.error.name?.toLowerCase().match(search.toLowerCase()) ||
          x.error.message?.toLowerCase().match(search.toLowerCase()) ||
          x.error.stack?.toLowerCase().match(search.toLowerCase())
        ) && 
        (Object.values(rest.status).includes(true) ?
          (
            (rest.status.reviewed && x.read) || 
            (rest.status.new && !x.read)
          ) : true
        )
      )
    }

    const labels = [
      {
        label: this.props.languagePack.table[0],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[1],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[2],
        sortable: true,
      },
      {
        label: this.props.languagePack.table[3],
        sortable: true,
      }
    ]

    
    const rows = errors.map(x => {
      const actions = [
        {"display": this.props.languagePack.tableActions.delete, action: () => this.dialogHandler('deleteWarningDialog'), color: 'error'},
      ]
      
      if(x.read === false) {
        actions.unshift({"display": this.props.languagePack.tableActions.review, action: (event) => this.reviewError(event, x.id)})
      }

      const status = x.read === true ? this.props.languagePack.filters.status.reviewed : this.props.languagePack.filters.status.new;
      return {
        id: x.id,
        onClick: () => this.viewError(x.id),
        cells: [
          {
            sortBy: `${x.error.name}: ${x.error.message}`,
            type: "text",
            options: {
              text: {
                primary: helper.limitStringLen(`${x.error.name}: ${x.error.message}`, 75),
                secondary: helper.limitStringLen(x.error.stack, 75),
              }
            }
          },
          {
            sortBy: new Date(x.dateLogged),
            type: "text",
            options: {
              text: {
                primary: moment(x.dateLogged).format("DD/MM/YYYY [at] HH:mm"),
              }
            }
          },
          {
            sortBy: status,
            type: 'status',
            options: {
              text: status,
              color: x.read === true ? "info" : "error"
            }
          },
          {
            type: 'actions', 
            options: {
              heading: this.props.languagePack.tableActions.heading,
              button: <MoreVert/>, 
              click: () => this.setSelectedError(x.id),
              actions: actions
            }
          }
        ]
      }
    })


    this.setState(state => ({
      ...state,
      tableData: {
        labels,
        rows
      }
    }))
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }
  
  render() {
    const {classes, theme, languagePack} = this.props;

    if(!this.state.errors || !this.state.tableData) {
      return <LoadingSpinner/>
    }

    return (
      <>
        <DeleteWarningDialog
          open={this.state.deleteWarningDialog.open}
          close={() => this.dialogHandler('deleteWarningDialog')}
          languagePack={languagePack.deleteWarningDialog}
          selectedNo={this.state.selectedErrors.length || 0}
          deleteHandler={this.deleteError}
          selected={this.state.selectedError}
        />
        <ErrorDialog
          open={this.state.errorDialog.open}
          close={() => this.dialogHandler('errorDialog')}
          languagePack={languagePack.errorDialog}
          error={this.state.selectedError}
          deleteHandler={this.deleteError}
          reviewHandler={this.reviewError}
        />
        <Card elevation={0} className={['cardWrapper', 'stickyToolbar']}>
          <Grid container>
            <Grid item xs={12} className="toolbarWrapper">
              <Toolbar
                filterValue={this.state.filters.search}
                filterTranslation={languagePack.filters}
                filterFieldHandler={this.filterFieldHandler}
                actions={[
                  {icon: <AssignmentTurnedIn fontSize="small"/> , color: theme.palette.info.main, tooltip: languagePack.actions.review, disabled: !this.state.selectedErrors.length, onClick: this.reviewError},
                  {icon: <Delete fontSize="small"/> , color: theme.palette.error.main, tooltip: languagePack.actions.delete, disabled: !this.state.selectedErrors.length, onClick: () => this.dialogHandler('deleteWarningDialog')},
                ]}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                recordNumber={this.state.errors?.length || 0}
                selectedNumber={this.state.selectedErrors?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
              />
            </Grid>
            <Grid item xs={12} className="h100">
              <Table
                dense
                stickyHeader
                tableData={this.state.tableData}
                selectionHandler={this.tableSelectionHandler}
                selectedArr={this.state.selectedErrors}
                recordsPerPage={100} 
                defaultSort={1}
                defaultSortOrder="desc"
              />
            </Grid>
          </Grid>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const page = window.location.pathname.split('/')[1]
  return  {
    uiLanguage: state.uiLanguage,
    languagePack: state.languagePack[page]
  }
}

export default connect(mapStateToProps)(withStyles(styles)(withTheme(ErrorLog)))
