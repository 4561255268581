import React, { Component } from 'react';
import {Card, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import { Button, Typography } from '../../../../components';
import moment from 'moment';

const styles = theme => ({
  errorWrapper: {
    padding: theme.spacing(2),
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  splitTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textarea: {
    borderColor: '#DADEE3',
    height: "100%",
    boxSizing: 'border-box',
    borderRadius: "8px",
    padding: theme.spacing(2),
    ...theme.typography.body1
  }
})

export class ErrorDialog extends Component {
  parseLines = (value) => value.replace(/(\\n)/g, "\n");
  
  render() {
    const {classes, languagePack, error, open, close, deleteHandler, reviewHandler} = this.props;
    return (
      <Dialog open={open} onClose={close} fullWidth maxWidth='md'>
        <DialogTitle className={classes.splitTitle} disableTypography>
          <Typography display="inline" variant='h5'>
            {error?.error.name}
          </Typography>
          <Typography display="inline" variant='body1' className={classes.textSecondary}>
            {'Created: ' + moment(error?.dateLogged).format("DD/MM/YYYY [at] HH:mm")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <TextareaAutosize
              className={[classes.textarea, classes.textSecondary].join(' ')}
              readOnly
              // value={JSON.stringify(error)}
              value={this.parseLines(JSON.stringify(error))}
              style={{width: "100%"}}
              fullWidth
            />
          </div>
          {/* <Card elevation={0} className={[classes.errorWrapper, classes.textSecondary].join(' ')}>
            <Typography variant="body1">
              {error?.error.stack}
            </Typography>
          </Card> */}
        </DialogContent>
        <DialogActions>
          <Button fullWidth disableElevation onClick={close}>
            {languagePack.buttons.cancel}
          </Button>
          <Button fullWidth disableElevation color="info" variant="contained" onClick={(event) => reviewHandler(event, error?.id, 'errorDialog')} disabled={error?.read}>
            {languagePack.buttons.reviewed}
          </Button>
          <Button fullWidth disableElevation color="error" variant="contained" onClick={(event) => deleteHandler(event, error?.id, 'errorDialog')}>
            {languagePack.buttons.delete}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ErrorDialog);
