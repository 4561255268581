import React, { Component } from 'react';
import {Popover, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import Icon from '../../../../assets/branding/OneBot full.png';
import {Typography} from '../../../../components';
import moment from 'moment';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    maxWidth: '197px',
    width: '100%'
  },
  grid: {
    padding: theme.spacing(2)
  },
  gridItem: {
    height: "47px",
    display: "flex",
    alignItems: 'center'
  },
  logo: {
    width: '100%',
    height: 'auto',
    marginRight: theme.spacing(1)
  },
  versionNumber: {
    marginLeft: '8px',
    color: theme.palette.text.secondary
  },
  value: {
    color: theme.palette.text.secondary
  }
})

class LicenseDialog extends Component {
  render() {
    const {classes, languagePack, license, open, anchor, close} = this.props;

    if(!license) {
      return null;
    }

    return (
      <Popover 
        id="license-popover"
        className={classes.popover}
        PaperProps={{
          className: classes.popoverPaper
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={close}
      >
        <Grid className={classes.grid} container>
          <Grid className={classes.gridItem} item xs={9}>
            <img className={classes.logo} src={Icon} alt="logo"/>
          </Grid>
          <Grid className={classes.gridItem} item xs={3}>
            <Typography className={classes.versionNumber} variant='body2'>
              {"v" + license?.version}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" bold>
              {languagePack.labels.licenseTo}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.value} variant='caption'>
              {license?.licensedTo}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" bold>
              {languagePack.labels.licenses}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.value} variant='caption'>
              {license?.licenses}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" bold>
              {languagePack.labels.expire}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.value} variant='caption'>
              {moment(license?.expires).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    );
  }
}

export default withStyles(styles)(LicenseDialog);