import {default as origAxios} from "axios";
import {Alerts} from "../../../../../../components";

const axios = origAxios.create();

export const getBusinessObjects = async function (force = false) {
  try {
    let businessObjects = this.state.dependencies?.businessObjects;

    if (!businessObjects || force) {
      this.setState(state => ({
        ...state,
        businessObjects: null
      }))
      const { languagePack } = this.props;
      const { data } = await axios({
        url: `/api/integration/cherwell/getbusinessobjectsummaries/type/All`,
      });

      function getAllSummaries(summaries) {
        for (const summary of summaries)
          summaries = summaries.concat(getAllSummaries(summary.groupSummaries));
        return summaries;
      }

      businessObjects = getAllSummaries(data).sort(function (a, b) {
        if (
          (a.major && !b.major) || 
          (a.supporting && !b.major && !b.supporting) ||
          (a.lookup && !b.major && !b.supporting && !b.lookup) ||
          (a.group && !b.major && !b.supporting && !b.lookup && !b.group)
        )
          return -1;

        if (
          a.major === b.major ||
          a.supporting === b.supporting ||
          a.lookup === b.lookup ||
          a.group === b.group
        )
          return 0;

        return 1;
      }).map(x => { 
        return {
        ...x,
        type: x.major ? languagePack.integrationFields[this.state.command.integrationId].businessObjectTypes["major"] :
          x.supporting ? languagePack.integrationFields[this.state.command.integrationId].businessObjectTypes["supporting"] :
          x.lookup ? languagePack.integrationFields[this.state.command.integrationId].businessObjectTypes["lookup"] :
          x.group ? languagePack.integrationFields[this.state.command.integrationId].businessObjectTypes["group"] :
          languagePack.integrationFields[this.state.command.integrationId].businessObjectTypes["unknown"]
        }
      });

      this.setDependencies("businessObjects", businessObjects);
    }

    this.setState(state => ({
      ...state,
      businessObjects
    }))
  } catch (err) {
    Alerts({type: 'error', text: err.response?.data || err.message});
  }
}

export const getSearches = async function (busObId, force = false) {
  try {
    let searches = this.state.dependencies?.searches?.[busObId];

    if (!searches || force) {
      this.setState(state => ({
        ...state,
        searches: {
          ...state.searches,
          [busObId]: null
        }
      }))
      const { data } = await axios({
        url: `/api/integration/cherwell/getsearchitems/association/${busObId}`,
      });
      const { root } = data;

      function getAllSearches(root) {
        let searches = root.childItems;
        for (const folder of root.childFolders)
          searches = searches.concat(getAllSearches(folder));
        return searches;
      }

      searches = getAllSearches(root);

      let searchesCache = this.state.dependencies.searches;
      if (!searchesCache)
        searchesCache = {};
      searchesCache[busObId] = searches;
      this.setDependencies("searches", searchesCache);
    }

    this.setState(state => ({
      ...state,
      searches: {
        ...state.searches,
        [busObId]: searches
      }
    }))
  } catch (err) {
    Alerts({type: 'error', text: err.response?.data || err.message});
  }
}

export const getBusinessObjectFields = async function (busObId, force = false) {
  try {
    let fields = this.state.dependencies?.businessObjectFields?.[busObId];
    if (!fields || force) {
      this.setState(state => ({
        ...state,
        businessObjectFields: {
          ...state.businessObjectFields,
          [busObId]: null
        }
      }))

      const { data } = await axios({
        url: `/api/integration/cherwell/getbusinessobjecttemplate/${busObId}`,
      });

      fields = data.fields;
      
      let fieldsCache = this.state.dependencies.businessObjectFields;
      if (!fieldsCache)
        fieldsCache = {};
      fieldsCache[busObId] = fields;
      this.setDependencies("businessObjectFields", fieldsCache);
    }

    this.setState(state => ({
      ...state,
      businessObjectFields: {
        ...state.businessObjectFields,
        [busObId]: fields
      }
    }))
  } catch (err) {
    Alerts({type: 'error', text: err.response?.data || err.message});
  }
}

export const getCustomCommandActions = async function (force = false) {
  try {
    let customCommandActions = this.state.dependencies?.customCommandActions;
    if (!customCommandActions || force) {
      this.setState(state => ({
        ...state,
        customCommandActions: null
      }))
      const { data } = await axios({
        url: `/api/integration/cherwell/getcustomcommandactions`,
      });

      customCommandActions = data;
      
      this.setDependencies("customCommandActions", customCommandActions);
    }

    this.setState(state => ({
      ...state,
      customCommandActions
    }))
  } catch (err) {
    Alerts({type: 'error', text: err.response?.data || err.message});
  }
}
