
import PropTypes from 'prop-types';
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Typography, Button} from '../../../../components';

const UserWarningDialog = ({languagePack, numberOfRecords, onCancel, onConfirm, confirmColor, ...rest}) => (
  <Dialog {...rest}  maxWidth='xs'>
    <DialogTitle disableTypography>
      <Typography variant="h5">
        {languagePack.heading}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="body1">
            {numberOfRecords > 1 && 
              <>
                {languagePack.multipleText[0]}
                <Typography display="inline" variant="body1" bold>{` ${numberOfRecords} `}</Typography>
                {`${languagePack.multipleText[1]}\n\n`}
              </>
            }
            {languagePack.text}
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button fullWidth size="large" onClick={onCancel}>
        {languagePack.buttons.cancel}
      </Button>
      <Button disableElevation fullWidth size="large" variant="contained" color={confirmColor} onClick={onConfirm}>
        {languagePack.buttons.confirm}
      </Button>
    </DialogActions>
  </Dialog>
)

UserWarningDialog.propTypes = {
  languagePack: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default UserWarningDialog;