import React, { Component } from 'react';
import { Grid, FormControlLabel, Divider } from '@material-ui/core';
import { Autocomplete, Typography, Checkbox, Button, Tooltip } from '../../../../../../components';
import { AddCircle, Cancel } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { getBusinessObjects, getSearches, getBusinessObjectFields, getCustomCommandActions } from "./api";
import axios from 'axios';


const styles = theme => ({
  customerFieldMappingAdd: {
    "& > span": {
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: 4,
        color: theme.palette.info.main
      }
    }
  },
  customerFieldMappingCancel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      cursor: "pointer",
      color: theme.palette.error.main
    }
  }
})

class SetupFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessObjects: null,
      searches: null,
      businessObjectFields: null
    }
  }

  componentDidMount() {

    this.getBusinessObjects();
    
    const { command, config } = this.props;
    if (command.busObId !== undefined) {
      this.getSearches(command.busObId);
      this.getBusinessObjectFields(command.busObId);
    }
    if (command.customerFieldMapping && config) {
      this.getBusinessObjectFields(config.businessObjects.customer.busObId);
    }
  }

  getBusinessObjects = getBusinessObjects;

  getSearches = getSearches;

  getBusinessObjectFields = getBusinessObjectFields;

  businessObjectHandler = async  (event, value) => {
    const updatedCommand = { ...this.props.command };
    const busObId = value?.busObId || null;
    if (!busObId)
      delete updatedCommand.busObId;
    else
      updatedCommand.busObId = busObId;
    
    delete updatedCommand.search;
    delete updatedCommand.templateId;
    delete updatedCommand.values;

    this.props.onUpdate(updatedCommand)

    if (busObId) {
      this.getSearches(busObId);
      this.getBusinessObjectFields(busObId);
    }
  }

  searchHandler = async  (event, value) => {
    const updatedCommand = { ...this.props.command };
    if (value) {
      const { id, scope, scopeOwner } = value;
      updatedCommand.search = {
        searchId: id,
        scope,
        scopeOwner
      };
    } else {
      delete updatedCommand.search;
    }

    this.props.onUpdate(updatedCommand);
  }

  customerFieldMappingCheckboxHandler = (event) => {
    const { command, config } = this.props;
    const updatedCommand = { ...command };

    if (updatedCommand.customerFieldMapping !== undefined)
      delete updatedCommand.customerFieldMapping;
    else {
      this.getBusinessObjectFields(config.businessObjects.customer.busObId);
      updatedCommand.customerFieldMapping = [{
        customerFieldId: "",
        businessObjectFieldId: "",
      }];
    }

    this.props.onUpdate(updatedCommand);
  }

  customerFieldMappingHandler = (index, name) => async (event, value) => {
    const { command } = this.props;
    const customerFieldMapping = [...command.customerFieldMapping];
    customerFieldMapping.splice(index, 1, {
      ...command.customerFieldMapping[index],
      [name]: value?.fieldId || ""
    })
    this.props.onUpdate({ ...command, customerFieldMapping });
  }

  addCustomerFieldMapping = (index) => (event) => {
    const { command } = this.props;
    const customerFieldMapping = [ ...command.customerFieldMapping ];
    customerFieldMapping.splice(index + 1, 0, {
      customerFieldId: "",
      businessObjectFieldId: ""
    });
    this.props.onUpdate({
      ...command,
      customerFieldMapping
    })
  }

  removeCustomerFieldMapping = (index) => (event) => {
    const { command } = this.props;
    const customerFieldMapping = [ ...command.customerFieldMapping ];
    customerFieldMapping.splice(index, 1);
    this.props.onUpdate({
      ...command,
      customerFieldMapping
    })
  }

  render() {
    const { businessObjects, searches, businessObjectFields } = this.state;
    const { classes, languagePack, integration, command, config } = this.props;

    const busObId = command.busObId || null;
    const search = command.search || null;
    
    const customerBusObId = config?.businessObjects?.customer?.busObId || null;
    const customerBusinessObjectFields = businessObjectFields?.[customerBusObId] || null;

    const selectedBusinessObjectFields = businessObjectFields?.[busObId] || null;
    const selectedBusinessObjectSearches = searches?.[busObId] || null;


    const busObIdVal = businessObjects?.find(x => x.busObId === busObId) || null;
    const searchVal = selectedBusinessObjectSearches?.find(x => x.id === search?.searchId) || null;

    const useCustomerFieldMapping = command.customerFieldMapping !== undefined;

    return <>
      <Grid item xs={12}>
        <Autocomplete
          key={`command-${command.id || "new"}-busObId`}
          label={languagePack["businessObject"].label}
          disabled={command?.id !== undefined}
          onRefresh={() => this.getBusinessObjects(true)}
          loading={businessObjects === null}
          options={businessObjects || []}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.displayName}
          onChange={this.businessObjectHandler}
          value={busObIdVal}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          key={`command-${integration.id}-search`}
          label={languagePack["search"].label}
          onRefresh={() => this.getSearches(busObId, true)}
          loading={busObId && selectedBusinessObjectSearches === null}
          options={selectedBusinessObjectSearches || []}
          getOptionLabel={(option) => option.displayName}
          onChange={this.searchHandler}
          disabled={!busObId}
          value={searchVal}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              key={`command-${integration.id}-customerFieldMapping-checkbox`}
              checked={useCustomerFieldMapping}
              onChange={this.customerFieldMappingCheckboxHandler}
            />
          }
          label={<Typography variant="body2" bold>{languagePack["customerFieldMappingCheckbox"].label}</Typography>}
        />
      </Grid>
      {useCustomerFieldMapping && command.customerFieldMapping.map((x, i) => {
        const selectedCustomerField = customerBusinessObjectFields?.find(f => f.fieldId === x.customerFieldId) || null;
        const selectedBusinessObjectField = selectedBusinessObjectFields?.find(f => f.fieldId === x.businessObjectFieldId) || null;

        const hasCancelButton = command.customerFieldMapping?.length > 1;
        const hasAddButton = command.customerFieldMapping?.length === i + 1;
        return <>
          <Grid item xs={hasCancelButton ? 11 : 12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  key={`command-${integration.id}-customerFieldMapping-${i}-customerField`}
                  label={languagePack["customerFieldMapping"].customerField}
                  onRefresh={() => this.getBusinessObjectFields(customerBusObId, true)}
                  loading={customerBusObId && customerBusinessObjectFields === null}
                  options={customerBusinessObjectFields || []}
                  getOptionLabel={(option) => option.displayName}
                  onChange={this.customerFieldMappingHandler(i, "customerFieldId")}
                  disabled={!customerBusObId}
                  value={selectedCustomerField}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  key={`command-${integration.id}-customerFieldMapping-${i}-businessObjectField`}
                  label={languagePack["customerFieldMapping"].businessObjectField}
                  onRefresh={() => this.getBusinessObjectFields(busObId, true)}
                  loading={busObId && selectedBusinessObjectFields === null}
                  options={selectedBusinessObjectFields || []}
                  getOptionLabel={(option) => option.displayName}
                  onChange={this.customerFieldMappingHandler(i, "businessObjectFieldId")}
                  disabled={!busObId}
                  value={selectedBusinessObjectField}
                />
              </Grid>
            </Grid>
          </Grid> 
          { 
            hasCancelButton && 
            <Grid className={classes.customerFieldMappingCancel} item xs={1}>
              <Tooltip
                text={languagePack["customerFieldMapping"].removeFieldMapping}
                placement="bottom"
              >
                <Cancel fontSize="small" onClick={this.removeCustomerFieldMapping(i)}/>
              </Tooltip>
            </Grid>
          }
          {
            hasAddButton &&
            <Grid className={classes.customerFieldMappingAdd} item xs={12}>
              <span onClick={this.addCustomerFieldMapping(i)}>
                <AddCircle fontSize="inherit"/>
                <Typography variant="body2" bold>{languagePack["customerFieldMapping"].newFieldMapping}</Typography>
              </span>
            </Grid>
          }
        </>
      }) }
    </>
  }
}

export default withStyles(styles)(SetupFields)