import React, { Component } from 'react';
import { Grid, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Autocomplete, Typography, Button, Tooltip, Checkbox, Alerts} from '../../../../../../components';
import { AddCircle, Cancel } from '@material-ui/icons';
import { getBusinessObjectFields, getCustomCommandActions } from "./api";
import { addInitialAdditionalField } from "../../commanddialog";

const styles = theme => ({
  additionalFieldsAdd: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
      "& svg": {
        marginRight: 4,
        color: theme.palette.info.main
      }
    // }
  },
  additionalFieldsCancel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      cursor: "pointer",
      color: theme.palette.error.main
    }
  }
})

class CardSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessObjectFields: null,
      selectedTemplateField: null,
      customCommmandActions: null
    }
  }

  componentDidMount() {
    const { busObId, values } = this.props.command;
    if (busObId !== undefined)
      this.getBusinessObjectFields(busObId);
    this.getCustomCommandActions();

    const template = this.props.template;
    const updatedValues = addInitialAdditionalField(template, values);

    this.props.onUpdate({
      ...this.props.command,
      values: updatedValues
    })
  }

  getBusinessObjectFields = getBusinessObjectFields;
  getCustomCommandActions = getCustomCommandActions;

  templateFieldHandler = (name, AFIndex = null) => async (event, value) => {
    const fieldId = value?.fieldId || null;
    const { command } = this.props;
    const values = { ...command.values };
    if (!fieldId && AFIndex === null)
      delete values[name];
    else if (AFIndex !== null)
      values[name][AFIndex] = fieldId;
    else
      values[name] = fieldId;
    this.props.onUpdate({
      ...command,
      values
    })
  }

  addAdditionalFieldHandler = (name, index) => (event) => {
    const { command } = this.props;
    const values = { ...command.values };
    values[name].splice(index + 1, 0, null);
    this.props.onUpdate({
      ...command,
      values
    })
  }

  removeAdditionalFieldHandler = (name, index) => (event) => {
    const { command } = this.props;
    const values = { ...command.values };
    values[name].splice(index, 1);
    this.props.onUpdate({
      ...command,
      values
    })
  }

  customActionsCheckboxHandler = (event) => {
    const { command } = this.props;
    const updatedCommand = { ...command };
    if (command.actions) {
      delete updatedCommand.actions
    } else {
      this.getCustomCommandActions();
      updatedCommand.actions = [
        null
      ]
    }
    this.props.onUpdate(updatedCommand)
  }

  customActionsHandler = (index) => (event, value) => {
    const { command } = this.props;
    const actions = [...command.actions];
    actions[index] = value;
    this.props.onUpdate({
      ...command,
      actions
    })
  }

  addCustomActionHandler = (index) => (event) => {
    const { command } = this.props;
    const actions = [...command.actions];
    if (actions.length >= 6)
      return Alerts({ type: "error", text: "Only a maximum of 6 actions allowed"})
    actions.splice(index + 1, 0, null);
    this.props.onUpdate({
      ...command,
      actions
    })
  }

  removeCustomActionHandler = (index) => (event) => {
    const { command } = this.props;
    const actions = [...command.actions];
    actions.splice(index, 1);
    this.props.onUpdate({
      ...command,
      actions
    })
  }

  render() {
    const { businessObjectFields, customCommandActions } = this.state;
    const { classes, languagePack, integration, command, template } = this.props;

    const selectedBusinessObjectFields = businessObjectFields?.[command.busObId];
    const useCustomActions = command.actions !== undefined;
    return <>
      {
        template.fields.map(x => {
          const fieldId = command.values[x.name] || null;
          const fieldIdVal = selectedBusinessObjectFields?.find(x => x.fieldId === fieldId) || null;
          return (
            <Grid item xs={12} key={`command-${command.id || "new"}-template-${x.name}`}>
              <Autocomplete
                label={x.label}
                loading={selectedBusinessObjectFields === null}
                options={selectedBusinessObjectFields || []}
                getOptionLabel={(option) => option.displayName}
                onChange={this.templateFieldHandler(x.name)}
                onRefresh={() => this.getBusinessObjectFields(command.busObId, true)}
                value={fieldIdVal}
              />
            </Grid>
          )
        })
      }
      {
        template.additionalFields.map(x => {
          return <>
            <Grid item xs={12} key={`command-${command.id || "new"}-template-${x.name}-header`}>
              <Typography variant="body2" bold>{x.headerText}</Typography>
            </Grid>
            {
              Array.isArray(command.values[x.name])
                ? command.values[x.name].map((fieldId, i) => {
                  const fieldIdVal = selectedBusinessObjectFields?.find(x => x.fieldId === fieldId) || null;
                  const hasCancelButton = command.values[x.name]?.length > 1;
                  return <Grid item xs={12} key={`command-${command.id || "new"}-template-${x.name}-${i}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={hasCancelButton ? 10 : 11}>
                        <Autocomplete
                          label={`${x.headerText} - ${i + 1}`}
                          loading={selectedBusinessObjectFields === null}
                          options={selectedBusinessObjectFields || []}
                          getOptionLabel={(option) => option.displayName}
                          onChange={this.templateFieldHandler(x.name, i)}
                          onRefresh={() => this.getBusinessObjectFields(command.busObId, true)}
                          value={fieldIdVal}
                          fullWidth
                        />
                      </Grid>
                        { 
                          hasCancelButton && 
                          <Grid className={classes.additionalFieldsCancel} item xs={1}>
                            <Tooltip
                              text={languagePack["additionalFields"].removeField}
                              placement="bottom"
                            >
                              <Cancel fontSize="small" onClick={this.removeAdditionalFieldHandler(x.name, i)}/>
                            </Tooltip>
                          </Grid>
                        }
                        <Grid className={classes.additionalFieldsAdd} item xs={1}>
                          <Tooltip
                            text={languagePack["additionalFields"].addField}
                            placement="bottom"
                          >
                            <AddCircle fontSize="small" onClick={this.addAdditionalFieldHandler(x.name, i)}/>
                          </Tooltip>
                        </Grid>
                    </Grid>
                  </Grid>
                }) 
                : null
            }
          </>
        })
      } 
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              key={`command-${integration.id}-template-actions-checkbox`}
              checked={useCustomActions}
              onChange={this.customActionsCheckboxHandler}
            />
          }
          label={<Typography variant="body2" bold>{languagePack["customCommandActions"].checkbox}</Typography>}
        />
      </Grid>
      {
        useCustomActions && command.actions.map((x, i) => {
          const action = customCommandActions?.find(a => x === a) || null;
          const hasCancelButton = command.actions.length > 1;
          return <>
            <Grid item xs={hasCancelButton ? 10 : 11} key={`command-${command.id || "new"}-template-actions-${i}`}>
              <Autocomplete
                label={languagePack["customCommandActions"].label}
                loading={customCommandActions === null}
                options={customCommandActions || []}
                getOptionLabel={(option) => option}
                onChange={this.customActionsHandler(i)}
                onRefresh={() => this.getCustomCommandActions(true)}
                value={action}
              />
            </Grid>
              { 
                hasCancelButton && 
                <Grid className={classes.additionalFieldsCancel} item xs={1}>
                  <Tooltip
                    text={languagePack["customCommandActions"].removeField}
                    placement="bottom"
                  >
                    <Cancel fontSize="small" onClick={this.removeCustomActionHandler(i)}/>
                  </Tooltip>
                </Grid>
              }
              <Grid className={classes.additionalFieldsAdd} item xs={1}>
                <Tooltip
                  text={languagePack["customCommandActions"].addField}
                  placement="bottom"
                >
                  <AddCircle fontSize="small" onClick={this.addCustomActionHandler(i)}/>
                </Tooltip>
              </Grid>
          </>
        })
      }
    </>
  }
}

export default withStyles(styles)(CardSetup);