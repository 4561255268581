import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputAdornment} from '@material-ui/core';
import {Typography, Button, TextInput} from '../../../../components';
import {Autocomplete} from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import moment from 'moment';
import { CalendarToday } from '@material-ui/icons';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  calendarIcon: {
    color: theme.palette.text.secondary
  }
})

class SupportAccessDialog extends Component {
  render() {
    const {classes, languagePack, close, open, dialogFields, fieldHandler, grantAccess, timeFrameOptions} = this.props;

    return (
      <Dialog open={open} onClose={close} maxWidth='xs'>
        <DialogTitle disableTypography={true}>
          <Typography variant="h5">
            {languagePack.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {languagePack.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                key="supportAccess-email"
                name="supportAccess-email"
                label={languagePack.fields.email.label}
                value={dialogFields.email.value}
                onChange={(event) => fieldHandler(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={timeFrameOptions.find(x => x === dialogFields.accessTime.value) || timeFrameOptions[0]}
                options={timeFrameOptions}
                getOptionLabel={option => languagePack.fields.accessTime.values[option]}
                renderInput={(params) => <TextInput {...params} label={languagePack.fields.accessTime.label}/>}
                disableClearable
                onChange={(e, val) => fieldHandler(null, 'supportAccess-accessTime', val)}
              />
            </Grid>
            {
              dialogFields.accessTime.value === "custom" ? 
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={momentUtils}>
                    <DatePicker
                      label={languagePack.fields.custom.label}
                      format="DD/MM/yyyy"
                      value={dialogFields.custom.value || moment()}
                      onChange={(val) => fieldHandler(null, "supportAccess-custom", moment.utc(val).endOf('d'))}
                      TextFieldComponent={ (props) => <TextInput 
                          InputLabelProps={{
                            shrink: true,
                            color: 'secondary'
                          }} 
                          {...props}
                          InputProps={{
                            ...props.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                {<CalendarToday className={classes.calendarIcon}/>}
                              </InputAdornment>
                            )
                          }}
                        />
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              : null
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth  onClick={close}>
            {languagePack.buttons.cancel}
          </Button>
          <Button fullWidth color="info" variant="contained" onClick={grantAccess}>
            {languagePack.buttons.submit}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SupportAccessDialog);