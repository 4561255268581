import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import logo from '../../assets/branding/Onebot white.svg';
import {connect} from 'react-redux';
import {TextInput, LoginButton, Alerts} from '../../components';
import axios from 'axios';

const styles = theme => ({
  root: {
    height: "100vh",
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: "center"
  },
  container: {
    maxWidth: '450px',
    width: '100%',
  },
  logo: {
    height: '100px',
    marginBottom: '2.5em'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    paddingLeft: "15px",
    paddingRight: "15px",
    justifyContent: 'center'
  },
  textField: {
    marginBottom: "1rem"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },
  pwLink: {
    color: theme.palette.common.white,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  spacer: {
    marginBottom: '1rem'
  },
  heading: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0em"
  }
})

class ResetRequested extends Component {
  render() {
    const {classes, languagePack} = this.props;


    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid 
            container
            alignItems='center'
          >
            <Grid 
              item
              xs={12}
              className={classes.row}
              style={{
                marginBottom: "3.75rem"
              }}
            >
              <img className={classes.logo} src={logo} alt="logo"/>
              <Typography align="center" className={classes.heading}>
                {languagePack.pageTitle}
              </Typography>
              <Typography align="center" variant="body2">
                {languagePack.helpText[0]}
                <Link to='/login'>{languagePack.helpText[1]}</Link>
                {languagePack.helpText[2]}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languagePack}) => {
  return {
    languagePack: languagePack['reset-complete']
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ResetRequested));