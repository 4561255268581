import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles';
import {FormControl, TextField, InputAdornment} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Search} from '@material-ui/icons';

const styles = theme => {
  return {
  loginInput: {
    width: "100%",
    display: "block",
    padding: "0.65rem 1rem",
    height: "calc(1.5em + 1.3rem + 2px)",
    boxSizing: 'border-box',
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    backgroundClip: "padding-box",
    border: "1px solid #E4E6EF",
    borderRadius: "50rem",
    boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
    opacity: 0.7,
    border: "1px solid #E4E6EF",
    // color: "#ffffff",
    backgroundColor: "rgba(24, 28, 50, 0.28)",
    '&::placeholder': {
      color: theme.palette.common.white
    },
    '&:focus': {
      color: "#3F4254",
      backgroundColor: theme.palette.common.white,
      borderColor: "#288cc4",
      outline: 0
    }
  },
  baseStyling: {
    "& .MuiInputBase-root.Mui-focused > fieldset": {
      backgroundColor: "transparent",
      border: `2px solid ${theme.palette.info.main}`
    },
    "& .MuiInputLabel-shrink.Mui-focused": {
      color: theme.palette.info.main
    },
    "& > .MuiInputLabel-shrink ~ .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
      "&:not(.Mui-focused) > fieldset": {
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.additionals.border}`
      }
    }
  },
  input: {
    borderRadius: "8px"
  }
}}

class TextInput extends Component {
  render() {
    const {variant, className, classes, startAdornment, endAdornment, size, ...rest} = this.props;
    const classNames = className ? [className] : [];

    classNames.push(classes.baseStyling);

    let input = <TextField
      fullWidth
      variant="outlined"
      size={size}
      className={classNames.join(" ")}
      InputProps={{
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
        className: [classes.input, this.props.inputPropsClass],
        root: classes.inputRoot
      }}
      InputLabelProps={{
        // style: { lineHeight: '10px' },
      }}
      {...rest}
    />;

    if(variant && variant.toLowerCase() === 'login') {
      input = <input className={[classes.loginInput, className].join(' ')} {...rest}/>
    }

    return input;
  }
}

TextInput.propTypes = {
  variant: PropTypes.string,
  startAdornament: PropTypes.element,
  endAdornament: PropTypes.element
}

export default withStyles(styles)(TextInput);