import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid} from '@material-ui/core';
import {Typography, Button, TextInput} from '../../../../components';
import { Autocomplete } from '@material-ui/lab';


class expectedoutcomedialog extends Component {
  render() {
    const {open, close, deleteHandler, submitHandler, expectedOutcome, languagePack, autocompleteHandler, questions} = this.props;

    return (
      <Dialog open={open} onClose={close}>
        <DialogTitle disableTypography>
          <Typography variant="h5">
            {languagePack.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {languagePack.helpText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput label={languagePack.fields.question} value={expectedOutcome?.message} multiline readonly/>
            </Grid>
            <Grid item xs={12}>
              <TextInput label={languagePack.fields.expectedOutcome} value={expectedOutcome?.outcomes[0]} multiline readonly/>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name={"dialog-autocomplete"}
                options={questions ? questions : []}
                loading={!questions}
                getOptionLabel={option => option.name}
                renderInput={(params) => <TextInput {...params} label="Select a Question" />}
                onChange={autocompleteHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            {languagePack.buttons.cancel}
          </Button>
          <Button fullWidth disableElevation color='error' variant='contained' onClick={() => {deleteHandler(null, expectedOutcome?.id, true); close()}}>
            {languagePack.buttons.delete}
          </Button>
          <Button fullWidth disableElevation color='info' variant='contained' onClick={() => {submitHandler(); close()}} disabled={!expectedOutcome?.question}>
            {languagePack.buttons.submit}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default expectedoutcomedialog;