const palette = {
  text: {
    primary: "#0A2443",
    secondary: "#AAB2BB",
    tertiary: "#374B62",
    quaternary: "#7D879F"
  },
  additionals: {
    border: "#D3DBE6"
  }

}

export default {
  typography: {
    fontFamily: [
      'Arial',
      'sans serif'
    ].join(','),
    h6: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "32px",
      letterSpacing: "0px",
      textAlign: "left",
    },
    body2: {
      fontFamily: "Arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.5px",
      textAlign: "left"
    },
    cell: {
      fontFamily: "Arial",
      color: palette.text.quaternary,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.5px",
      textAlign: "left",
    },
    tableHeader: {
      fontFamily: "Arial",
      color: palette.text.tertiary
    },
  },
  palette,
};
