import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import logo from '../../assets/branding/Onebot white.svg';
import {connect} from 'react-redux';
import {TextInput, LoginButton, Alerts} from '../../components';
import axios from 'axios';
import {Link} from 'react-router-dom';

const styles = theme => ({
  root: {
    height: "100vh",
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: "center"
  },
  container: {
    maxWidth: '450px',
    width: '100%',
  },
  logo: {
    height: '100px',
    marginBottom: '2.5em'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    paddingLeft: "15px",
    paddingRight: "15px",
    justifyContent: 'center'
  },
  textField: {
    marginBottom: "1rem"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },
  pwLink: {
    color: theme.palette.common.white,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  spacer: {
    marginBottom: '1rem'
  },
  heading: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0em"
  }
})

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      password2: ""
    }
  }
  
  render() {
    const {classes, languagePack} = this.props;

    const registerHandler = event => {
      this.setState(state => ({
        ...state,
        [event.target.name]: event.target.value
      }))
    }

    const registerSubmit = (event) => {
      event.preventDefault();

      if(this.state.password !== this.state.password2) {
        Alerts({type: 'warning', text: 'Passwords do not match'})
        return
      }

      const url = window.location.href.split('/');

      const payload = {
        username: this.state.username,
        password: this.state.password,
        token: url[url.length - 1] || url[url.length - 2]
      }

      axios.post('/auth/registration/register', payload).then(({data}) => {
        
        this.props.history.push('/registration-complete');
      })
      .catch(err => {
        Alerts({text: "<strong>Login Error:</strong><br/>"+err.response?.data || err.message, type: 'error'});
      })
    }
    
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid 
            container
            alignItems='center'
          >
            <Grid 
              item
              xs={12}
              className={classes.row}
              style={{
                marginBottom: "3.75rem"
              }}
            >
              <img className={classes.logo} src={logo} alt="logo"/>
              <Typography align="center" className={classes.heading}>
                {languagePack.pageTitle}
              </Typography>
              <Typography align="center" variant="body2">
                {languagePack.helpText[0]} <Link to="/">{languagePack.helpText[1]}</Link> {languagePack.helpText[2]}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languagePack}) => {
  return {
    languagePack: languagePack['registration-complete']
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Login));