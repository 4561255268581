import React, { Component } from 'react';
import {Grid, Card} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {SupervisorAccount, Forum, Help, CloudDownload} from '@material-ui/icons';
import {connect} from 'react-redux';
import axios from 'axios';
import {Alerts, LoadingSpinner, View} from '../../components';
import integrations from '../appstore/integrations.json';
import {
  RecentStats,
  TopApps,
  TopCommands,
  TopUsers,
  Tile
} from './components';

const styles = theme => ({
  card: {
    padding: theme.spacing(2) + "px !important"
  }
})

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      users: null,
      pastWeek: null,
      commands: null
    }
  }
  
  componentDidMount() {
    axios.get('/api/statistics?showDeleted=true')
    .then((stats) => {
      const lastWeek = new Date();
      lastWeek.setDate(new Date().getDate() - 7);
      axios.get('/api/statistics?showDeleted=true', { params: { after: lastWeek.toISOString() } })
      .then(async ({data}) => {
        const commands = await stats.data.commands.filter(x => {
          return x.command !== null;
        }).map(j => {
          j.pastWeek = data.commands.filter(p => {
            return p.command === j.command;
          }).pop();
          return j;
        })

        this.setState(state => ({
          ...state,
          stats: stats.data,
          pastWeek: commands
        }))
      })
      .catch(err => {
        Alerts({text: err.response?.data || err.message, type: 'error'});
      })
    })
    .catch(err => {
      Alerts({text: err.response?.data || err.message, type: 'error'});
    })
    
    axios.get('/api/botusers')
    .then(({data}) => {
      this.setState(state => ({
        ...state,
        users: data
      }))
    })
    .catch(err => {
      Alerts({text: err.response?.data || err.message, type: 'error'});
    })

    this.getCommands();
  }

  getCommands = () => {
    axios.get('/api/commands').then(data => {
      this.setState(state => ({
        ...state,
        commands: data.data
      }))
    }).catch(err => {
      Alerts({type: 'error', text: err.response?.data || err.message})
    })
  }

  getAverageDaily = () => {
    const stats = {...this.state.stats}
    const totalUsage = stats.pastMonth.length > 0 ? stats.pastMonth.map(function(x){
      return x.total;
    }).reduce(function (acc, cur) {
      return acc + cur; 
    }) : 0;

    const averageUsage = (totalUsage / stats.pastMonth.length).toFixed(0);

    return averageUsage;
  }

  render() {
    const {languagePack, classes} = this.props;
    
    if(!this.state.stats || !this.state.users || !this.state.pastWeek || !this.state.commands) {
      return <LoadingSpinner/>;
    }
    
    const licensedUsers = this.state.users.filter(x => x.licensed === true)

    return (
      <Card elevation={0} className={[classes.card, "cardWrapper"]}>
        <Grid 
          container
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<SupervisorAccount/>}
              primaryText={licensedUsers.length}
              secondaryText={languagePack.tiles.licensedUsers}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<Forum/>}
              color="secondary"
              primaryText={this.getAverageDaily()}
              secondaryText={languagePack.tiles.averageDailyUsage}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<Help/>}
              color="warning"
              primaryText={this.state.stats.requests.total}
              secondaryText={languagePack.tiles.questionsAnswered}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<CloudDownload/>}
              color="info"
              primaryText={Object.keys(integrations).filter(x => integrations[x].status !== 'comingSoon').length}
              secondaryText={languagePack.tiles.installedApps}
            />
          </Grid>
          <TopUsers languagePack={languagePack.topUsers} users={this.state.users} userStats={this.state.stats.users}/>
          <RecentStats languagePack={languagePack.recentStatistics} stats={this.state.stats}/>
          <TopApps languagePack={languagePack.topApps} commands={this.state.commands} stats={this.state.stats}/>
          <TopCommands languagePack={languagePack.topCommands} stats={this.state.stats} pastWeek={this.state.pastWeek} commands={this.state.commands}/>
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = ({languagePack}) => {
  const page = window.location.pathname.split('/')[1]
  return {
    languagePack: languagePack[page]
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));