import React, { Component } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button as MuiButton, FormControlLabel} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {TextInput, Button, Chip, Table, Typography, Checkbox} from '../../../../components';
import {Autocomplete} from '@material-ui/lab'
import {Check, Close, HighlightOff} from '@material-ui/icons';
import TranslateIcon from '@material-ui/icons/Translate';
import {connect} from 'react-redux';

const styles = theme => ({
  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  button: {
    marginLeft: theme.spacing(2),
    boxSizing: 'border-box',
    maxWidth: '48px',
    minWidth: '48px',
    padding: 0,
    borderWidth: '2px'
  },
  buttonIcon: {
    verticalAlign: 'middle'
  },
  uSTitle: {
    marginBottom: theme.spacing(2)
  },
  actionButton: {
    maxWidth: "36px",
    minWidth: "36px",
    height: "36px",
    backgroundColor: "rgba(244,246,249,1)", //TODO Update Greys in theme
    marginRight: theme.spacing(1),
    "&:last-of-type": {
      marginRight: 0
    }
  },
  buttonWrapper: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1
  },
  save: {
    color: theme.palette.success.main
  },
  discard: {
    color: theme.palette.error.main
  },
  tagifyField: {
    '& .MuiChip-deleteIcon': {
      color: "inherit",
      width: 16,
      height: 16,
      margin: 0,
      paddingRight: 12,
    },
    '& .MuiChip-label': {
      paddingRight: 4
    }
  }
})
class QuestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.props.question,
      commands: this.props.commands,
      languages: null,
      userSuggestions: this.props.userSuggestions,
      variantInput: "",
      step: 0
    }
  }

  createUSTable = (userSuggestions, question) => {
    if(!userSuggestions.length || !question) {
      return {
        rows: []
      };
    }
    const rows = userSuggestions.filter(x => x.language === this.props.currentLanguage).map(u => {
      return {
        id: u.id,
        cells: [
          u.message,
          {
            type: 'custom', 
            options: {
              key: `action-buttons-${u.id}`,
              element: (
                <React.Fragment>
                  <div className={this.props.classes.buttonWrapper}>
                    <div className={this.props.classes.spacer}></div>
                    <MuiButton className={[this.props.classes.actionButton, this.props.classes.save].join(' ')} onClick={() => this.props.resolveUserSuggestion(u)} disabled={false}>
                      <Check/>
                    </MuiButton>
                    <MuiButton className={[this.props.classes.actionButton, this.props.classes.discard].join(' ')} onClick={() => this.props.deleteUserSuggestion(u.id)}>
                      <Close/>
                    </MuiButton>
                  </div>
                </React.Fragment>
              )
            }
          }
        ]
      }
    })

    return {
      rows: rows
    }
  }

  variantonChangeHandler = (e, v) => {
    const value = v[v.length - 1];
    if (typeof value === "string" && !value.trim())
      return;

    const { fieldHandler } = this.props;
    fieldHandler(e, 'variant', v);
    this.setState((state) => ({
      ...state,
      variantInput: ""
    }))
  }

  variantInputHandler = (e) => {
    const v = e.target.value;
    this.setState((state) => ({
      ...state,
      variantInput: v
    }))
  }

  getTagifyVals = (e) => {
    const { question } = this.props;
    return question?.questions ? question?.questions?.filter(x => x.language === this.props.currentLanguage) : [];
  }

  variantBlurHandler = (e) => {
    const val = e.target.value?.trim();
    if (!val)
      return;

    const { fieldHandler } = this.props;
    const values = this.getTagifyVals();
    values.push(val)
    fieldHandler(e, 'variant', values);
    this.setState((state) => ({
      ...state,
      variantInput: ""
    }))
  }

  variantKeyDownHandler = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      e.target.blur();
      e.target.focus();
    }

  }

  stepHandler = (step) => {
    let current = this.state.step;
    let nextStep;
    if(step === 'next')
      nextStep = current += 1
    
    if(step === 'prev')
      nextStep = current -= 1

    this.setState(state => ({
      ...state,
      step: nextStep
    }))
  }

  stepReset = () => {
    this.setState(state => ({
      ...state,
      step: 0
    }))
  }
 
  render() {
    const {classes, question, open, dialogHandler, fieldHandler, saveQuestion, commands, addQuestion, translateHandler, userSuggestions, userSuggestionLanguage, enabledLanguages, currentLanguage} = this.props;
    const isNew = !question?.id; 
    const answer = question?.answers?.find(x => x.language === currentLanguage);
    const tagifyVals = this.getTagifyVals();
    const uSFiltered = userSuggestions.filter(x => {
      return x.language === currentLanguage;
    })
    const languagePack = this.props.languagePack[this.state.step]
    const steps = [
      {
        content: (
          <Grid container spacing={2} justify='space-between'>
            <Grid item xs={12}>
              <TextInput 
                name='name' 
                label={languagePack.fields.description?.label} 
                value={question?.name} 
                onChange={(e) => fieldHandler(e, 'name')}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.flexWrapper}>
                <Autocomplete 
                  fullWidth
                  options={enabledLanguages || []}  
                  disableClearable
                  value={enabledLanguages ? enabledLanguages.find(x => x.language === currentLanguage) : null}
                  renderInput={(params) => <TextInput {...params} label={languagePack.fields.language?.label}/>}
                  getOptionLabel={x => x.label}
                  onChange={(e, val) => {
                    this.props.setCurrentLanguage({ currentLanguage: val.language });
                  }}
                />
                <Button className={classes.button} onClick={translateHandler} question={question}>
                  <TranslateIcon className={classes.buttonIcon}/>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className={classes.tagifyField}
                value={tagifyVals || []}
                multiple
                options={[]}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      round
                      size="large"
                      bgColor={{color: option.userSuggestion ? 'success' : 'info', variant: 'light'}}
                      color={{color:  option.userSuggestion ? 'success' : 'info', variant: 'contrastText'}}
                      label={<Typography variant="buttonsmall" bold>{option.question}</Typography>}
                      deleteIcon={<HighlightOff fontSize="small"/>}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    fullWidth
                    label={languagePack.fields.questionVariants?.label}
                    onChange={this.variantInputHandler}
                  />
                )}
                onChange={this.variantonChangeHandler}
                inputValue={this.state.variantInput}
                onBlur={this.variantBlurHandler}
                onKeyDown={this.variantKeyDownHandler.bind(this)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput name='answers' multiline label={languagePack.fields.questionAnswer?.label} value={answer ? answer.answer : ""} onChange={(e) => fieldHandler(e, 'answer')}/>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete 
                options={commands}  
                value={question?.action ? commands.find(x => {return x.id === question.action}) : null}
                renderInput={(params) => <TextInput {...params} label={languagePack.fields.action?.label}/>}
                getOptionLabel={x => x.label}
                onChange={(e, val) => fieldHandler(e, 'action', val)}
              />
            </Grid>
            {
              !isNew && uSFiltered.length > 0 ? <Grid item xs={12}>
              <Typography className={classes.uSTitle} variant="body2" bold>
                {userSuggestionLanguage.heading}
              </Typography>
              <Table
                dense
                tableData={this.createUSTable(userSuggestions, question)}
              />
            </Grid> : null}
          </Grid>
        ),
        buttons: (
          <>
            <Button fullWidth onClick={dialogHandler}>
              {languagePack.buttons.close}
            </Button>
            <Button disableElevation fullWidth color='info' variant='contained' onClick={() => this.stepHandler('next')}>
              {languagePack.buttons.publicSettings}
            </Button>
            <Button disableElevation fullWidth color='success' variant='contained' onClick={saveQuestion}>
              {languagePack.buttons.save}
            </Button>
          </>
        )
      },
      {
        content: (
          <Grid container spacing={2} justify='space-between'>
            <Grid item xs={12}>
              <TextInput 
                name='skill' 
                key='skill'
                label={languagePack.fields.skill?.label} 
                value={question?.skill} 
                onChange={(e) => fieldHandler(e, 'skill')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput 
                name='howToUse' 
                multiline 
                rows={3}
                label={languagePack.fields.howToUse?.label} 
                value={question?.howToUse} 
                onChange={(e) => fieldHandler(e, 'howToUse')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    key="publicSkill"
                    checked={question?.publicSkill || false}
                    onChange={(e) => fieldHandler(e, 'publicSkill')}
                  />
                }
                label={<Typography variant="body2" bold>{languagePack.fields.showOnHelp?.label}</Typography>}
              />
            </Grid>
          </Grid>
        ),
        buttons: (
          <>
            <Button fullWidth onClick={dialogHandler}>
              {languagePack.buttons.close}
            </Button>
            <Button disableElevation fullWidth color='info' variant='contained' onClick={() => this.stepHandler('prev')}>
              {languagePack.buttons.adminSettings}
            </Button>
            <Button disableElevation fullWidth color='success' variant='contained' onClick={saveQuestion}>
              {languagePack.buttons.save}
            </Button>
          </>
        )
      }
    ]


    return (
      <Dialog fullWidth maxWidth='sm' open={open} onClose={dialogHandler} onExited={this.stepReset}>
        <DialogTitle disableTypography={true}>
          <Typography variant="h5">
            {languagePack.heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {steps[this.state.step].content}
        </DialogContent>
        <DialogActions>
          {steps[this.state.step].buttons}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLanguage: state.currentLanguage
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setCurrentLanguage: x => dispatch({type: 'SETCURRENTLANGUAGE', payload: x}),
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuestionModal));