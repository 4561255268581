import { withStyles } from '@material-ui/styles';
import React, { Component, createRef } from 'react'
import {Typography} from '../../components';
import {withRouter} from 'react-router-dom';

const styles = theme =>({
  wrapper: {
    position: 'relative',
  },
  tabContainer: {
    display: "flex",
    backgroundColor: '#F0F4FA',
    height: "24px",
    padding: "2px",
    borderRadius: "6px",
    marginTop: "12px",
    position: "relative"
  },
  tabItem: {
    lineHeight: '24px',
    borderRadius: '4px',
    flex: 1,
    zIndex: 1010,
    "& a": {
      color: '#374B62',
      textDecoration: 'none'
    }
  },
  tabText: {
    textAlign: 'center',
  },
  tabActive: {
    backgroundColor: theme.palette.common.white,
    // width: "25%",
    height: "calc(100% - 4px)",
    position: 'absolute',
    borderRadius: "4px",
    top: 0,
    left: 0,
    marginLeft: '2px',
    marginTop: '2px',
    zIndex: 1000,
    transition: 'left .6s'
  }
})

class SliderTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.location.hash.split('#').pop()
    }
    this.activeRef = createRef();
  }

  scrollHandler = (event) => {
    const hash = this.state.hash;
    for (const [key, value] of Object.entries(event.srcElement.children)) {
      if(
        (hash !== value.id && 
        (value.offsetTop) <= event.srcElement.scrollTop &&
        (value.offsetTop + value.clientHeight) > event.srcElement.scrollTop)
      ) {
          window.history.replaceState(null, "", `#${value.id}`);
          this.setState(state => ({
            ...state,
            hash: value.id
          }))
        return
      }
    }
  }

  render() {
    const {classes, tabs, scrollRef,} = this.props;

    scrollRef.current?.addEventListener('scroll', this.scrollHandler)

    const hash = this.state.hash;

    const tabIndex = tabs.findIndex(x => x.href === hash);
    
    const parentWidth = this.activeRef.current?.offsetParent?.clientWidth;
    const offset = ((parentWidth - 4) / tabs.length) * tabIndex;
    const activeStyles = {
      width: parentWidth / tabs.length,
      left: Math.floor(offset)
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.tabContainer}>
        <div ref={this.activeRef} className={classes.tabActive} style={activeStyles}></div>
        {
          tabs.map((x, i) => {
            const classArr = [classes.tabItem];
            return (
              <div className={classArr.join(' ')}>
                <a href={`#${x.href}`}>
                  <Typography variant="body1" className={classes.tabText}>
                    {x.text}
                  </Typography>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>
    )
  }
}

export default withRouter(withStyles(styles)(SliderTabs));