import React, { Component } from 'react';
import { Grid, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';
import {Typography} from '../../../../components';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
  cardContent: {
    paddingTop: "1.5rem"
  },
  item: {
    marginBottom: theme.spacing(2.25),
    alignItems: "center",
    display: "flex",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  icon: {
    color: theme.palette.common.white,
    width: "40px",
    height: "40px",
    borderRadius: ".25rem",
    fontWeight: 500,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    textAlign: "center!important",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  text: {
    display: "flex",
    flexDirection: "column",
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    height: '32px',
    width: '32px'
  }
})

class TopUsers extends Component {
  render() {
    const {classes, languagePack, users, userStats} = this.props;

    const uStats = [...userStats];
    const userList = uStats.splice(0, 5).map(x => {
      return users.find(u => {
        return u.id === x.userId;
      })
    });
    return (
      <Grid 
        item
        xs={12}
        md={4}
        lg={3}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>  
          <CardHeader
            title={(<Typography variant="body1" bold>{languagePack.heading}</Typography>)}
            subheader={(<Typography variant="body2">{languagePack.helpText}</Typography>)}
            disableTypography
          />
          <CardContent className={classes.cardContent}>
            {
              userList ? userList.map((x, i) => (
                <div key={x.teamsId} className={classes.item}>
                  <div className={[classes.icon].join(' ')}>
                    <Avatar className={classes.avatar} src={`/api/botuser/${x.id}/avatar`}/>
                  </div>
                  <div className={classes.text}>
                    <Typography variant="cell">
                      {x.fullName}
                    </Typography>
                    <Typography variant="cell" className={classes.secondaryText}>
                      {x.department}
                    </Typography>
                  </div>
                </div>
              )) : null
            }
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(TopUsers);