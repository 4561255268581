import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {TextInput, Typography, Button, Autocomplete, Alerts} from '../../../../components';
import axios from 'axios';
import {connect} from 'react-redux';

import { getSearches } from "./api";

const CHERWELL_ID = "7676f599-c6d4-490e-8d37-2292e81f6043";

const UserAuthDialog = ({ open, languagePack, integrations, onClose, ...rest }) => {
  const [config, setConfig] = useState(null);
  const [customerSearches, setCustomerSearches] = useState(null);
  const [selectedCustomerSearch, setSelectedCustomerSearch] = useState(null);

  const close = () => {
    setSelectedCustomerSearch({...config.customerSearch});
    onClose();
  }

  const getConfig = async () => {
    try{
      const { data } = await axios({
        url: `/api/configs`,
      });
      setConfig(data?.find(x => x.id === CHERWELL_ID));
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  const getCustomerSearches = async () => {
    try {
      setCustomerSearches(null);
      if (!config)
        return;
      const customerSearches = await getSearches(config.businessObjects.customer.busObId)
      setCustomerSearches(customerSearches);
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  const customerSearchHandler = (e, val) => {
    if (!val)
      return setSelectedCustomerSearch(null);
    const { id, scope, scopeOwner } = val;
    setSelectedCustomerSearch({
      searchId: id,
      scope,
      scopeOwner,
    })
  }

  const testCustomerSearch = async () => {
    try {
      const { data: { totalRecords } } = await axios({
        url: "/api/botusers/auth/test",
        method: "POST",
        data: {
          customerSearch: selectedCustomerSearch || {}
        }
      });
      Alerts({type: 'success', text: `${totalRecords} record${totalRecords > 1 ? "s": ""} found.`});

    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }
  const saveCustomerSearch = async () => {
    try {
      const { data } = await axios({
        url: `/api/config/${config.id}`,
        method: "PUT",
        data: {
          customerSearch: selectedCustomerSearch || {}
        }
      });
      setConfig(data);
      Alerts({type: 'success', text: "User authentication successfully changed."});
      close();
    } catch (err) {
      Alerts({type: 'error', text: err.response?.data || err.message});
    }
  }

  useEffect(() => {
    getConfig()
  }, [])
  useEffect(() => {
    getCustomerSearches()
  }, [config !== null])
  useEffect(() => {
    if (config?.customerSearch)
      setSelectedCustomerSearch({...config.customerSearch});
  }, [config])

  const selectedSearch = customerSearches?.find(x => x.id === selectedCustomerSearch?.searchId) || null;
  return (
    <Dialog open={open} onClose={close} {...rest}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h5">
          {languagePack.heading}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body1">
              {languagePack.text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              key="userauth-integration"
              name="userauth-integration"
              label={languagePack.integration.label}
              value={integrations[CHERWELL_ID].product}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedSearch}
              disabled={!customerSearches}
              onRefresh={() => getCustomerSearches()}
              loading={!customerSearches}
              key={`userauth-customerSearch`}
              options={customerSearches || []}
              getOptionLabel={(option) => option.displayName}
              label={languagePack.customerSearch.label}
              onChange={customerSearchHandler}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={() => close()}>
          {languagePack.buttons.cancel}
        </Button>
        <Button color="success" variant="contained" disableElevation fullWidth onClick={testCustomerSearch}>
          {languagePack.buttons.test}
        </Button>
        <Button color="info" variant="contained" disableElevation fullWidth onClick={saveCustomerSearch}>
          {languagePack.buttons.save}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserAuthDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  languagePack: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

const mapStateToProps = ({ integrations }) => {
  return {
    integrations
  }
}

export default connect(mapStateToProps)(UserAuthDialog);