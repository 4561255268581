import React, { Component } from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import logo from '../../assets/branding/Onebot white.svg';
import {connect} from 'react-redux';
import {TextInput, LoginButton, Alerts} from '../../components';
import axios from 'axios';

const styles = theme => ({
  root: {
    height: "100vh",
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: "center"
  },
  container: {
    maxWidth: '450px',
    width: '100%',
  },
  logo: {
    height: '100px',
    marginBottom: '2.5em'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    paddingLeft: "15px",
    paddingRight: "15px",
    justifyContent: 'center'
  },
  textField: {
    marginBottom: "1rem"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },
  pwLink: {
    color: theme.palette.common.white,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  spacer: {
    marginBottom: '1rem'
  },
  heading: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0em"
  }
})

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      password2: ""
    }
  }
  
  render() {
    const {classes, languagePack} = this.props;

    const fieldHandler = event => {
      this.setState(state => ({
        ...state,
        [event.target.name]: event.target.value
      }))
    }

    const resetSubmit = (event) => {
      event.preventDefault();

      if(!this.state.username || !this.state.password || !this.state.password2) {
        return Alerts({type: "warning", text: 'Please populate all fields'})
      }

      if(this.state.password !== this.state.password2) {
        Alerts({type: "warning", text: 'Passwords do not match'})
        return
      }


      const url = window.location.href.split('/');

      const payload = {
        username: this.state.username,
        password: this.state.password,
        token: url[url.length - 1] || url[url.length - 2]
      }

      axios.post('/auth/passwordreset/reset', payload).then(({data}) => { 
        this.props.history.push('/reset-complete');
      })
      .catch(err => {
        Alerts({text: "<strong>Password Reset Error:</strong><br/>"+err.response?.data || err.message, type: 'error'});
      })
    }

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid 
            container
            alignItems='center'
          >
            <Grid 
              item
              xs={12}
              className={classes.row}
              style={{
                marginBottom: "3.75rem"
              }}
            >
              <img className={classes.logo} src={logo} alt="logo"/>
              <Typography align="center" className={classes.heading}>
                {languagePack.pageTitle}
              </Typography>
              <Typography align="center" variant="body2">
                {languagePack.helpText}
              </Typography>
            </Grid>
            <Grid 
              item
              xs={12}
              className={classes.row}
            >
              <form action="" onSubmit={resetSubmit.bind(this)}>
                <TextInput variant="login" name="username" className={classes.spacer} onChange={fieldHandler.bind(this)} type="text" placeholder={languagePack.username} autoComplete='username'/>
                <TextInput variant="login" name="password" className={classes.spacer} onChange={fieldHandler.bind(this)} type="password" placeholder={languagePack.password} autoComplete='current-password'/>
                <TextInput variant="login" name="password2" className={classes.spacer} onChange={fieldHandler.bind(this)} type="password" placeholder={languagePack.password2} autoComplete='current-password'/>
                <div className={classes.buttonContainer}>
                  <LoginButton className={classes.spacer} text={languagePack.button}/>
                </div>
                <Typography align="center">
                  <a className={classes.pwLink} href="reset-request">{languagePack.forgottenPW}</a>
                </Typography>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languagePack}) => {
  return {
    languagePack: languagePack["new-password"]
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Login));